import React, { useState } from 'react'
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { BaseHttpService } from 'services/base';
import { Utils } from 'utils';
import CMSLayout from 'view/cms/components/shared/CMSLayout';
import DeleteDialog from 'view/cms/components/shared/DeleteDialog';
import CustomModal from 'view/components/modals/CustomModal';
import LanguageForm from '../components/LanguageForm';

function LanguagesPage() {
  const [languages, setData] = useState(null);
  const [refresh, setRefresh] = useState(0);

  const { isLoading, error, data, refetch } = useQuery('languages', () => { return BaseHttpService.loadLanguages() }, {
    onSuccess: (data) => {
      setData(data.results)
    },
    // cacheTime: 60*60*24*30*1000// Cache results for 30 days
    refetchInterval: 60 * 10000,
  });

  const [selectedId, setSelectedId] = useState(0);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);



  const _handleEdit = (e, id) => {
    setSelectedId(id);
    setRefresh(refresh + 1)
    setEditModalIsOpen(true)
  }

  const _handleDelete = (e, id) => {
    setSelectedId(id);
    setRefresh(refresh + 1)
    setDeleteDialogIsOpen(true)
  }

  const _onDeleteDone = () => {
    var temp = languages.filter(item => item.id !== selectedId)
    setData(temp)
    setDeleteDialogIsOpen(false);
    setRefresh(refresh + 1)
  }
  return (
    <CMSLayout>
      <div className='box'>
        <div className="box-header mb-5">
          <h5>Languages</h5>
          <div className="btns d-flex">

            {/* <Link to="/cms/category/" className="btn btn-icon bg-warning mr-2" title="categories"><i className="fa fa-folder "></i></Link> */}
            <button className="btn btn-icon bg-success" onClick={() => { setSelectedId(null); setEditModalIsOpen(true) }}><i className="fa fa-plus "></i></button>
          </div>
        </div>
        <div className="box-body general-list">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>
                  #
                </th>
                <th>Name</th>
                <th>Abbreviations</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {languages && languages.map(item => (
                <tr key={"cat" + item.id}>
                  <td>{item.id}</td>
                  <td className='kfont'><Link onClick={(e) => { _handleEdit(e, item.id) }}>{item.name}</Link></td>
                  <td>{item.abbreviation}</td>
                  <td className='text-right'>
                    <a href="#" onClick={(e) => { alert("Deleting languages disabled temporarily"); //_handleDelete(e, item.id)
                     }} className="btn btn-outline btn-swap-1">
                      <span><i className="fas fa-trash color-gray "></i></span>
                      <span>Delete <i className="fas fa-trash ms-2 color-gray "></i></span>
                    </a>

                  </td>

                </tr>
              ))}


            </tbody>


          </table>
        </div>

        <CustomModal key={editModalIsOpen} setOpen={(value) => setEditModalIsOpen(value)}
          open={editModalIsOpen} >
          <LanguageForm id={selectedId} onEditDone={() => { refetch(); setEditModalIsOpen(false) }} />

        </CustomModal>

        <DeleteDialog
          url={`/base/language/${selectedId}`}
          isOpen={deleteDialogIsOpen}
          key={"mdl" + deleteDialogIsOpen + selectedId}
          setOpen={setDeleteDialogIsOpen}
          notifyDone={_onDeleteDone}
        />
      </div>
    </CMSLayout>
  )
}

export default LanguagesPage