import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { CourseHttpService } from 'services/course';
import { Utils } from 'utils';

function HomeCourses() {
    const { isLoading, error, data } = useQuery(
        'courses',
        () => CourseHttpService.loadCourses(),
        {
            onSuccess: (data) => {},
        }
    );


    // Limit to the first 8 courses to display
    const displayedCourses = data?.results?.slice(0, 8) || [];

    return (
        <section id="grid">
            <div className="custom-grid-1">
                <div className="container">
                    <div className="row">
                        {displayedCourses.map((course, i) => (
                            <div className="col-12 col-md-6 col-lg-3" key={course.id + 'HC'}>
                                <div className="custom-grid-1-item h-100">
                                    <div className="custom-grid-1-img">
                                        <img className="p-relative" src={course.image} loading="lazy" alt="" />
                                    </div>
                                    <div className="custom-grid-1-text d-flex align-items-center h-100">
                                        <div className="ps-4 full-width p-3">
                                            <div className="overflow-hidden lan-rtl text-small-center">
                                                <Link to={`/course/${course.id}`} className="stretched-link text-decoration-none">
                                                    <h3 className="font-weight-semi-bold text-course-title text-capitalize text-6 line-height-2 mb-3 appear-animation-visible font-bolder kfont spacing-1" data-appear-animation="maskUp" data-appear-animation-delay="50">
                                                        {Utils.getDefaultName(course)}
                                                    </h3>
                                                </Link>
                                                <div className="home-course-short-description kfont text-small-center text-course">
                                                    {Utils.parseShortDescription(course)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HomeCourses;
