import React, { useState } from 'react';

const SelectQuestionType = ({ onSelect }) => {
  const [selectedOption, setSelectedOption] = useState('');

  const handleSelect = (event) => {
    const option = event.target.value;
    setSelectedOption(option);
    onSelect(option);
  };

  return (
    <select className='form-control' value={selectedOption} onChange={handleSelect}>
      <option value="">Select an option</option>
      <option value="multiple_choice">Multiple Choice</option>
      <option value="open_ended">Text</option>
    </select>
  );
};

export default SelectQuestionType;
