import { StarOutline, Star } from '@mui/icons-material';
import React, { useState } from 'react';

const SelectStar = ({ initialRating = 0, onRatingChange }) => {
  const [rating, setRating] = useState(initialRating);

  const handleStarClick = (index) => {
    const newRating = index + 1;
    setRating(newRating);
    if (onRatingChange) {
      onRatingChange(newRating);
    }
  };

  const stars = Array.from({ length: 5 }, (_, index) => (
    <span key={index} onClick={() => handleStarClick(index)} style={{ cursor: 'pointer' }}>
      {index < rating ? <Star className="gold-star" /> : <StarOutline />}
    </span>
  ));

  return <div className="review-stars">{stars}</div>;
};

export default SelectStar;
