import React, { useState } from 'react'
import SocialShareButtons from 'view/features/feature_blog/components/SocialShareButtons'

function ShareUrl({title="",url}) {
  

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
      <SocialShareButtons title={title} url={url} showCopy={1} />
    </div>
  )
}

export default ShareUrl