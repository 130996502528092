import React from 'react'
import BlogStyle4 from './blog_styles/BlogStyle4'
import TranslateController from 'controller/shared/TranslateController'

function PostsAside() {

    const {_t} = TranslateController();

  return (
    <div className="col-md-3">

                    <BlogStyle4 title={""}/>

                    {/* <h5 className="font-weight-semi-bold pt-1">Recent Comments</h5>

                    <ul className="list-unstyled mb-4 pb-1 pt-2">

                        <li className="pb-3 text-2">
                            <a href="#" rel="external nofollow" className="font-weight-bold text-dark">John Doe</a> on <a href="blog-post.html" className="text-dark">Main Reasons To Stop Texting And Driving</a>
                        </li>

                        <li className="pb-3 text-2">
                            <a href="#" rel="external nofollow" className="font-weight-bold text-dark">John Doe</a> on <a href="blog-post.html" className="text-dark">Tips to Help You Quickly Prepare your Lunch</a>
                        </li>

                        <li className="pb-3 text-2">
                            <a href="#" rel="external nofollow" className="font-weight-bold text-dark">John Doe</a> on <a href="blog-post.html" className="text-dark">Why should I buy a smartwatch?</a>
                        </li>

                        <li className="pb-3 text-2">
                            <a href="#" rel="external nofollow" className="font-weight-bold text-dark">John Doe</a> on <a href="blog-post.html" className="text-dark">The best augmented reality smartglasses</a>
                        </li>

                        <li className="pb-3 text-2">
                            <a href="#" rel="external nofollow" className="font-weight-bold text-dark">John Doe</a> on <a href="blog-post.html" className="text-dark">12 Healthiest Foods to Eat for Breakfast</a>
                        </li>

                    </ul> */}

                </div>
  )
}

export default PostsAside