import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { PostHttpService } from '../../services';
import { Utils } from 'utils';
import { CourseHttpService } from 'services/course';

export default function SelectPeopleMulti({ callback, placeholder, name="author", type="", value=[], width }) {

    const [SelectOptions, setSelectOptions] = useState([]);

    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: "white",
            width: width ? width : "200px",
            boxShadow: state.isFocused ? null : null,
            "&:hover": {}
        }),
        menu: base => ({
            ...base,
            width: "200px",
            '*::-webkit-scrollbar': {
                width: '0.4em'
            },
            '*::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
            },
            '*::-webkit-scrollbar-thumb': {
                backgroundColor: '#c7c7c7',
            },
            borderRadius: 0,
            borderBottomRightRadius: 5,
            borderBottomLeftRadius: 5,
            marginTop: 0,
            backgroundColor: "#fff",
            opacity: "0.97"
        }),
        menuList: base => ({
            ...base,
            padding: 10,
            "&:hover": {
                borderBottomRightRadius: 5,
                borderBottomLeftRadius: 5,
                transition: "all 0.5s ease-out"
            }
        }),
        option: (base, state) => {
            let backgroundColor = "transparent";
            if (state.isSelected) {
                backgroundColor = "#ccc";
            }
            if (state.isFocused) {
                backgroundColor = "#ccc";
            }
            if (state.isActive) {
                backgroundColor = "#ccc";
            }
            return {
                ...base,
                color: "#000",
                backgroundColor,
                transition: "all 0.5s ease-out"
            };
        }
    };

    useEffect(() => {
        // Instructors are people. if their type is "author", then they are the authors of the posts
        CourseHttpService.loadPeople(type).then((items) => {
            if (items.results) {
                var authors = items.results;          
                if (authors) {
                    var temp_options = []
                    authors.map((item, i) => {
                        var option = {
                            label: Utils.getDefaultName(item),
                            value: item.id
                        };
                        temp_options.push(option);
                    });
                    setSelectOptions(temp_options);
                }
            }
        });
    }, [type]);

    const handleSelect = (selectedOptions) => {
        const values = selectedOptions ? selectedOptions.map(option => option.value) : [];
        callback(values, name);
    };

    return (
        <div className="filter-select">
            <Select
                options={SelectOptions}
                styles={customStyles}
                onChange={handleSelect}
                placeholder={placeholder ? placeholder : "Select"}
                value={SelectOptions.filter(option => value.includes(option.value))}
                isMulti
            />
        </div>
    );
}
