import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { PostHttpService } from '../../services';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function SelectTags({defaultValue=[] , onSelect}) {
  const theme = useTheme();
  const [names, setNames] = React.useState([]); // State for names loaded from API
  const [personName, setPersonName] = React.useState([]); // Default values

  // Load names from an API on component mount
  React.useEffect(() => {
    PostHttpService.loadTags().then(items => {
        setNames(items.results)

        _setDefaultValues(items.results)


      })  }, 
    []); // Empty dependency array means this effect runs once on mount
  

  const _setDefaultValues = (names) => {

    const selectedTitles = defaultValue.map(id => names.find(item => item.id == id).title);
    setPersonName(selectedTitles.map(item=>item));

  }
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    const selectedNames = typeof value === 'string' ? value.split(',') : value;

    const selectedIds = selectedNames.map(title => names.find(item => item.title === title)?.id
    ).filter(id => id !== undefined);

    onSelect(selectedIds,"tags")
  
    setPersonName(
        typeof value === 'string' ? value.split(',') : value,
    );

      
    // onSelect(namesTitles);
    
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 250 }}>
        <InputLabel id="demo-multiple-chip-label">Tags</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Tags" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}

        >
          {names && names.map((item) => (
            <MenuItem
              key={item.title}
              value={item.title}
              style={getStyles(item.title, personName, theme)}
            >
              {item.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
