

export class HttpService {

    static ApiBase = "https://kurdishtalent.academy/api"; 
    // static ApiBase = "http://127.0.0.1:8000"; 
    static SiteBase = "https://kurdishtalent.academy";
    static TOKEN_KEY = "kuraca23";
    
    static DefaultRefetchInterval =  60 * 100000;

   
    headers = new Headers();
    
    static getToken = () => {
        let token = localStorage.getItem(HttpService.TOKEN_KEY)
        if(token == null || token=="null") return null;
        return token;
    };

    static setToken = (token) => {
        localStorage.setItem(HttpService.TOKEN_KEY,token);
    };

    static setUser = (user) => {
        localStorage.setItem("user",JSON.stringify(user));
    };

    static getUser = () => {
        let userObj = localStorage.getItem("user") || null
        if(userObj == null || userObj=="null") return null;
        return JSON.parse(userObj);
    };

    getUserToken = () => {
        let token = localStorage.getItem(HttpService.TOKEN_KEY);
        if(token == null || token=="null") return null;
        return  token;
    };

    setHeaders() {
       this.headers.append("Content-Type", "application/json");
       this.headers.append("Accept", "application/json");    
        const auth = this.getUserToken();
        if (auth) this.headers.append("Authorization", "Bearer " + auth);

    }

    

    async get(url,body)  {
        // console.log("GET", url, body);
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");   
        const auth = this.getUserToken();
        if (auth != null) headers.append("Authorization", "Bearer " + auth);   


        const response = await fetch(`${HttpService.ApiBase}${url}`, {
        headers: new Headers(headers),
        method: 'GET',
        });



        // redirect use if there is a token but they get 401 error
        if(auth!=null && response.status === 401)
        {
            window.localStorage.removeItem(HttpService.TOKEN_KEY);
            // localStorage.removeItem(HttpService.TOKEN_KEY);
            localStorage.removeItem("user");
            window.location.href = '/'
            return
        }

        const data = await response.json();


        return data;
    }

    async post(url,body,hasFile)  {
        var headers = new Headers();
        if(!hasFile)
            headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json"); 
        const auth = this.getUserToken();
        if (auth != null) headers.append("Authorization", "Bearer " + auth); 
        
        const response = await fetch(`${HttpService.ApiBase}${url}`, {
            headers: new Headers(headers),
            method: 'POST',
            body: hasFile ? body : JSON.stringify(body),
        });


        if (response.status === 400) {
            // If the content type is JSON, attempt to parse it and log
            const contentType = response.headers.get("Content-Type");
            if (contentType && contentType.includes("application/json")) {
                const errorData = await response.json(); // Parse the response to get JSON error data
                console.error("Error 400: Bad Request", errorData); // Log the error data
            } else {
                console.error("Error 400: Bad Request: ", response.statusText); // Log the status text directly
            }
            // Optionally, you can throw an error here to stop further execution
            // throw new Error("Bad Request: " + response.statusText);
        } else if (!response.ok) {
            // Handle other non-successful responses if needed
            console.error(`Error ${response.status}: ${response.statusText}`);
            // Optionally, throw an error here as well
        }

        const data = await response.json();


        return data;
    }

    async put(url,body,hasFile=false)  {

        var headers = new Headers();
        if(!hasFile)
            headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json"); 
        const auth = this.getUserToken();
        if (auth) headers.append("Authorization", "Bearer " + auth);   
        
        const response = await fetch(`${HttpService.ApiBase}${url}`, {
            headers: new Headers(headers),
            method: 'PUT',
            body: hasFile ? body : JSON.stringify(body),
        });

        const data = await response.json();



        return data;
    }

    async patch(url,body,hasFile)  {
        
        var headers = new Headers();
        if(!hasFile)
            headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json"); 
        const auth = this.getUserToken();
        if (auth) headers.append("Authorization", "Bearer " + auth);   
        
        const response = await fetch(`${HttpService.ApiBase}${url}`, {
            headers: new Headers(headers),
            method: 'PATCH',
            body: hasFile ? body : JSON.stringify(body),
        });

        const data = await response.json();

        return data;
    }

    async delete(url) {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json"); 
        const auth = this.getUserToken();
        if (auth) headers.append("Authorization", "Bearer " + auth);   

        const response = await fetch(`${HttpService.ApiBase}${url}`, {
        headers: new Headers(headers),
        method: 'DELETE',
        });

        return ;
    }

    async updateActive(url,is_active) {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json"); 
        const auth = this.getUserToken();
        if (auth) headers.append("Authorization", "Bearer " + auth);   

        var body = {
            "is_active" : is_active
        }

        const response = await fetch(`${HttpService.ApiBase}${url}`, {
            headers: new Headers(headers),
            method: 'PATCH',
            body: JSON.stringify(body),
        });

        const data = await response.json();

        return data;
    }

    

    
}

