import { HttpService } from "services/http";
import { Utils } from "utils";

export class FaqHttpService {

    static getFaqList = async ({pageParam =1,size=30})  =>  {
        if(!pageParam) pageParam = 1;
        let query_text = `?page=${pageParam}&size=${size}`;
        let language_id = Utils.getCurrentLanguageId();

        if (language_id) query_text += "&language=" + language_id;

        var service = new HttpService();
        var response = await service.get(`/api/faq/${query_text}`);

        return {response , nextPage: pageParam + 1, totalPages: 30 };

    };

}