import React, { useState } from 'react'
import Modal from '@mui/material/Modal';
import { Backdrop, Box, Fade } from '@mui/material';

function CustomModal(props) {

  // const [open,setOpen] = useState(true)
  const handleClose = () => {
    // setOpen(false)

    if(props.setOpen){
      props.setOpen(false);
    }

  };


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: props.xl ? "90%" : props.big ? 1000 : props.medium ? 800 : 500,
    maxWidth: "95%",
    bgcolor: props.dark ? '#000c' : 'background.paper',
    border: '1px solid #787878',
    boxShadow: 24,
    p: props?.padding ? props?.padding : 2,
  };


  return (
    <Modal
      open={props.open}
      keepMounted
      onClose={handleClose}
      closeAfterTransition
      // BackdropComponent={Backdrop}
      // BackdropProps={{
      //   timeout: 1000,
      // }}
    >
      <Fade in={props.open}>
        <Box sx={style} className={`modal-box ${props.padding==0 && "p-0"} `}>

          <div className={``}>
            <div className={`modal-content  ${props.contentClassName ? props.contentClassName : ""}`}>
              {props.title && (
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">{props.title}</h5>

                </div>
              )}

              <div className='relative show-on-small'>
                <button type="button" className="btn-close btn-colse-single" onClick={handleClose}  aria-label="Close"></button>
              </div>

              {props.showClose && (
                <div className='relative'>
                  <button type="button" className="btn-close  btn-colse-single" onClick={handleClose}  aria-label="Close"></button>
                </div>
              )}
              <div className={`modal-body ${props.className ? props.className : ""}`}>
                {props.children}
              </div>
              {props.footer &&
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={handleClose} data-bs-dismiss="modal">Close</button>
                  <button type="button" className="btn btn-primary">Save changes</button>
                </div>
              }
            </div>
          </div>
        </Box>
      </Fade>

    </Modal>
  )
}

export default CustomModal