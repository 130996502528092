import React, { useEffect, useState, useRef } from 'react';
import CourseForm from 'view/cms/components/forms/course/CourseForm';
import CmsHeader from 'view/cms/components/shared/CmsHeader';
import { useParams } from 'react-router-dom';
import { Utils } from 'utils';
import { CourseHttpService } from 'services/course';
import NotationForm from 'view/cms/features/feature_notation/components/NotationForm';
import NotationPages from '../components/NotationPages';
import NotationArtists from 'view/cms/components/Widgets/course/NotationArtists';
import NotationWriters from 'view/cms/components/Widgets/course/NotationWriters';

function NotationDetailsPage() {
    const { id } = useParams();
    
    const [data, setData] = useState(null);
    const [count, setCount] = useState(0);
    const [showForm, setShowForm] = useState(!id);
    const [loading, setLoading] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);

    const audioRef = useRef(null);

    useEffect(() => {
        if (id) {
            _getData();
        }
    }, [id]);

    const _getData = () => {
        setLoading(true);
        CourseHttpService.loadNotation(id).then(item => {
            setData(item);
            setLoading(false);
        });
    };

    const handlePlayPause = () => {
        const audio = audioRef.current;
        if (audio.paused) {
            audio.play();
            setIsPlaying(true);
        } else {
            audio.pause();
            setIsPlaying(false);
        }
    };

    const handlePause = () => {
        const audio = audioRef.current;
        audio.pause();
        setIsPlaying(false);
    };

    const handleTimeUpdate = () => {
        setCurrentTime(audioRef.current.currentTime);
    };

    const goToAndPlay = (time) => {
        const audio = audioRef.current;

        audio.currentTime = time;
        audio.play();
        setIsPlaying(true);
    };

    return (
        <div className="body">
            <CmsHeader />

            <div role="main" className="main">
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-lg-12 mb-5">
                            <div className="flex-align-items-center flex-space-between mb-3">
                                <h2 className='mb-0'>{data && Utils.getDefaultName(data)}</h2>
                                <button className="btn btn-icon bg-gray" onClick={(e) => { e.preventDefault(); setShowForm(!showForm); }}>
                                    <i className="fas fa-edit" ></i>
                                </button>
                            </div>
                            {showForm && (
                                <NotationForm
                            
                                id={id} onEditDone={(response)=>{
                                    // console.log(response)
                                    if(!id)
                                    {
                                        window.location.href = `/cms/notation/${response.id}`
                                    }
                                }} />
                            )}

                            <div className="mb-5">
                                <NotationPages notationId={id} handlePause={handlePause} goToAndPlay={goToAndPlay} currentTime={currentTime} />
                            </div>

                            {/* <NotationArtists notation={data} />
                            <NotationWriters notation={data} /> */}

                            {/* <PodcastInstructors podcast={data} /> */}

                        </div>
                    </div>
                </div>
            </div>

            <div className="audio-controls-container">
                {/* <button className="btn btn-primary" onClick={handlePlayPause}>
                    {isPlaying ? 'Pause' : 'Play'}
                </button> */}
                <div className="container d-flex flex-center">
                    <span className="ml-3 mx-5" style={{ width: "30px" }}>{Math.floor(currentTime * 1000)}</span>
                    <audio
                        ref={audioRef}
                        src={data?.music_url}
                        onTimeUpdate={handleTimeUpdate}
                        style={{ width: '100%', marginTop: '10px' }}
                        controls
                    />
                </div>
            </div>
            {/* <Footer /> */}
        </div>
    );
}

export default NotationDetailsPage;
