import React, { useEffect, useState, useRef } from 'react';
import { CourseHttpService } from 'services/course';
import NotePageSquareInlineForm from './NotePageSquareInlineForm';
import TranslateController from 'controller/shared/TranslateController';
import DeleteDialog from 'view/cms/components/shared/DeleteDialog';
import Draggable from 'react-draggable';

function NotationPageSquares({ notePage, currentTime, goToAndPlay, handlePause }) {
  const [squares, setSquares] = useState([]);
  const [loading, setLoading] = useState(false);
  const { _t } = TranslateController();
  const [refresh, setRefresh] = useState(0);
  const [selectedId, setSelectedId] = useState(0);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [editingSquare, setEditingSquare] = useState(null);
  const rowRefs = useRef({});
  const [showTable, setShowTable] = useState(false);

  // Compute highlightedSquareId based on currentTime
  const getLastHighlightedSquareId = (squares, currentTime) => {
    const timeInMs = currentTime * 1000;
    for (let i = squares.length - 1; i >= 0; i--) {
      if (squares[i].time < timeInMs) {
        return squares[i].id;
      }
    }
    return null; // Return null if no item meets the condition
  };

  const highlightedSquareId = getLastHighlightedSquareId(squares, currentTime);


  useEffect(() => {
    _getData();
  }, [notePage.id]);

  const _getData = () => {
    setLoading(true);
    CourseHttpService.loadNotePageSquares(notePage.id).then((items) => {
      if (items.results) {
        setSquares(items.results);
      }
      setLoading(false);
    });
  };

  const _handleDelete = (e, id) => {
    setSelectedId(id);
    setRefresh(refresh + 1);
    setDeleteDialogIsOpen(true);
  };

  const _onDeleteDone = () => {
    setSquares(squares.filter(item => item.id !== selectedId));
    setDeleteDialogIsOpen(false);
    setRefresh(refresh + 1);
  };

  const handleSquareClick = (id) => {
    if (editingSquare) return;
    setSelectedId(id);
    const square = squares.find(square => square.id === id);
    if (square) {
      let time = square.time / 1000; // Seek to notePage time in seconds
      goToAndPlay(time);
    }
  };

  const handleSquareDoubleClick = (id) => {
    const square = squares.find(square => square.id === id);
    setEditingSquare(square);
    handlePause();
  };

  const handleDragStop = (e, data, id) => {
    let updatedSquare = null;

    const updatedSquares = squares.map(square => {
      if (square.id === id) {
        updatedSquare = { ...square, top: data.y, left: data.x };

        return updatedSquare;
      }

      return square;
    });
    setSquares(updatedSquares);
    setRefresh(refresh + 1);

    // Update the backend with the new position
    if (updatedSquare) {
      CourseHttpService.updateNotePageSquarePosition(id, updatedSquare);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditingSquare({ ...editingSquare, [name]: value });
  };

  const handleSave = () => {
    const updatedSquares = squares.map(square =>
      square.id === editingSquare.id ? editingSquare : square
    );
    setSquares(updatedSquares);
    setEditingSquare(null);
    CourseHttpService.updateNotePageSquarePosition(editingSquare.id, editingSquare);
  };


  const handleDuplicate = () => {
    const duplicatedSquare = { ...editingSquare, id: null }; // Create a duplicate without an id
    CourseHttpService.saveNotePageSquare(duplicatedSquare).then(response => {

      _getData();
      setEditingSquare(null);
    }).catch(err => {
      console.error("Error duplicating square: ", err);
    });
  };

  const handleSetCurrentTime = () => {
    setEditingSquare({ ...editingSquare, time: Math.floor(currentTime * 1000) });
  };

  const handleClose = () => {
    setEditingSquare(null);
  };

  return (
    <div>
      <div className="box-header">
        <h5>{_t('Squares')}</h5>
        <div className="box-header-btns">
          <button className="btn btn-icon" onClick={() => { setShowTable(!showTable); }}><i className="fa fa-table"></i></button>
        </div>
      </div>
      <table className={`table ${!showTable && 'hide'} `}>
        <thead>
          <tr>
            <th style={{ width: "100px" }}>#</th>
            <th style={{ width: "170px" }} title='Location from top of the page in pixels'>top</th>
            <th style={{ width: "175px" }} title='Location from left of the page in pixels'>left</th>
            <th style={{ width: "175px" }} title='Square width in pixels'>width</th>
            <th style={{ width: "150px" }} title='Square height in pixels'>height</th>
            <th style={{ width: "175px" }} title='Time of showing the square in milliseconds'>time</th>
            <th></th>
          </tr>
        </thead>
        <tbody key={refresh + "ref"}>
          {squares.map(item => (
            <NotePageSquareInlineForm
              onDelete={_handleDelete}
              key={"sq" + item.id}
              notePageId={notePage.id}
              square={item}
              currentTime={currentTime}
              ref={(el) => (rowRefs.current[item.id] = el)}
              className={selectedId === item.id ? 'table-active' : ''}
              onEditDone={_getData}
            />
          ))}
        </tbody>
      </table>

      {addNew && (
        <>
          <h5 className='mt-5'>Add new square</h5>
          <table className="table table-striped">
            <tbody>
              <NotePageSquareInlineForm
                notePageId={notePage.id}
                onEditDone={_getData}
              />
            </tbody>
          </table>
        </>
      )}

      <button className="btn btn-primary" onClick={() => setAddNew(!addNew)}>
        <i className="fa fa-plus"></i>
      </button>

      <div className="d-flex flex-center">
        <div className="note-page-image mt-3 text-center" style={{ position: 'relative' }}>
          <img src={notePage?.image} style={{ width: "800px", height: "1131px" }} alt="Note Page" />
          {squares.map(square => (
            <Draggable
              key={square.id}
              defaultPosition={{ x: square.left, y: square.top }}
              onStop={(e, data) => handleDragStop(e, data, square.id)}
            >
              <div
                onClick={() => handleSquareClick(square.id)}
                onDoubleClick={(e) => { e.stopPropagation(); handleSquareDoubleClick(square.id) }}
                style={{
                  position: 'absolute',
                  width: `${square.width}px`,
                  height: `${square.height}px`,
                  backgroundColor: highlightedSquareId === square.id ? 'rgba(255, 255, 0, 0.5)' : 'rgba(4, 67, 255, 0.2)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  zIndex: editingSquare?.id === square.id ? 1000 : 0,
                }}
              >
                <span>{square.id}</span>

              </div>
            </Draggable>
          ))}


          {editingSquare && (
            <Draggable
              key={editingSquare.id}
              defaultPosition={{ x: 0, y: 100 }}
              // onStop={(e, data) => handleDragStop(e, data, editingSquare.id)}
            >
              <div style={{
                position: 'absolute',
                top: `${editingSquare.top-50}px`,
                left: `${editingSquare.left}px`,
                transform: 'translateX(-50%)',
                background: 'white',
                border: '1px solid #ccc',
                padding: '20px',
                zIndex: 1000,
                width: '370px',
                height: '170px',
                textAlign: 'right',
              }}>
                <div className="row">
                  <div className="col-md-8">
                    <div className='d-flex '>
                      <label className='mr-2' htmlFor="time" style={{ width: "50px" }}>Time  </label>
                      <input
                        type="number"
                        id="time"
                        name="time"
                        className='form-control'
                        placeholder='Time'
                        style={{ width: "100px" }}
                        value={editingSquare.time}
                        onChange={handleInputChange}
                      />
                      <button type="button" title="Set current time" className="btn mr-2" onClick={handleSetCurrentTime}>
                        <i className="fa fa-clock"></i>
                      </button>
                    </div>
                    <div className='mt-2 d-flex'>
                      <label className='mr-2 ' style={{ width: "50px" }} htmlFor="width">Width </label>
                      <input
                        type="number"
                        name="width"
                        style={{ width: "100px" }}
                        className='form-control'
                        id="width"
                        placeholder='Width'
                        value={editingSquare.width}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className='mt-2 d-flex'>
                      <label className='mr-2' htmlFor="height" style={{ width: "50px" }}>Height</label>
                      <input
                        type="number"
                        name="height"
                        className='form-control'
                        style={{ width: "100px" }}
                        id="height"
                        placeholder='Height'
                        value={editingSquare.height}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 d-flex-column-end">
                    <button className='btn btn-info mt-2' onClick={handleSave}>Save</button>
                    <button className='btn btn-info  ml-2 mt-2' onClick={handleDuplicate}><i className="fa fa-copy"></i></button>
                    <button className='btn btn mt-2 ml-2' onClick={handleClose}>Close</button>

                  </div>
                </div>

              </div>
            </Draggable>
          )}
        </div>
      </div>




      <DeleteDialog
        url={`/academy/course/notation/notepage/notesquare/${selectedId}`}
        isOpen={deleteDialogIsOpen}
        key={"mdl" + deleteDialogIsOpen + selectedId}
        setOpen={setDeleteDialogIsOpen}
        notifyDone={_onDeleteDone}
      />
    </div>
  );
}

export default NotationPageSquares;
