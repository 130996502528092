import React, { useState } from 'react'
import { Utils } from '../../../../utils';
import ReviewStars from './ReviewStars';

function CourseReviewList({ reviews }) {
    

    return (
        <div className="pt-3 pb-3 " >
            {reviews?.map(review=>(
                <div className="review-item" key={review.id+"rvw"}>
                    <ReviewStars rating={review.star} />
                    <p className="date">{Utils.formatDate(review.created_at)}</p>
                    <p className="description">{review.description}</p>
                </div>              
            )
            )}
        </div>
    )
}

export default CourseReviewList