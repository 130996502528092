import { CKEditor } from '@ckeditor/ckeditor5-react';

import { TextField } from '@mui/material';
import React, {  useState } from 'react'
import { useForm } from 'react-hook-form';
import { BaseHttpService } from '../../../../../services/base';
import { useQuery } from 'react-query';

function ValuesWidgets({ setValues, values = [] }) {

    const { isLoading, error, data } = useQuery('languages', BaseHttpService.loadLanguages, {
        onSuccess: (data) => {
            setLanguages(data.results)
        },
        cacheTime: 60*60*24*30*1000// Cache results for 30 days
    });
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [languages, setLanguages] = useState(null);



    const handleOnChange = (e, language_id) => {

        var name = null;
        if (values) {
            name = values.find(x => x.language === parseInt(language_id));
        }
        if (name) {
            var temp_names = [...values];
            temp_names.find(x => x.language === parseInt(language_id)).value = e.target.value;
            setValues(values)
        }
        else {

            var new_name = { value: e.target.value, language: parseInt(language_id) }
            var temp_talentNamesa = [];
            if (values)
                temp_talentNamesa = [...values, new_name];
            else temp_talentNamesa = [new_name]
            setValues(temp_talentNamesa)
        }
    }



    return (
        <div className="tabs tabs-bottom tabs-simple bg-tabs">
            <ul className="nav nav-tabs tabs-episodes">

                {languages && languages.map((language, i) =>
                (
                    <li key={"lng" + language.id} className={`nav-item kfont`} onClick={() => { setSelectedTabIndex(i) }}>
                        <a className={selectedTabIndex === i ? "nav-link active" : "nav-link"} href={`#`} onClick={(e)=>{ e.preventDefault()}} data-toggle="tab">{language.name}</a>
                    </li>
                )
                )}

            </ul>
            <div className="tab-content">
                {languages && languages.map((language, i) =>
                (
                    <div id={`namestab${language.id}`} key={"lngupne" + i} className={selectedTabIndex === i ? "tab-pane p-3 active" : "tab-pane p-3"} >
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <TextField
                                        fullWidth
                                        label="Value"
                                        defaultValue={values && values.find(x => x.language === language.id) ? values.find(x => x.language === language.id).value : ""}
                                        variant="outlined"
                                        className='kfont'
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) => { handleOnChange(e, language.id) }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>

    )
}

export default ValuesWidgets