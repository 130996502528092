import React, { useEffect, useState } from 'react'
import { Utils } from 'utils'
import { useQuery } from 'react-query';
import { SettingsHttpService } from 'view/cms/features/feature_settings/services';
import PageLayout from 'view/components/shared/PageLayout'
import TranslateController from 'controller/shared/TranslateController';
import RegisterAndLoginForm from 'view/components/user/RegisterAndLoginForm';
import { useParams } from 'react-router-dom';

function VerifyPage() {

	const { _t } = TranslateController();

	useEffect(() => {
		Utils.initiateTheme()
	}, [])

	// ?email_valid=True
	const { email_valid } = useParams(true);


	return (
		<PageLayout showbreadcrumbs={false} HeaderChild={
			<h1 className="text-color-light my-3 font-weight-bold text-10 text-sm-8 lan-kfont">{_t("Verify")}</h1>
		}>

			<div className="row pt-md-2 pt-3 pb-4 lan-rtl lan-kfont ">
				<div className="col-lg-12 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="300">
					<div className="text-center">
						{/* {email_valid && ( */}
							<h2 className="kfont mb-2">{_t("Your Email has been verified. Now you can login using your email and password")}</h2>
						{/* )} */}
					</div>

					<div className="row justify-content-center">
						<div className="col-md-4 inline-login-form">
							<RegisterAndLoginForm showRegister={false} />

						</div>
					</div>
				</div>
			</div>
		</PageLayout>


	)
}

export default VerifyPage