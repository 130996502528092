import React, { useState } from 'react'
import ContentBreadCrumb from 'view/components/course/content/ContentBreadCrumb'
import ContentVideo from 'view/components/shared/video/ContentVideo'
import ContentViewTabs from 'view/components/course/content/ContentViewTabs'
import { Utils } from 'utils'
import ContentReading from './reading/ContentReading'
import TranslateController from 'controller/shared/TranslateController'
import { Link } from 'react-router-dom'
import CertificatePreview from '../user/CertificatePreview'

function ContentViewDetails({ courseData, content }) {
    const [currentTime, setCurrentTime] = useState(0);
    const [progress, setProgress] = useState(0);

    const handleTranscriptClicked = (transcript) => {
        setCurrentTime(transcript.startTime);
    };

    const { _t } = TranslateController()

    var next_content = content?.next_content;
    var previous_content = content?.previous_content;
    var next_link = "";
    var previous_link = "";
    var showQuizNext = false;

    if (content?.next_content == null && content?.next_quiz != null) {
        showQuizNext = true;
    }

    if (content?.next_content != null && content?.next_content?.lesson !== content.lesson && content?.next_quiz != null) {
        //next content is from next lesson, so show Quizes first
        showQuizNext = true;
    }

    if (showQuizNext && content?.next_quiz) {
        next_link = `/course/${courseData?.id}/lesson/${content.next_quiz.lesson}/quiz/${content.next_quiz?.id}`
    }
    else if (next_content) {
        next_link = `/course/${courseData?.id}/lesson/${next_content.lesson}/content/${next_content?.id}`
    }

    if (previous_content) {
        previous_link = `/course/${courseData?.id}/lesson/${previous_content.lesson}/content/${previous_content?.id}`
    }

    return (
        <div className=''>
            <ContentBreadCrumb content={content} courseData={courseData} />
            {content && (content.url ?
                <>
                    <ContentVideo poster={content?.image ?? "/assets/images/sample.jpg"} content={content} currentTime={currentTime} onProgress={(progress) => { setProgress(progress) }}
                        videoUrl={content.url} />
                    {/* <h2>{content && Utils.getDefaultName(content)}</h2> "https://d35hhtq5kry8us.cloudfront.net/piano.mp4" */}

                    <div className="content-top-next-prev  mb-3 lan-kfont">
                        <Link to={previous_link}> <i className="fa fa-chevron-left"></i> {_t("Previous")}</Link>

                        {next_content && (
                            <Link to={next_link} >{_t("Next")} <i className="fa fa-chevron-right"></i></Link>
                        )}
                    </div>

                    {content && content.course_certificate && (
                        <CertificatePreview certificate={content.course_certificate} />
                    )}

                    <ContentViewTabs content={content} progress={progress} onTranscriptClicked={handleTranscriptClicked} />
                </>
                :
                <>
                    <ContentReading content={content} />

                </>)
            }

        </div>

    )
}

export default ContentViewDetails