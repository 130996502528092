import React from 'react'

function WatchVideoPlayer() {
  return (
    <div className='watch-video-container'>
      {/* <video src="assets/video/kt.mp4" controls /> */}
      <img src="assets/images/sample.jpg" title="" alt="" />

      <div className="video-preview-overlay">
        <span className="fa fa-play"></span>
        <h1>The evelution from analog to digital</h1>
      </div>
    </div>
  )
}

export default WatchVideoPlayer