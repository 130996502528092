import React from 'react'
import EnrollmentSummary from './EnrollmentSummary'
import TranslateController from 'controller/shared/TranslateController'

function UserCourseSummary() {

    const {_t} = TranslateController()

    return (
        <div className="tabs tabs-top mt-3 lan-rtl">
            <ul className="nav nav-tabs nav-tabs-custom ">
                {/* <li className="nav-item ">
                    <a className="nav-link active" href="#home" data-bs-toggle="tab" role="tab">{_t("Home")}</a>
                </li> */}
                <li className="nav-item">
                    <a className="nav-link active" href="#inprogress" data-bs-toggle="tab" role="tab">{_t("In Progress")}</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#completed" data-bs-toggle="tab" role="tab">{_t("Completed")}</a>
                </li>
            </ul>
            <div className="tab-content custom-tabs-content">
                {/* <div className="tab-pane active" id="home">
                    <EnrollmentSummary isFinished={0} size={1} />
                </div> */}
                <div className="tab-pane active" id="inprogress">
                    <EnrollmentSummary isFinished={0} size={10} />
                </div>
                <div className="tab-pane" id="completed">
                    <EnrollmentSummary isFinished={1} />
                </div>
            </div>
        </div>
    )
}

export default UserCourseSummary