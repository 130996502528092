import React from 'react'
import { Utils } from 'utils'

function ContentTranscriptSummary({content,onTranscriptClicked,progress=-1}) {
  return (
    <div>
        {content?.transcripts?.map(transcript=>(
            <div className={`transcript-paragraph ${ (Utils.isTranscriptVisible(transcript,progress)) ? 'active' : "" }`}  key={`transc_`+transcript.id} onClick={()=>{ onTranscriptClicked(transcript) }}>
                {Utils.parseName(transcript)}
            </div>
        ))}
    </div>
  )
}

export default ContentTranscriptSummary