import React, { useEffect, useState } from 'react';

import { Utils } from "utils";

const UserBadge = ({ last_online , setIsOnline=null }) => {
    const getBadgeClass = (lastOnline) => {
        const now = new Date();
        const lastOnlineDate = new Date(lastOnline);
        const diffMinutes = Math.floor((now - lastOnlineDate) / (1000 * 60)); // difference in minutes

        if (!lastOnline) {
            return 'badge-default'; // Default badge color if no last_online data
        }
        if (diffMinutes <= 20) {
            if(setIsOnline) setIsOnline(true)
            return 'badge-success'; // Green for less than 20 minutes
        } else if (diffMinutes <= 120) {
            return 'badge-warning'; // Orange for less than 2 hours
        } else if (diffMinutes <= 1200) {
            return 'badge-danger'; // Red for less than 10 hours (offline)
        }
        else {
            return 'badge-default'; // default for more than 10 hours (offline)
        }
    };

    return (
        <div className={`badge ${getBadgeClass(last_online)}`}>
            <i className="fa-regular fa-clock mr-1"></i> {Utils.timeAgo(last_online)}
        </div>
    );
};

export default UserBadge;