import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { Utils } from 'utils';
import { BaseHttpService } from 'services/base';
import TranslateController from 'controller/shared/TranslateController';

export default function SelectCity({ countryId, onValue, value, width, placeholder, name = "city" }) {

    const [SelectOptions, setSelectOptions] = useState([]);
    const [cities, setCities] = useState([]);

    const { _t } = TranslateController()


    const customStyles = {
        control: (base, state) => ({
            ...base,
            padding: 8,
            background: "white",
            // match with the menu

            width: width ? width : "220px",
            // Overwrittes the different states of border
            // Removes weird border around container
            "&:hover": {
                // Overwrittes the different states of border
            }
        }),
        menu: (base, state) => ({


            ...base,
            width: width ? width : "200px",
            '*::-webkit-scrollbar': {
                width: '0.4em'
            },
            '*::-webkit-scrollbar-thumb': {
                backgroundColor: '#c7c7c7',
            },
            // override border radius to match the box
            borderRadius: 0,
            borderBottomRightRadius: 5,
            borderBottomLeftRadius: 5,
            // kill the gap
            marginTop: 0,
            backgroundColor: "#fff",
            opacity: "1"

        }),
        menuList: base => ({
            ...base,
            // kill the white space on first and last option
            padding: 10,

            "&:hover": {
                borderBottomRightRadius: 5,
                borderBottomLeftRadius: 5,
                transition: "all 0.5s ease-out"
            }
        }),
        option: (base, state) => {


            let backgroundColor = "transparent";

            if (state.isSelected) {
                backgroundColor = "#ccc";
            }

            if (state.isFocused) {
                backgroundColor = "#ccc";
            }
            if (state.isActive) {
                backgroundColor = "#ccc";
            }

            return {
                ...base,
                color: "#000",
                backgroundColor,
                transition: "all 0.5s ease-out"
            };
        }

    };




    useEffect(() => {

        if (countryId) {
            BaseHttpService.loadCities(countryId).then(data => {
                setCities(data.results);
                let cities = data.results

                if (cities) {
                    var temp_options = []
                    cities.map((item, i) => {
                        var option = null;
                        option = {
                            label: Utils.getDefaultName(item),
                            value: item.id
                        }
                        temp_options.push(option)
                    })
                    //
                    setSelectOptions(temp_options);
                    // callback()
                    //setTalents(temp)
                }
            })
        }

    }, [countryId])

    const handleSelect = (e) => {

        onValue(e && e.value, name)


    }

    return (
        <>
            <div className="filter-select kfont">
                <Select
                    options={SelectOptions}
                    styles={customStyles}
                    onChange={handleSelect}
                    placeholder={placeholder ? _t(placeholder) : _t("City")}
                    isClearable={true}
                    value={SelectOptions && SelectOptions.filter(obj => obj.value == value)}


                />
            </div>

        </>
    )
}
