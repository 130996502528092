import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Utils } from '../../../../utils';
import { Link } from 'react-router-dom';
import { Avatar } from '@mui/material';
import { BaseHttpService } from 'services/base';
import DeleteDialog from '../shared/DeleteDialog';
import CustomModal from 'view/components/modals/CustomModal';

function SupportMessageRepliesList({ supportMessageId }) {
  const [data, setData] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);
  const [selectedId, setSelectedId] = useState(0);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [selectedSupportMessage, setSelectedSupportMessage] = useState(null);

  const handlePageChange = (newPageNumber) => {
    setPageNumber(newPageNumber);
    _getData(newPageNumber)
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

  };

  useEffect(() => {

    _getData(1)

    return () => {
    }
  }, [])

  const _getData = (pageNumber) => {

    BaseHttpService.loadSupportMessageReplies(supportMessageId, pageNumber).then((items) => {
      if (items.results) {
        setData(items.results)
        setCount(items.count)
        setTotalPages(Math.ceil(items.count / 30));
      }
    });

  }



  return (
    <div className='box'>
      <div className="box-body">
        <div className="replies-container mb-3">
          {data && data.map(item => (
            <div key={item.id + "smr"} className="kfont lan-rtl ">
              <div className="featured-box featured-box-primary featured-box-effect-4 min-height-0 border-all-light border-radius box-shadow-none box-shadow-1 box-shadow-1-hover border-color-transparent-hover">
                <div className="box-content d-flex p-3 border-0 p-1 text-left lan-text-right">
                  <div className="mr-2 ">
                    {/* <i className="icon-featured icon-featured-small fa fa-message"></i> */}
                    <Avatar className='' title={item?.user.profile.first_name}   alt={item?.user.profile.first_name} src={Utils.getProfileImage(item?.user.profile)} />

                  </div>
                  <p className="mb-0 text-left lan-text-right">{item.message}</p>
                </div>
              </div>
            </div>

          ))}
        </div>

        {totalPages > 1 && (

          <div className="mt-2">
            <ul className="pagination">
              <li className={`page-item ${pageNumber === 1 ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => handlePageChange(pageNumber - 1)} disabled={pageNumber === 1}>
                  <i className="fas fa-angle-left"></i>
                </button>
              </li>
              {Array.from({ length: totalPages }, (_, index) => (
                <li key={index} className={`page-item ${pageNumber === index + 1 ? 'active' : ''}`}>
                  <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                    {index + 1}
                  </button>
                </li>
              ))}
              <li className={`page-item ${pageNumber === totalPages ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => handlePageChange(pageNumber + 1)} disabled={pageNumber === totalPages}>
                  <i className="fas fa-angle-right"></i>
                </button>
              </li>
            </ul>
          </div>

        )}
      </div>


      <DeleteDialog
        url={`/api/supportmessages/${selectedId}`}
        isOpen={deleteDialogIsOpen}
        passwordNeeded={false}
        key={"mdl" + deleteDialogIsOpen + selectedId}
        setOpen={setDeleteDialogIsOpen}
        notifyDone={() => { _getData(pageNumber); setDeleteDialogIsOpen(false) }}
      />

      <CustomModal big key={editModalIsOpen} setOpen={(value) => setEditModalIsOpen(value)}
        open={editModalIsOpen}  >

        {selectedSupportMessage?.title}
        {/* <SupportMessageDetails support_message={selectedSupportMessage} /> */}

      </CustomModal>

    </div>
  )
}

export default SupportMessageRepliesList