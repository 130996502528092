import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, TextField, TextareaAutosize } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { Edit } from "@mui/icons-material";
import Done from "view/cms/components/shared/Done";
import Error from "view/cms/components/shared/Error";
import LoadingInside from "view/cms/components/shared/LoadingInside";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { SettingsHttpService } from "../services";
import  SelectStatus from "view/cms/features/feature_blog/components/forms/SelectStatus";
import SelectLanguage from "../../feature_blog/components/forms/SelectLanguage";

function FaqForm({ id, lesson, ...props }) {
  const { register, handleSubmit, setValue, watch, formState: { errors }, } = useForm();
  const [editingItem, setEditingItem] = useState(null);

  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [item_image, setImage] = useState(null);
  const [data, setData] = useState(null);


  const fileRef = useRef()

  useEffect(() => {
    if (id) {
      SettingsHttpService.loadFaq(id).then(item => {
        setData(item)
        onEditing(item)
      })
    }
  }, [])

  const onEditing = (item) => {
    setEditingItem(item)


    for (const key in item) {
      setValue(key, item[key])
    }

    setRefresh(refresh + 1)
    setImage(item.image)
  }

  const saveChanges = (data) => {

    setError(false)
    if (editingItem) data.id = editingItem.id;

    var body = {
      "id": id ? id : data.id,
      "question": data.question,
      "answer": data.answer,
      "language": data.language,
      "status": data.status,
    }


    setDone(false)
    setLoading(true)
    SettingsHttpService.saveFaq(body).then(response => {
      setLoading(false)
      setDone(true)
      setError(false)
      props.onEditDone(response);
    }).catch(err => {
      setLoading(false)
      // setError("Something went wrong")
    });
  };

  const handleValue = (value, name) => {
    switch (name) {

      case "status":
        setValue("status", value)
        break;
      case "language":
        setValue("language", value)
        break;
      default:
        break;
    }
  }



  return (
    <div>


      <form className="mt-4" onSubmit={handleSubmit(saveChanges)}>
        <div className="row">
          <div className="col-md-8">
            <div className="form-group">
              <TextField
                fullWidth
                label="Question"
                value={watch('question') ?? ""}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                {...register("question")}
              />
            </div>
            <hr className="solid" />

            <div className="form-group">
              <h5>Answer</h5>
              <CKEditor
                editor={ClassicEditor}
                data={data && data.answer}
                onReady={editor => {
                  // You can store the "editor" and use when it is needed.
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setValue("answer", data)
                }}
              />
            </div>
            <hr className="solid" />

            

          </div>

          <div className="col-md-4">
            <div className="form-group">
              <SelectStatus placeholder="Status" width={250} callback={handleValue} value={watch("status")} border={"1"} />
            </div>
            <div className="form-group">
              <SelectLanguage placeholder="Language" width={250} callback={handleValue} value={watch("language")} border={"1"} />
            </div>
          </div>
        </div>
        <div className="form-group mt-3 text-right mb-1">
          <Button
            onClick={handleSubmit}
            type="submit"
            variant="contained"
            startIcon={editingItem ? <Edit /> : <AddIcon />}
          >
            {editingItem ? "Save Changes" : "Add FAQ"}
          </Button>


        </div>
        <div className="form-group">
          {done && <Done />}
          {error && <Error message={error} />}
          {loading && <LoadingInside />}
        </div>
      </form>
    </div>
  );
}

export default FaqForm;
