import { Checkbox, FormControlLabel } from '@mui/material';
import TranslateController from 'controller/shared/TranslateController';
import React, { useState } from 'react'
import { CourseHttpService } from 'services/course';
import { Utils } from 'utils'
import Error from 'view/cms/components/shared/Error';

function EnrollmentWidget({ course , onEnrollment}) {

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [termsChecked, setTermsChecked] = useState(false);
    const { _t } = TranslateController()

    const handleTermsChecked = (event) => {
        setTermsChecked(event.target.checked);
    };

    const enroll = (event) => {
        setError("")
        if(!termsChecked){
            setError(_t("Please accept terms before enroll"))
            return
        }
        setLoading(true)

        CourseHttpService.enroll(course?.id).then((res) => {
            if(res?.id){
                onEnrollment(res)
            }
            setLoading(false)
        })
    }

    return (
        <div className='lan-rtl'>
            <h2>{Utils.getDefaultName(course)}</h2>
            <div className='kfont'>{Utils.parseDescription(course,100)}</div>
            <div className={`mt-5 agree ${(error && !termsChecked) ? 'box-error' : ''}`}>
                <FormControlLabel
                    className='kfont'
                    control={<Checkbox checked={termsChecked} onChange={handleTermsChecked} />}
                    label={_t('I agree with the terms and conditions.')}
                />
            </div>
            <button onClick={() => { if(!loading) enroll(); }} className="btn mt-3 mb-2 btn-light btn-outline btn-outline-thin btn-outline-light-opacity-2 font-weight-semi-bold px-4 btn-py-2 text-3 text-color-light text-color-hover-dark ml-2" >
                {loading ? "..." :_t("Enroll")} <i className="fa fa-star ml-1"></i>
            </button>
            {error && <Error message={error} />}

        </div>
    )
}

export default EnrollmentWidget