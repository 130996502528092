import { Button } from '@mui/material';
import TranslateController from 'controller/shared/TranslateController';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { CourseHttpService } from 'services/course';
import { Utils } from 'utils';

function NotationsGrid({ query, size = 30, excludeId = null, start_letter, scrollTop = 1, artistId,peopleId, loadMore = false }) {
    const [notations, setNotations] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [count, setCount] = useState(0);
    const {_t} = TranslateController();

    const { artist_id } = useParams();

    if (artist_id) {
        artistId = artist_id;
    }

    const { isLoading, error, data, refetch } = useQuery(
        ['notations', pageNumber,start_letter],
        () => {
            return CourseHttpService.searchNotations(query, pageNumber, size, artistId,peopleId);
        },
        {
            onSuccess: (data) => {
                if (loadMore) {
                    // setNotations((prevNotations) => [...prevNotations, ...data.results]);

                    setNotations(prevData => {
                        const existingIds = new Set((prevData || []).map(item => item.id));
                        // Filter out the new data that already exists in the current data
                        const filteredNewData = data.results.filter(item => !existingIds.has(item.id));
                        // Append only the unique items to the existing list
                        return [...(prevData || []), ...filteredNewData];
                      });

                } else {
                    // alert(loadMore);
                    console.log(data);

                    setNotations(data.results);
                }
                setTotalPages(Math.ceil(data.count / size));
                setCount(data.count);
            },
            refetchInterval: 600 * 100000,
        }
    );

    useEffect(() => {
        refetch();
    }, [query, pageNumber, artistId]);

    const handleLoadMore = () => {
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
        // if (scrollTop) {
        //     window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        // }
    };

    const handlePageChange = (newPageNumber) => {
        setPageNumber(newPageNumber);
        if (scrollTop) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    };

    return (
        <div className="mt-1 container notation-grid-container">
            <div className="row portfolio-list sort-destination lan-rtl">
                {notations?.map((notation) => (
                    <div key={notation.id + 'ngi'} className="col-6 col-md-3 col-lg-2 brands">
                        <div className="portfolio-item">
                            <a href={`/notation/${notation.id}/${Utils.slugify(Utils.getDefaultName(notation))}`}>
                                <span className="thumb-info thumb-info-lighten border-radius-0 kfont lan-rtl">
                                    <span className="thumb-info-wrapper border-radius-0">
                                        <img src={Utils.getNotationImage(notation)} className="img-fluid border-radius-0 notation-grid-img" alt="" />
                                        <span className="thumb-info-title">
                                            <span className="thumb-info-inner">{Utils.getDefaultName(notation)}</span>
                                            <span className="thumb-info-type">{notation?.level && Utils.getDefaultName(notation?.level)}</span>
                                        </span>
                                        <span className="thumb-info-action">
                                            <span className="thumb-info-action-icon bg-dark opacity-8"><i className="fas fa-play"></i></span>
                                        </span>
                                    </span>
                                </span>
                            </a>
                        </div>
                    </div>
                ))}
            </div>

            {loadMore ? (
                <div className="text-center mt-3 mb-3">
                    {pageNumber < totalPages && (
                        <Button variant="outlined" onClick={handleLoadMore} disabled={isLoading}>
                            {isLoading ?  _t('Loading...') : _t('Load More')}
                        </Button>
                    )}
                </div>
            ) : (totalPages>1) && (
                <ul className="pagination float-end">
                    <li className={`page-item ${pageNumber === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageChange(pageNumber - 1)} disabled={pageNumber === 1}>
                            <i className="fas fa-angle-left"></i>
                        </button>
                    </li>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <li key={index} className={`page-item ${pageNumber === index + 1 ? 'active' : ''}`}>
                            <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                                {index + 1}
                            </button>
                        </li>
                    ))}
                    <li className={`page-item ${pageNumber === totalPages ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageChange(pageNumber + 1)} disabled={pageNumber === totalPages}>
                            <i className="fas fa-angle-right"></i>
                        </button>
                    </li>
                </ul>
            )}
        </div>
    );
}

export default NotationsGrid;
