import React, { useEffect, useRef, useState } from "react";

import { useForm } from "react-hook-form";
import { Button, TextField } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { CourseHttpService } from "../../../../../services/course";
import Done from "../../shared/Done";
import Error from "../../shared/Error";
import Loading from "../../shared/Loading";
import { Edit } from "@mui/icons-material";
import NamesWidgets from "../shared/NamesWidgets";
import SelectQuestionType from "./selects/SelectQuestionType";

function QuizQuestionForm({ id, quizId, contentId, ...props }) {

  const { register, handleSubmit, setValue, watch, formState: { errors }, } = useForm();
  const [editingItem, setEditingItem] = useState(null);

  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [names, setNames] = useState([]);

  const fileRef = useRef()

  useEffect(() => {
    if (id) {
      CourseHttpService.loadQuizQuestion(id).then(item => {
        onEditing(item)
      })
    }
  }, [])

  const onEditing = (item) => {
    setEditingItem(item)

    for (const key in item) {
      setValue(key, item[key])
    }

    setNames(item.names)
    setRefresh(refresh + 1)

  }

  const saveChanges = (data) => {

    setError(false)
    if (editingItem) data.id = editingItem.id;

    var body = {
      id: id ? id : data.id,
      names: names,
      question_type: "multiple_choice",
      quiz: quizId ?? null,
      content: contentId ?? null,
    }

    if (contentId) {
      body["show_at_time"] = data.show_at_time;
    }

    setDone(false)
    setLoading(true)
    CourseHttpService.saveQuizQuestion(body).then(response => {
      setLoading(false)
      setDone(true)
      setError(false)
      console.log(response)
      props.onEditDone(response);
    }).catch(err => {
      setLoading(false)
      // setError("Something went wrong")
    });
  };


  return (
    <div>
      <form className="" onSubmit={handleSubmit(saveChanges)}>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <NamesWidgets key={refresh + "qnamesw"} names={names} setNames={(names) => { setNames(names) }} />
            </div>
          </div>
          {contentId && (
            <div className="col-md-12">
              <div className="form-group">
                <TextField
                  fullWidth
                  label="Show At Time"
                  variant="outlined"
                  {...register("show_at_time")}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </div>
          )}
          <div className="col-md-12">
            <div className="form-group">
              <SelectQuestionType onSelect={(value) => { setValue("question_type", value) }} />
            </div>
          </div>
        </div>
        <div className="form-group mt-3 text-right mb-1">
          <Button
            onClick={handleSubmit}
            type="submit"
            variant="contained"
            startIcon={editingItem ? <Edit /> : <AddIcon />}
          >
            {editingItem ? "Save Changes" : "Add Question"}
          </Button>


        </div>
        <div className="form-group">
          {done && <Done />}
          {error && <Error message={error} />}
          {loading && <Loading />}
        </div>
      </form>
    </div>
  );
}

export default QuizQuestionForm;
