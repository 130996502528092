import React from 'react';
import TranslateController from 'controller/shared/TranslateController';

function BottomSoundPlayer({ isPlaying, currentTrack, totalTime,playingShuffle, currentTime, handlePlayPause, Playnext, PlayPrevious, Repeat, handleShuffle, onSeek }) {
  const { _t } = TranslateController();

  const progress = (currentTime / totalTime) * 100;

  function formatDuration(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

  const handleProgressClick = (e) => {
    const progressBar = e.currentTarget;
    const rect = progressBar.getBoundingClientRect();
    const clickPosition = e.clientX - rect.left;
    const clickProgress = clickPosition / progressBar.offsetWidth;
    const newTime = totalTime * clickProgress;
    onSeek(newTime);
  };

  var enabled = currentTrack!=null;

  return (
    <div className={`bottom-sound-player ${!enabled && "disabled"}`}>
      <div className="row full-width">
        <div className="col-md-3"></div>
        <div className="col-md-6">
          <div className="bottom-player-controls">
            <button className="btn" onClick={() => { if(enabled) handleShuffle() }}>
              <i className={`fa-solid fa-shuffle ${playingShuffle && 'color-control-active '} `}></i>
            </button>
            <button className="btn" onClick={() => { if(enabled) PlayPrevious() }}>
              <i className="fa-solid fa-backward-step"></i>
            </button>
            <button className="btn" onClick={() => { if(enabled) handlePlayPause() }}>
              {!isPlaying ? (
                <i className="fa fa-play-circle "></i>
              ) : (
                <i className="fa fa-pause-circle "></i>
              )}
            </button>
            <button className="btn" onClick={() => { if(enabled) Playnext() }}>
              <i className="fa-solid fa-forward-step"></i>
            </button>
            <button className="btn" onClick={() => { if(enabled) Repeat() }}>
              <i className="fa-solid fa-repeat"></i>
            </button>
          </div>
          <div className="bottom-player-progress-indicator">
            <div style={{ width: 30 }}>{formatDuration(currentTime)}</div>
            <div className="progress" onClick={(e)=>{ if(enabled) handleProgressClick(e)  }}>
              <div className="progress-bar bottom-player-progress-bar" data-appear-progress-animation={`${progress}%`} data-appear-animation-delay="20" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{ width: `${progress}%`, marginLeft: progress > 0 ? 0 : "10px" }}>
              </div>
            </div>
            <div style={{ width: 40 }}>{formatDuration(totalTime)}</div>
          </div>
        </div>
        <div className="col-md-3"></div>
      </div>
    </div>
  );
}

export default BottomSoundPlayer;
