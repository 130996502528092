import React, { useEffect, useRef, useState } from "react";
import SupportMessageReplyForm from "./SupportMessageReplyForm";
import SupportMessageRepliesList from "../../lists/UserSupportMessageRepliesList";

function SupportMessageReply({ supportMessageId, ...props }) {

  const [refresh,setRefresh] = useState(0)
  return (
    <div>
          <SupportMessageRepliesList key={refresh+"smrls"} supportMessageId={supportMessageId} />
          <hr className="solid" />
          <SupportMessageReplyForm supportMessageId={supportMessageId} onDone={(response)=>{  setRefresh(refresh+1) }}  />
    </div>
  );
}

export default SupportMessageReply;
