import React from 'react'
import RecentlyViewedCoursesController from 'controller/course/content/RecentlyViewedCoursesController'
import CoursesGridView from '../components/CoursesGridView'

function RecentlyViewedCourses() {
    const { data, isLoading, error,_t } = RecentlyViewedCoursesController()


    return (
        <div className="mb-4 full-width lan-rtl lan-kfont ">
            <h2>{_t('Based on Your Recent Views')}</h2>
            <CoursesGridView data={data} isLoading={isLoading} />
        </div>
    )
}

export default RecentlyViewedCourses