import React, { useState } from 'react'

function ReviewPercentageItem({ rating , value }) {
    
    return (
            <div className="review-percentage-item">
                <div className='review-text'>{rating} star{rating>1 ? "s" : ""}</div>
                <div className='custom-progress'>
                    <div className='custom-progress-value' style={{ width: `${value}%` }}></div>
                </div>
                <div>{value} %</div>
            </div>
    )
}

export default ReviewPercentageItem