import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { CourseHttpService } from 'services/course'
import { Utils } from 'utils'

function ContentViewController(course_id) {
  const { content } = useParams()
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [courseData,setCourse] = useState(null);

  const { isLoading, error, data } = useQuery(['content', content], () => {
    return CourseHttpService.loadContent(content)
  }, {
    onSuccess: (data) => {
		if(data){
			document.title = Utils.SiteTitle + Utils.getDefaultName(data);
		}
      // Utils.initiateTheme()
    },
	refetchInterval: 600*10000,
  })


	const { isLoading: isLoadingCourse, error: errorCourse, refetch: refetchCourse, data: courseInfo } = useQuery(['course', course_id], () => {
		return CourseHttpService.loadCourse(course_id)
	  }, {
		onSuccess: (data) => {
		  setCourse(data)
		  setBreadcrumbs([
			{ 
			  "title": Utils.getDefaultName(data),
			  "url": `/course/${course_id}/lectures`
			}
		  ]);
		  // Utils.initiateTheme()
		},
		refetchInterval: 600*1000,

	  });

  return { data, isLoading, error,breadcrumbs,courseData }
}

export default ContentViewController