import React from 'react'
import VideoList from './VideoList'

function Suggestions() {
    return (
                <div class="suggestions bg-tabs mt-3 ">
                    <h2>Suggestions</h2>
                   <VideoList /> 
                </div>

    )
}

export default Suggestions