import { ClosedCaptionOffOutlined, DragHandle, QuestionMarkOutlined, QuizOutlined } from '@mui/icons-material';
import React, { useState } from 'react'
import Quizes from './quiz/Quizes';
import ContentQuestions from './quiz/ContentQuestions';
import { Utils } from 'utils';
import CustomModal from 'view/components/modals/CustomModal';
import TranslateController from 'controller/shared/TranslateController';
import ContentForm from '../../forms/course/ContentForm';
import DeleteDialog from '../../shared/DeleteDialog';
import SortModal from '../shared/sort/SortModal';
import ContentsController from 'view/cms/controllers/course/content/ContentsController';
import Tracks from './content/Tracks';

function Contents({ lesson }) {
    const { _t } = TranslateController();
    const [sortModalIsOpen, setSortModalIsOpen] = useState(false);
    const [tracksModalIsOpen, setTracksModalIsOpen] = useState(false);
    
    const { data, setData, setSelectedId, setEditModalIsOpen, _showQuizes, editModalIsOpen, selectedId, deleteDialogIsOpen
        , questionModalIsOpen, setQuestionModalIsOpen, _showQuestions, setDeleteDialogIsOpen, quizModalIsOpen, setQuizModalIsOpen, navigate, _onDeleteDone, _handleDelete, _edit } = ContentsController(lesson)


    return (
        <div className='box '>
            <div className="box-header mb-5">
                <h5>{_t('Contents')}</h5>
                <div className="box-header-btns">
                    <button className="btn btn-icon" title="Sort contents" onClick={() => { setSelectedId(null); setSortModalIsOpen(true) }}><DragHandle /> </button>
                    <button className="btn btn-icon" onClick={() => { setSelectedId(null); setEditModalIsOpen(true) }}><i className="fa fa-plus"></i></button>
                </div>
            </div>
            <div className="box-body">
                <div >
                    <div className="accordion accordion-modern" id="contentsaccordion">
                        {data?.map(item => (
                            <div className="card card-default" key={`ccontent${item.id}`}>
                                <div className="card-header">
                                    <h4 className="card-title m-0">
                                        <a className="accordion-toggle collapsed" data-bs-toggle="collapse" data-bs-target={`#contentcollapse${item.id}`} aria-controls={`#contentcollapse${item.id}`} aria-expanded="false">
                                            <div>
                                                <span className="sort_order">{item.sort_order + 1}</span>
                                                {/* <i className="fas fa-check"></i>  */}
                                                {Utils.getDefaultName(item)} <span className='small'>( {item.type} )</span>
                                            </div>
                                            <div className="accordion-btns">
                                                <button className="btn btn-accordion" title={"Quizes"} onClick={(e) => { e.preventDefault(); e.stopPropagation(); _showQuizes(e, item) }}>
                                                    <QuizOutlined />
                                                </button>
                                                <button className="btn btn-accordion" title={"Tracks"} onClick={(e) => { e.preventDefault(); e.stopPropagation();  setTracksModalIsOpen(item.id) }}>
                                                    <ClosedCaptionOffOutlined />
                                                </button>
                                                
                                                <button className="btn btn-accordion" title={"Questions"} onClick={(e) => { e.preventDefault(); e.stopPropagation();  _showQuestions(e, item) }}>
                                                    <QuestionMarkOutlined />
                                                </button>
                                                <button className="btn btn-accordion" onClick={(e) => { e.preventDefault(); e.stopPropagation(); _edit(e, item) }}>
                                                    <i className="fas fa-edit" ></i>
                                                </button>
                                                <button onClick={(e) => { e.preventDefault(); e.stopPropagation(); navigate(`/cms/content/${item.id}`) }} className="btn btn-accordion" >
                                                    <i className="fas fa-eye" ></i>
                                                </button>

                                                <button className="btn btn-accordion" onClick={(e) => { e.preventDefault(); e.stopPropagation(); _handleDelete(e, item.id) }}>
                                                    <i className="fas fa-trash" ></i>
                                                </button>
                                            </div>
                                        </a>
                                    </h4>
                                </div>
                                <div id={`contentcollapse${item.id}`} className="collapse">
                                    <div className="card-body">
                                        {item.url && (
                                            <>

                                                <video controls style={{ width: "100%" }} >
                                                    <source src={item.url} type="video/mp4" />
                                                </video>
                                                <b>Video url</b> : <a href={`${item.url}`} target='_blank'>{item.url}</a> <br />

                                                <hr />

                                            </>
                                        )}
                                        {item.document && (
                                            <>
                                                <b>document url</b> : <a href={`${item.document}`} target='_blank'>{item.document}</a> <br />
                                                <hr />
                                            </>
                                        )}
                                        {Utils.parseDescription(item)}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>


            <SortModal type="content" data={data} setData={setData} isOpen={sortModalIsOpen} setIsOpen={setSortModalIsOpen} />


            {editModalIsOpen && (
                <CustomModal className="" big key={editModalIsOpen} setOpen={(value) => setEditModalIsOpen(value)}
                    open={editModalIsOpen} >

                    <ContentForm key={`contentmd${selectedId}`} lesson={lesson} id={selectedId}
                        onEditDone={(content) => {
                            let index = data.findIndex(item => item.id === content.id);
                            if (index > -1) {
                                data[index].names = content.names;
                                data[index].url = content.url;
                            }
                            else {
                                data.push(content);
                            }
                            setData(data)
                        }}
                    />

                </CustomModal>
            )}
            {quizModalIsOpen && (
                <CustomModal big className="" key={quizModalIsOpen} setOpen={(value) => setQuizModalIsOpen(value)}
                    open={quizModalIsOpen} >

                    <Quizes contentId={selectedId} />

                </CustomModal>
            )}

            {tracksModalIsOpen && (
                <CustomModal big className="" key={tracksModalIsOpen} setOpen={(value) => setTracksModalIsOpen(value)}
                    open={tracksModalIsOpen} >

                    <Tracks contentId={tracksModalIsOpen} />

                </CustomModal>
            )}

            {questionModalIsOpen && (
                <CustomModal big className="" key={questionModalIsOpen + "qmio"} setOpen={(value) => setQuestionModalIsOpen(value)}
                    open={questionModalIsOpen} >

                    <ContentQuestions contentId={selectedId} />

                </CustomModal>
            )}
            {deleteDialogIsOpen && (
                <DeleteDialog
                    url={`/academy/course/content/${selectedId}`}
                    isOpen={deleteDialogIsOpen}
                    key={"mdl" + deleteDialogIsOpen + selectedId}
                    setOpen={setDeleteDialogIsOpen}
                    notifyDone={_onDeleteDone}
                />
            )}




        </div>
    )
}

export default Contents