import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link, useNavigate, useParams } from "react-router-dom";
import { CourseHttpService } from '../../../../services/course';
import { Utils } from '../../../../utils';
import CustomModal from '../../../components/modals/CustomModal';
import DeleteDialog from '../shared/DeleteDialog';
import CourseForm from '../forms/course/CourseForm';
import { DragHandle, Grid3x3, List } from '@mui/icons-material';
import SortModal from '../Widgets/shared/sort/SortModal';
import AdminView from '../shared/Permissions';
import EnrolledUsersList from './EnrolledUsersList';
import CourseListTable from './CourseListTable';

function CourseList({ gallery }) {

  var navigate = useNavigate()
  const [data, setData] = useState(null);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = React.useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = React.useState(false);
  const [sortModalIsOpen, setSortModalIsOpen] = useState(false);
  const [enrolledModalIsOpen, setEnrolledModalIsOpen] = useState(false);
  const [showAsList, setShowAsList] = useState(1);

  const [selectedId, setSelectedId] = React.useState(0);
  const { id } = useParams();
  const [refresh, setRefresh] = React.useState(0);

  useEffect(() => {

    _getData()

    return () => {
    }
  }, [])

  const _getData = () => {
    setLoading(true);
    CourseHttpService.loadCourses().then((items) => {
      if (items.results) {
        setData(items.results)
      }
    });
  }


  const _handleDelete = (e, id) => {
    setSelectedId(id);
    setRefresh(refresh + 1)
    setDeleteDialogIsOpen(true)
  }

  const _showEnrolledUsers = (e, id) => {
    setSelectedId(id);
    setRefresh(refresh + 1)
    setEnrolledModalIsOpen(true)
  }

  const _onDeleteDone = () => {
    var temp = data.filter(item => item.id !== selectedId)
    setData(temp)
    setDeleteDialogIsOpen(false);
    setRefresh(refresh + 1)
  }

  const _edit = (e, item) => {
    e.preventDefault();
    setSelectedId(item.id);
    setEditModalIsOpen(true)
    setRefresh(refresh + 1)
  }



  return (
    <div className='box'>
      <div className="box-header mb-5">
        <h5>Courses</h5>
        <div className="btns box-header-btns">
          <AdminView>
            <button className="btn btn-icon" onClick={() => { setSelectedId(null); setShowDelete(!showDelete) }}><i className="fa fa-trash "></i></button>
            <button className="btn btn-icon" title="Sort courses" onClick={() => { setSelectedId(null); setSortModalIsOpen(true) }}><DragHandle /> </button>
            <button className="btn btn-icon" title="Change view" onClick={() => { setShowAsList(!showAsList);  }}>{showAsList ? (<Grid3x3 />) : (<List />) }</button>
            <button className="btn btn-icon" onClick={() => { setSelectedId(null); setEditModalIsOpen(true) }}><i className="fa fa-plus "></i></button>
          </AdminView>
        </div>
      </div>
      {(showAsList == 1) && (

      <div className="box-body">
        <CourseListTable data={data} handleDelete={_handleDelete} showDelete={showDelete} />
      </div>
      )}
      {(showAsList == 0) && (

      <div className="box-body general-list">
        {data?.map(item => (
          <div key={item.id + "cmp"} className='general-list-item ' onClick={(e) => { navigate(`/cms/course/${item.id}`) }}>
            <img className="img-fluid mb-4" src={Utils.getImage(item)} alt=""></img>
            <div className="caption">
              <h3> {Utils.getDefaultName(item)}</h3>
              <div className="btns">
                {/* <Link to={`/cms/course/${item.id}`} href="#edit"  >
                  <i className="fa fa-edit"></i>
                </Link> */}
                {showDelete && (
                  <button className="btn" onClick={(e) => { e.stopPropagation(); _handleDelete(e, item.id) }}>
                    <i className="fa fa-trash "></i>
                  </button>
                )}
                <Link to={`/cms/course/${item.id}/enrolled`} className="btn text-3 " onClick={(e) => { e.stopPropagation(); }}>
                  <i className="fa fa-user "></i> {item.enrolled_count}
                </Link>
              </div>
            </div>
          </div>
        )
        )}
      </div>
      )}

      



      {/* <CustomModal big key={enrolledModalIsOpen+"enmod"} setOpen={(value) => setEnrolledModalIsOpen(value)}
        open={enrolledModalIsOpen}  >

        <EnrolledUsersList courseId={selectedId} />

      </CustomModal> */}

      <CustomModal big key={editModalIsOpen} setOpen={(value) => setEditModalIsOpen(value)}
        open={editModalIsOpen}  >

        <CourseForm onEditDone={() => { _getData(); setEditModalIsOpen(false) }} />

      </CustomModal>

      <DeleteDialog
        url={`/academy/course/${selectedId}`}
        isOpen={deleteDialogIsOpen}
        key={"mdl" + deleteDialogIsOpen + selectedId}
        setOpen={setDeleteDialogIsOpen}
        notifyDone={_onDeleteDone}
      />

      <SortModal type="course" data={data} setData={setData} isOpen={sortModalIsOpen} setIsOpen={setSortModalIsOpen} />

    </div>
  )
}

export default CourseList