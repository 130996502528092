import { CheckCircleOutline } from '@mui/icons-material'
import TranslateController from 'controller/shared/TranslateController'
import React from 'react'
import { Link } from 'react-router-dom'
import { Utils } from 'utils'

function UserCourseNextUp({ courseId, content }) {

  const { _t } = TranslateController()


  return (
    <div className="next-up">

      {content ?
        <>
          <h2 className='text-3-4 mb-0'>{_t('Next Up')}</h2>
          <div className="next-content">
            <div className={`icon `}>
              <CheckCircleOutline />
            </div>
            <div className="description">
              <Link to={`/course/${courseId}/lesson/${content?.lesson}/content/${content?.id}`}>
                <p className='mb-0'>{content && Utils.getDefaultName(content)}</p>
              </Link>
              <div className="info d-flex">
                <span className='mr-2'>{content?.type ? _t(content.type) : _t("Reading")} </span>
                <span aria-hidden="true" className='mr-2' > • </span>

                <div className='content-length'>{Math.round(content.duration/60)} {_t("min")}</div>
              </div>

            </div>
          </div>
        </>
        :
        <div className="next-up-buttons">
          <Link to={`/course/${courseId}/home/welcome/`} className="btn btn-primary btn-icon" onClick={() => { }}> {_t("Go To Course")} <i class={`fa fa-arrow-${ Utils.isRtl() ? "left" : "right" } `}></i></Link>
        </div>
      }
    </div>
  )
}

export default UserCourseNextUp