import React, { useState } from 'react'
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Utils } from 'utils'
import { PostHttpService } from 'view/cms/features/feature_blog/services';

function BlogStyle4({ title = "Featured posts" }) {

    const [posts, setData] = useState(null);

    const { isLoading, error, data, refetch } = useQuery('posts_aside_1', () => { return PostHttpService.loadPosts(null,null,5) }, {
        onSuccess: (data) => {
            setData(data.results)
        },
        // cacheTime: 60*60*24*30*1000// Cache results for 30 days
        refetchInterval: 60 * 100000,
    });
    return (
        <div>
            {title && (<h3 className="font-weight-bold text-3 pt-1">{title}</h3>)}

            <div className="pb-2">
                {posts && posts.map(post => (
                    <div key={"post_st4_"+post.id} className="mb-2 ">
                        <article className="thumb-info thumb-info-no-zoom bg-transparent border-radius-0 pb-2 mb-2">
                            <div className="row">
                                <div className="col mb-2">
                                    <Link to={`/post/${post.id}/${post.slug}`}>
                                        <img src={post.image}  className="img-fluid border-radius-0" alt={post.title} />
                                    </Link>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="thumb-info-caption-text">
                                        <h4 className="d-block line-height-2 text-4 text-dark font-weight-bold mb-0">
                                            <Link to={`/post/${post.id}/${post.slug}`} className="text-decoration-none text-color-dark text-color-hover-primary">{post.title}</Link>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                )
                )}

            </div>
        </div>
    )
}

export default BlogStyle4