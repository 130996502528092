import React, { useEffect, useState } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js';
import { CourseHttpService } from 'services/course';
import { useQuery } from 'react-query';
import { HttpService } from 'services/http';

// Register the necessary Chart.js components for Pie chart
ChartJS.register(ArcElement, Tooltip, Legend);

const GenderStatisticsChart = () => {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [],
    });

    const { isLoading, error, data, refetch } = useQuery('statistics_gender', () => {
        return CourseHttpService.loadGenderStatistics();
    }, {
        onSuccess: (data) => {
            const genderLabels = data.map((item) => item.gender);
            const userCounts = data.map((item) => item.user_count);

            setChartData({
                labels: genderLabels,
                datasets: [
                    {
                        label: 'User Count by Gender',
                        data: userCounts,
                        backgroundColor: [
                            'rgba(54, 162, 235, 0.6)',  // Male
                            'rgba(255, 99, 132, 0.6)',  // Female
                            'rgba(255, 206, 86, 0.6)',  // Other
                        ],
                        borderColor: [
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 99, 132, 1)',
                            'rgba(255, 206, 86, 1)',
                        ],
                        borderWidth: 1,
                    },
                ],
            });
        },
        refetchInterval: HttpService.DefaultRefetchInterval,
    });

    return (
        <div className="">
            <Bar
                data={chartData}
                options={{
                    responsive: true,
                    plugins: {
                        legend: {
                            display: false,
                            position: 'bottom',
                            labels: {
                                padding: 30,
                            },
                        },
                    },
                }}
            />
        </div>
    );
};

export default GenderStatisticsChart;
