import React, { useEffect, useState } from 'react'
import { Utils } from 'utils';
import CustomModal from 'view/components/modals/CustomModal';
import { DragHandle } from '@mui/icons-material';
import DeleteDialog from 'view/cms/components/shared/DeleteDialog';
import SortModal from 'view/cms/components/Widgets/shared/sort/SortModal';
import NotationPageForm from './NotationPageForm';
import { useParams } from 'react-router-dom';
import TranslateController from 'controller/shared/TranslateController';
import { CourseHttpService } from 'services/course';
import NotationPageSquares from './NotationPageSquares';

function NotationPages({ notationId , currentTime,goToAndPlay,handlePause}) {

    const [data, setData] = useState(null);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    
    const [selectedId, setSelectedId] = useState(0);
    const { id } = useParams();
    const [refresh, setRefresh] = useState(0);
    const { _t } = TranslateController()

    useEffect(() => {

        _getData()

        return () => {
        }
    }, [])

    const _getData = () => {
        setLoading(true);
        CourseHttpService.loadNotePages(notationId).then((items) => {
            if (items.results) {
                setData(items.results)
            }
        });
    }


    const _handleDelete = (e, id) => {
        setSelectedId(id);
        setRefresh(refresh + 1)
        setDeleteDialogIsOpen(true)
    }

    const _onDeleteDone = () => {
        var temp = data.filter(item => item.id !== selectedId)
        setData(temp)
        setDeleteDialogIsOpen(false);
        setRefresh(refresh + 1)
    }

    const _edit = (e, item) => {
        e.preventDefault();
        setSelectedId(item.id);
        setEditModalIsOpen(true)
        setRefresh(refresh + 1)
    }

    return (
        <div className='box mt-5'>
            <div className="box-header mb-5">
                <h5>{_t('Pages')}</h5>
                <div className="box-header-btns">
                    {/* <button className="btn btn-icon" title="Sort contents" onClick={() => { setSelectedId(null); setSortModalIsOpen(true) }}><DragHandle /> </button> */}
                    <button className="btn btn-icon" onClick={() => { setSelectedId(null); setEditModalIsOpen(true) }}><i className="fa fa-plus"></i></button>
                </div>
            </div>
            <div className="box-body">
                <div >
                    <div className="accordion accordion-modern" id="contentsaccordion">
                        {data?.map((item,index) => (
                            <div className="card card-default" key={`ccontent${item.id}`}>
                                <div className="card-header">
                                    <h4 className="card-title m-0">
                                        <a className="accordion-toggle collapsed" data-bs-toggle="collapse" data-bs-target={`#contentcollapse${item.id}`} aria-controls={`#contentcollapse${item.id}`} aria-expanded="false">
                                            <div>
                                                <span className="sort_order">{item.time/1000}</span>
                                                {/* <i className="fas fa-check"></i>  */}
                                                Page {index+1}
                                                
                                            </div>
                                            <div className="accordion-btns">
                                                
                                                <button className="btn btn-accordion" onClick={(e) => { e.preventDefault(); _edit(e, item) }}>
                                                    <i className="fas fa-edit" ></i>
                                                </button>
                                                <button className="btn btn-accordion" onClick={(e) => { e.preventDefault(); _handleDelete(e, item.id) }}>
                                                    <i className="fas fa-trash" ></i>
                                                </button>
                                            </div>
                                        </a>
                                    </h4>
                                </div>
                                <div id={`contentcollapse${item.id}`} className="collapse">
                                    <div className="card-body">
                                        <NotationPageSquares notePage={item} handlePause={handlePause} goToAndPlay={goToAndPlay} currentTime={currentTime} />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>


            {/* <SortModal type="notepage" data={data} setData={setData} isOpen={sortModalIsOpen} setIsOpen={setSortModalIsOpen} /> */}


            {editModalIsOpen && (
                <CustomModal className="" big key={editModalIsOpen} setOpen={(value) => setEditModalIsOpen(value)}
                    open={editModalIsOpen} >

                    <NotationPageForm key={`notepf${selectedId}`} notationId={notationId} id={selectedId}
                        onEditDone={(content) => {
                            let index = data.findIndex(item => item.id === content.id);
                            if (index > -1) {
                                data[index].names = content.names;
                                data[index].url = content.url;
                                data[index].time = content.time;
                            }
                            else {
                                data.push(content);
                            }
                            setData(data)
                        }}
                    />

                </CustomModal>
            )}
            
            {deleteDialogIsOpen && (
                <DeleteDialog
                    url={`/academy/course/notation/notepage/${selectedId}`}
                    isOpen={deleteDialogIsOpen}
                    key={"mdl" + deleteDialogIsOpen + selectedId}
                    setOpen={setDeleteDialogIsOpen}
                    notifyDone={_onDeleteDone}
                />
            )}




        </div>
    )

   


    
}

export default NotationPages