import {  FormControlLabel, Radio } from '@mui/material';
import React, {  useState } from 'react';
import { Utils } from 'utils';

function QuizQuestionChoices({ question, ...props }) {

    const [selectedChoice, setSelectedChoice] = useState('');

    const handleChoiceChange = (value) => {
        setSelectedChoice(value);
        props?.onChange(value)
    };


    return (
        <div className='quiz-question-choices'>
            {question?.choices.map(choice => (
                <div className='quiz-choice lan-kfont' key={choice.id}>
                    <FormControlLabel
                        control={
                            <Radio
                                checked={selectedChoice === choice.id}
                                value={choice.id}
                                onChange={() => handleChoiceChange(choice.id)}
                            />
                        }
                        label={Utils.getDefaultName(choice)}
                    />
                </div>
            ))}
        </div>
    );
}

export default QuizQuestionChoices;