import React, { useEffect, useState } from 'react'
import { Utils } from 'utils'
import { useQuery } from 'react-query';
import { SettingsHttpService } from 'view/cms/features/feature_settings/services';
import PageLayout from 'view/components/shared/PageLayout'
import TranslateController from 'controller/shared/TranslateController';
import { useForm } from 'react-hook-form';

function ContactPage() {

	const { register, handleSubmit, setValue, watch, formState: { errors }, } = useForm();
	const [loading, setLoading] = useState(false);
	const [done, setDone] = useState(false);
	const [error, setError] = useState(false);
	const [refresh, setRefresh] = useState(0);

	const { _t } = TranslateController()

	useEffect(() => {
		Utils.initiateTheme()
	}, [])


	const saveChanges = (data) => {

		setError(false)
	
		var body = {
		  "name": data.name,
		  "email": data.email,
		  "subject": data.subject,
		  "message": data.message,
		}
	
	
		setDone(false)
		setLoading(true)
		SettingsHttpService.saveFaq(body).then(response => {
		  setLoading(false)
		  setDone(true)
		  setError(false)
		}).catch(err => {
		  setLoading(false)
		  // setError("Something went wrong")
		});
	  }
	
	 

	return (
		<PageLayout showbreadcrumbs={false} HeaderChild={
			<h1 className="text-color-light my-3 font-weight-bold text-10 text-sm-8 lan-kfont">{_t("Contact")}</h1>
		}>

			<div className="row py-4">
				<div className="col-lg-6">

					<h2 className="font-weight-bold text-8 mt-2 mb-0">Contact Us</h2>
					<p className="mb-4">Feel free to ask for details!</p>

					<form className="contact-form"  onSubmit={handleSubmit(saveChanges)} novalidate="novalidate">
						<div className="contact-form-success alert alert-success d-none mt-4">
							<strong>Success!</strong> Your message has been sent to us.
						</div>

						<div className="contact-form-error alert alert-danger d-none mt-4">
							<strong>Error!</strong> There was an error sending your message.
							<span className="mail-error-message text-1 d-block"></span>
						</div>

						<div className="row">
							<div className="form-group col-lg-6">
								<label className="form-label mb-1 text-2">Full Name</label>
								<input type="text" {...register("name")} value="" data-msg-required="Please enter your name." maxlength="100" className="form-control text-3 h-auto py-2" name="name" required="" />
							</div>
							<div className="form-group col-lg-6">
								<label className="form-label mb-1 text-2">Email Address</label>
								<input type="email" {...register("email")} value="" data-msg-required="Please enter your email address." data-msg-email="Please enter a valid email address." maxlength="100" className="form-control text-3 h-auto py-2" name="email" required="" />
							</div>
						</div>
						<div className="row">
							<div className="form-group col">
								<label className="form-label mb-1 text-2">Subject</label>
								<input type="text" value="" {...register("subject")} data-msg-required="Please enter the subject." maxlength="100" className="form-control text-3 h-auto py-2" name="subject" required="" />
							</div>
						</div>
						<div className="row">
							<div className="form-group col">
								<label className="form-label mb-1 text-2">Message</label>
								<textarea maxlength="5000" {...register("message")} data-msg-required="Please enter your message." rows="8" className="form-control text-3 h-auto py-2" name="message" required=""></textarea>
							</div>
						</div>
						<div className="row">
							<div className="form-group col">
								<input type="submit" onClick={handleSubmit} value="Send Message" className="btn btn-primary btn-modern" data-loading-text="Loading..." />
							</div>
						</div>
					</form>

				</div>
				<div className="col-lg-6">

					<div className="appear-animation animated fadeIn appear-animation-visible" data-appear-animation="fadeIn" data-appear-animation-delay="800" >
						<h4 className="mt-2 mb-1">Our <strong>Office</strong></h4>
						<ul className="list list-icons list-icons-style-2 mt-2">
							<li><i className="fas fa-map-marker-alt top-6"></i> <strong className="text-dark">Address:</strong> 1234 Street Name, City Name, United States</li>
							<li><i className="fas fa-phone top-6"></i> <strong className="text-dark">Phone:</strong> (123) 456-789</li>
							<li><i className="fas fa-envelope top-6"></i> <strong className="text-dark">Email:</strong> <a href="mailto:mail@example.com">mail@example.com</a></li>
						</ul>
					</div>

					{/* <div className="appear-animation animated fadeIn appear-animation-visible" data-appear-animation="fadeIn" data-appear-animation-delay="950" >
								<h4 className="pt-5">Business <strong>Hours</strong></h4>
								<ul className="list list-icons list-dark mt-2">
									<li><i className="far fa-clock top-6"></i> Monday - Friday - 9am to 5pm</li>
									<li><i className="far fa-clock top-6"></i> Saturday - 9am to 2pm</li>
									<li><i className="far fa-clock top-6"></i> Sunday - Closed</li>
								</ul>
							</div> */}

					<h4 className="pt-5">Get in <strong>Touch</strong></h4>
					<p className="lead mb-0 text-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur eget leo at velit imperdiet varius. In eu ipsum vitae velit congue iaculis vitae at risus. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>

				</div>

			</div>

		</PageLayout>


	)
}

export default ContactPage