import React from "react";
import { Navigate } from "react-router-dom";
import { HttpService } from "services/http";

const PrivateRoute = ({ children }) => {
    const localStorageToken = HttpService.getToken()


return localStorageToken ? children : <Navigate to="/login" replace />;
};

export default PrivateRoute;