import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { CourseHttpService } from '../../../services/course'
import { useState } from 'react'
import TranslateController from 'controller/shared/TranslateController';
import { Utils } from 'utils';

function QuizViewQuestionsController({quiz , props}) {
  const [questions, setQuestions] = useState(null);
    const [selectedChoices, setSelectedChoices] = useState({});
    const [termsChecked, setTermsChecked] = useState(false);
    const [name, setName] = useState('');
    const [error, setError] = useState(null);
    const [done, setDone] = useState(false);
    const [loading, setLoading] = useState(false);
    const [grade, setGrade] = useState(false);

    const { _t } = TranslateController()

   useQuery(['quiz_questions', quiz?.id], () => CourseHttpService.loadQuizQuestions(quiz?.id), {
        onSuccess: (quiz_questions) => {
            if (quiz_questions.results) {
                setQuestions(quiz_questions.results)
            }
        }
    });

    const handleChoiceChange = (questionId, choiceId) => {

        setSelectedChoices(prevChoices => ({
            ...prevChoices,
            [questionId]: choiceId,
        }));
    };

    const handleTermsChecked = (event) => {
        setTermsChecked(event.target.checked);
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const saveChanges = useMutation(() => {
        setError(false);
        setDone(false);
        const choices = Object.entries(selectedChoices).map(([questionId, choiceId]) => ({ questionId, choiceId }));
        if (choices.length !== questions.length) {
            setError(_t('Please answer all the questions'));
            return;
        }

        // if (!termsChecked) {
        //     setError(_t('Please agree to the terms and conditions'));
        //     return;
        // }

        // if (name.trim() === '') {
        //     setError(_t('Please enter your legal name'));
        //     return;
        // }

        setLoading(true)
        return CourseHttpService.saveQuizChoices(choices)
            .then(response => {
                Utils.scrollTo(0);
                setDone(1)
                if(response.id){
                  setGrade(response.grade)
                  if(props.onScoreChange){
                    props.onScoreChange(response.grade)
                  }
                }
                setLoading(false)

            })
            .catch(error => {
                console.error('Error occurred while saving:', error);
            });
    });

  return { questions,loading,error,done ,selectedChoices,termsChecked, name,handleChoiceChange,
    handleTermsChecked,handleNameChange,saveChanges,_t,setDone , grade}
}

export default QuizViewQuestionsController