import TranslateController from 'controller/shared/TranslateController';
import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Utils } from 'utils';
import CustomModal from 'view/components/modals/CustomModal';
import EnrollmentWidget from '../enrollment/EnrollmentWidget';
import { HttpService } from 'services/http';
import LoginModal from 'view/components/user/LoginModal';
import { CourseHttpService } from 'services/course';

function PodcastSummary({ podcast, playingEpisode, handlePlayPause, isPlaying }) {

    const [enrollment, setEnrollment] = useState(null);
    const [enrollmentModalIsOpen, setEnrollmentModalIsOpen] = useState(false);
    const [loginModalIsOpen, setLoginModalIsOpen] = useState(false);

    var user = HttpService.getUser()

    const { _t } = TranslateController()

    const navigate = useNavigate()

    useEffect(() => {

        return () => {

        }
    }, [podcast])



    return (
        <section className="course-summary section section-concept section-no-border section-angled section-angled-reverse pt-5 m-0" >
            {/* <div className="section-angled-layer-bottom section-angled-layer-increase-angle bg-light"></div> */}
            <div className="container ">
                <div className="row pt-3 pb-lg-0 pb-xl-0 lan-rtl">
                    <div className="col-lg-12 pt-4  mb-lg-0 text-left lan-text-right ">
                        <ul className="breadcrumb font-weight-semibold text-4 lan-kfont negative-ls-1 hide-on-small">
                            <li className=''><a href="/explore">{_t("Browse")}</a></li>
                            <li className="text-color-primary"><a href="/podcasts/">{_t("Podcasts")}</a></li>
                            {/* <li className="active text-gray">Piano</li> */}
                        </ul>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="d-flex">
                                    <div className="text-left lan-text-right">

                                        <h1 className="font-weight-bold text-8  text-sm-8  line-height-2 mb-3 full-width lan-text-right lan-rtl  ">{podcast && Utils.getDefaultName(podcast)}</h1>
                                        {/* <p className='text-2 font-weight-light level-intro mb-3'>{_t("Level") + " : "}{podcast && Utils.getDefaultName(podcast?.level)}</p> */}
                                        {/* <PodcastInstructor podcast={podcast} /> */}
                                        {/* <div className="font-weight-light pb-2 mb-2 text-light  lan-kfont">{podcast && 15} episodes, 25 minutes</div> */}
                                        <div className="font-weight-light opacity-8 text-light text-justify lan-kfont">{podcast && Utils.parseShortDescription(podcast, 100)}</div>
                                        <div className='mb-3 podcast-summary-play-button'>
                                            <button className="btn color-primary pl-0 hide-on-small" onClick={handlePlayPause}>
                                                {isPlaying ? <i className="fa fa-pause-circle "></i> : <i className="fa fa-play-circle color-primary"></i>}
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-6 podcast-summary-image">
                                <img src={playingEpisode ? playingEpisode.image : podcast?.image} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </section>
    )


}

function PodcastInstructor({ podcast }) {

    const { _t } = TranslateController();

    var instructor = null;
    if (podcast && podcast?.instructors.length > 0) {
        instructor = podcast.instructors[0];
    }

    return (
        <>

            {instructor && (<div className="font-weight-light pb-2 mb-2 text-light  lan-kfont">
                {_t("Narrative") + ":"} &nbsp;
                <a href={`/instructor/${instructor.id}`} className='text-light'>{Utils.getDefaultName(instructor)}</a>
            </div>)}
        </>
    )
}

export default PodcastSummary