import React, { useState } from 'react'
import { useQuery } from 'react-query';
import { CourseHttpService } from 'services/course';
import FilterByWidget from './FilterByWidget';
import FilterByCategoriesWidget from './FilterByCategoriesWidget';
import FilterByLevelsWidget from './FilterByLevelWidget';


function SearchAside({ filterOptions, setFilterOptions }) {

    const handleCategorySelect = (ids) => {
        setFilterOptions(prevOptions => ({
            ...prevOptions,
            categories: ids,
        }));
    };

    const handleLevelSelect = (ids) => {
        setFilterOptions(prevOptions => ({
            ...prevOptions,
            levels: ids,
        }));
    };



    return (
        <div className='mt-4 lan-rtl'>
            {/* <h3>Filter By</h3> */}
            <div className='video-list nice-scroll'>
                <FilterByCategoriesWidget onSelect={handleCategorySelect}/>
                <FilterByLevelsWidget  onSelect={handleLevelSelect} />
                {/* <FilterByWidget title="Time period" data={["Less Than 2 Hours","1-4 Weeks","1-3 Months","3-6 Months"]} /> */}
                {/* <FilterByWidget title="Language" data={["Kurdi"]} /> */}
                
            </div>
        </div>

    )
}

export default SearchAside