import React from 'react'
import TranslateController from 'controller/shared/TranslateController';

function QuizGradeSummary({ grade }) {

    const { _t } = TranslateController()

    return (
        <div className='quiz-grade-summary'>
            <div className="info">
                <h3>{_t("Receive grade")}</h3>
                <b>{_t("To Pass")}</b> {_t("80% or higher")}
            </div>
            <div className="grade">
            <h3>{_t("Your grade")}</h3>
            {grade ?? '-'}
            </div>
        </div>

    )
}

export default QuizGradeSummary