import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Utils } from 'utils'

function PodcastListTable({ data, handleDelete, showDelete }) {

  var navigate = useNavigate()

  return (
    <div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th style={{ width: 30 }}>#</th>
            <th style={{ width: 400 }}>Podcast</th>
            <th >Writer</th>
            <th >Narrator</th>
            <th >Episodes</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data?.map(item => (
            <tr key={item.id}>
              <td>
                <Link to={`/cms/podcast/${item.id}`} className="">
                  {item.id}
                </Link>
              </td>
              <td>
                <Link to={`/cms/podcast/${item.id}`} className="">
                  {Utils.getDefaultName(item)}
                </Link>
              </td>
              <td>
                  {item.writers}
              </td>
              <td>
                {item.narrators}
              </td>
              <td>
                
              </td>
              <td>
                {showDelete && (
                  <button className="btn" onClick={() => handleDelete(item.id)}>
                    <i className="fa fa-trash"></i>
                  </button>
                )}

              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default PodcastListTable