import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import TranslateController from "controller/shared/TranslateController";
import React, { useEffect, useState } from "react";

const genders = [
  { value: 0, label: "Male" },
  { value: 1, label: "Female" },
  { value: 2, label: "Other" },
];

const SelectGender = ({onSelect , value}) => {
  const [gender, setGender] = useState(value);

  useEffect(() => {
    setGender(value);
  }, [value]);

  const handleSelect = (event) => {
    const selectedValue = event.target.value;
    setGender(selectedValue);
    onSelect(selectedValue);
  };

  const { _t } = TranslateController()



  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Gender</InputLabel>
      <Select
        label={_t("Gender")}
        fullWidth
        className="full-width"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        value={gender}
        onChange={handleSelect}
      >
        {genders.map((gender) => (
          <MenuItem key={gender.value} value={gender.value}>
            {gender.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectGender;