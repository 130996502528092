import React, { useState } from 'react'
import UserSearchList from 'features/feature_search/hooks/UserSearchList';
import CourseItem from './CourseItem';
import CourseCard from 'view/components/course/components/CourseCard';

function SearchList({ filterOptions }) {

	const [query, setQuery] = useState("")
    const { data, isLoading, isError, hasNextPage, fetchNextPage, refetch } = UserSearchList(query,filterOptions);

    return (
        <div className='mt-4'>
            <form action="" onSubmit={(e) => {  e.preventDefault(); refetch() }}>
                <div className="search-container">
                    <div className="input-group mt-3 mb-4">
                        <input className="form-control text-1 " value={query} name="q" onChange={(e) => { setQuery(e.target.value) }} type="search" placeholder="Search..." />
                        <button className="btn" type="submit" aria-label="Search">
                            <i className="fas fa-search header-nav-top-icon"></i>
                        </button>
                    </div>
                </div>
            </form>
            {/* <h3>{data?.count ?? 0} results</h3> */}

            <div className="row py-3 ">
                {data?.results?.map((course, i) => (
                    <div className="col-sm-8 col-md-6 mb-4 mb-md-0 " key={course.id}>
                        <CourseCard course={course} showDescription={false} />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default SearchList