import React, { useContext, useState } from 'react'
import { useQuery } from 'react-query';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { CourseHttpService } from '../../../../services/course';
import LessonQuizes from './LessonQuizes';
import { useParams } from 'react-router-dom';
import ContentsLightList from '../content/ContentsLightList';
import { Utils } from 'utils';
import CustomAccordion from 'view/components/shared/CustomAccordion';
import TranslateController from 'controller/shared/TranslateController';

function LessonsLightAccordion({ lecture, course, isOpen,lecture_index }) {

    const { content } = useParams();
    const { isLoading, error, data } = useQuery(['lessons_by_lecture', lecture], () => { return CourseHttpService.loadLessons(lecture,content) }, {
        refetchInterval: 3000, // Refetch every 15 seconds (15,000 milliseconds)
    });

    return (
            <div className=" without-bg border-bottom" >
                {data?.results.map((item, index) => (
                    <LessonAccordion lecture_index={lecture_index}  lesson={item} open={item?.is_completed ? 0 : 1 /** index === 0 && isOpen */} course={course} key={"lessac" + item.id+item?.is_completed} />
                ))}
            </div>
    )
}

function LessonAccordion({ lesson, course, open = false, lecture_index }) {

    const [isOpen, setIsOpen] = useState(open);

    const {_t} = TranslateController();


    return (
        <CustomAccordion open={open} key={`clesson${lesson.id}`} title={`${lecture_index+1} - `+Utils.getDefaultName(lesson)}>
                <ContentsLightList lecture_index={lecture_index} lesson={lesson.id} course={course}  />
                <LessonQuizes lesson={lesson.id} isLight={1}  course={course} />
        </CustomAccordion>

    )
}

export default LessonsLightAccordion