import React, { useContext } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link, useParams } from "react-router-dom";
import { LanguageContext } from '../../../../../contexts/LanguageContext';
import { CourseHttpService } from '../../../../../services/course';
import { translate, Utils } from '../../../../../utils';
import CustomModal from '../../../../components/modals/CustomModal';
import DeleteDialog from '../../shared/DeleteDialog';

function CourseReviews({ course }) {

    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = React.useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = React.useState(false);

    const [selectedId, setSelectedId] = React.useState(0);
    const { id } = useParams();
    const [refresh, setRefresh] = React.useState(0);

    const { translations } = useContext(LanguageContext);
    const _t = (key) => {
        return translate(key, translations)
    }


    const _handleDelete = (e, id) => {
        setSelectedId(id);
        setRefresh(refresh + 1)
        setDeleteDialogIsOpen(true)
        CourseHttpService.SetInstructorToCourse(course.id,id,"remove")
    }

    const _onDeleteDone = () => {
        course.instructors = course.instructors.filter(item => item.id !== selectedId)
        setDeleteDialogIsOpen(false)
        setRefresh(refresh + 1)
    }

    const _addInstructorToCourse = (item) => {
        if(course?.instructors){
           course.instructors.push(item)
           CourseHttpService.SetInstructorToCourse(course.id,item.id)
        }
        else{
            course.instructors = [item]
        }
        setEditModalIsOpen(false)
        setRefresh(refresh + 1)

    }



    return (
        <div className='box mt-5 mb-5'>
            <div className="box-header mb-5">
                <h5>Reviews</h5>
                <div className="btns">
                </div>
            </div>
            <div className="box-body">
                <div className='' >
                    {course?.instructors?.map(item => (
                        <div key={item.id + "cmp"} className=''>
                            
                        </div>
                    )
                    )}
                </div>
            </div>

            {editModalIsOpen && (
                <CustomModal big className="" key={editModalIsOpen} setOpen={(value) => setEditModalIsOpen(value)}
                    open={editModalIsOpen} >

                </CustomModal>
            )}

            {deleteDialogIsOpen && (
                <DeleteDialog
                    url={`/academy/course/week/${selectedId}`}
                    isOpen={deleteDialogIsOpen}
                    key={"mdl" + deleteDialogIsOpen + selectedId}
                    setOpen={setDeleteDialogIsOpen}
                    notifyDone={_onDeleteDone}
                />
            )}
        </div>
    )
}

export default CourseReviews