import React, { useState } from 'react'
import CMSLayout from 'view/cms/components/shared/CMSLayout'
import { SettingsHttpService } from '../services';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Utils } from 'utils';
import DeleteDialog from 'view/cms/components/shared/DeleteDialog';
import FaqForm from '../components/FaqForm';
import CustomModal from 'view/components/modals/CustomModal';
import NamesWidgets from 'view/cms/components/forms/shared/NamesWidgets';
import Done from 'view/cms/components/shared/Done';
import Error from 'view/cms/components/shared/Error';
import LoadingInside from 'view/cms/components/shared/LoadingInside';
import { Button, TextField } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import ImageFieldWithGallery from 'view/cms/components/Widgets/shared/ImageFieldWithGallery';

function GeneralSettings() {
    const { register, handleSubmit, setValue, watch, formState: { errors }, } = useForm();

    const [aboutNames, setAboutNames] = useState([]);
    const [loading, setLoading] = useState(false);
    const [done, setDone] = useState(false);
    const [error, setError] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [item_image, setImage] = useState(null);

    const { isLoading, error: queryError, data, refetch } = useQuery('settings', () => { return SettingsHttpService.loadSettings(1) }, {
        onSuccess: (data) => {
            onEditing(data)
        },
        // cacheTime: 60*60*24*30*1000// Cache results for 30 days
        refetchInterval: 60 * 10000,
    });


    const onEditing = (item) => {
        for (const key in item) {
            setValue(key, item[key])
        }

        setAboutNames(item.names);
        setImage(item.image)

        setRefresh(refresh + 1)
    }

    const saveChanges = (data) => {

        setError(false)

        var body = {
            "id": 1,
            "title": data.title,
            "names": aboutNames,
            "image": item_image,
            "facebook": data.facebook,
            "twitter": data.twitter,
            "instagram": data.instagram,
            "youtube": data.youtube,
            "spotify": data.spotify,
            "linkedin": data.linkedin,
            "meta_description": data.meta_description,
            "meta_keywords": data.meta_keywords,

        }


        setDone(false)
        setLoading(true)
        SettingsHttpService.saveSettings(body).then(response => {
            setLoading(false)
            setDone(true)
            setError(false)
        }).catch(err => {
            setLoading(false)
            // setError("Something went wrong")
        });
    };


    return (
        <CMSLayout>
            <div className='box'>
                <div className="box-header mb-5">
                    <h5>General Settings</h5>
                    <div className="btns d-flex">
                    </div>
                </div>
                <div className="box-body ">
                    <form className="mt-4" onSubmit={handleSubmit(saveChanges)}>

                        <div className="row">
                            <div className="col-md-8">
                                <h5>About</h5>
                                <NamesWidgets  show_short_description={1} names={aboutNames} setNames={(names) => { setAboutNames(names) }} />
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <ImageFieldWithGallery className="about-image" key={item_image + "itimg"} defaultImageUrl={item_image} onImageSelected={setImage} />
                                </div>
                                <div className="form-group">
                                    <TextField
                                        fullWidth
                                        label="Site Title"
                                        value={watch('title') ?? ""}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("title")}
                                    />
                                </div>
                                <div className="form-group">
                                    <TextField
                                        fullWidth
                                        label="Meta Keywords"
                                        value={watch('meta_keywords') ?? ""}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("meta_keywords")}
                                    />
                                </div>
                                <div className="form-group">
                                    <TextField
                                        fullWidth
                                        label="Meta Description"
                                        value={watch('meta_description') ?? ""}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("meta_description")}
                                    />
                                </div>
                                <hr />
                                <div className="form-group">
                                    <TextField
                                        fullWidth
                                        label="Facebook"
                                        value={watch('facebook') ?? ""}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("facebook")}
                                    />
                                </div>
                                <div className="form-group">
                                    <TextField
                                        fullWidth
                                        label="Youtube"
                                        value={watch('youtube') ?? ""}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("youtube")}
                                    />
                                </div>

                                <div className="form-group">
                                    <TextField
                                        fullWidth
                                        label="Twitter"
                                        value={watch('twitter') ?? ""}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("twitter")}
                                    />
                                </div>

                                <div className="form-group">
                                    <TextField
                                        fullWidth
                                        label="Instagram"
                                        value={watch('instagram') ?? ""}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("instagram")}
                                    />
                                </div>

                                <div className="form-group">
                                    <TextField
                                        fullWidth
                                        label="LinkedIn"
                                        value={watch('linkedin') ?? ""}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("linkedin")}
                                    />
                                </div>

                                <div className="form-group">
                                    <TextField
                                        fullWidth
                                        label="Spotify"
                                        value={watch('spotify') ?? ""}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("spotify")}
                                    />
                                </div>

                                <hr />
                                <div className="form-group mt-3 text-right mb-1">
                                    <Button
                                        onClick={handleSubmit}
                                        type="submit"
                                        variant="contained"
                                        startIcon={<Edit />}
                                    >
                                        {"Save Changes"}
                                    </Button>


                                </div>
                                <div className="form-group">
                                    {done && <Done />}
                                    {error && <Error message={error} />}
                                    {loading && <LoadingInside />}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </CMSLayout>
    )
}

export default GeneralSettings