import React, { useContext, useEffect, useState } from 'react'
import Modal from 'react-modal';
import EmailLoginModal from './EmailLoginModal';
import SocialLogin from './SocialLogin';
import { translate } from 'utils'
import validator from "validator";
import { HttpService } from 'services/http';
import { UserHttpService } from 'services/user';


export default function LoginModal({ isOpen,setOpen, setLoggedIn,onClose,nextLocation="" }) {

    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [emailLoginModalIsOpen, setEmailLoginModalIsOpen] = useState(false);

    useEffect(() => {
        setModalIsOpen(isOpen)
    }, [])

    const closeModal = async () =>  {
        setModalIsOpen(false)
        // setTimeout(function(){}, 5050);
        setOpen && setOpen(false)
        onClose && onClose()
    }


    return (
        <>
            <Modal
                closeTimeoutMS={500}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="Modal modal-social-login "
                ariaHideApp={false}
                overlayClassName="Overlay"
            >
                <div className="modal-content"> 
                    
                    <div className="modal-body p-6">
                        <div className="btn btn-close" onClick={closeModal}><i className="fa fa-times"></i></div>
                        <SocialLogin setLoggedIn={setLoggedIn} goToEmailRegister={()=>{ setEmailLoginModalIsOpen(true) }} />
                    </div>
                    
                </div>
            </Modal>
            <EmailLoginModal key={emailLoginModalIsOpen} setOpen={setEmailLoginModalIsOpen} setLoggedIn={setLoggedIn} isOpen={emailLoginModalIsOpen} />
            
        </>
    )
}


// function HomeLoginForm({ translations, setLoginModalIsOpen }) {

// 	const _t = (key) => {
// 		return translate(key, translations)
		
// 	}

//     const useFormInput = (initialValue) => {
//       const [value, setValue] = useState(initialValue);
  
//       const handleChange = (e) => {
//         setValue(e.target.value);
//       };
//       return {
//         value,
//         onChange: handleChange,
//       };
//     };
  
  
  
//     const username = useFormInput("");
//     const password = useFormInput("");
//     const [error, setError] = useState(null);
//     const [loading, setLoading] = useState(false);
  
//     const handleLogin = (e) => {
//       e.preventDefault();
     
//       setError(null);
//       setLoading(true);
  
//       if (!validator.isEmail(username.value)) {
//         setError(_t("Email address is not valid"));
//         setLoading(false);
//         return;
//       }
  
//       if (!validator.isLength(password.value, { min: 6 })) {
//         setError(_t("Password is not valid"));
//         setLoading(false);
//         return;
//       }

//       var body = {
//         "email": username.value,
//         "username": username.value,
//         "password": password.value
//     }

//       UserHttpService.login(body).then(response => {
//         setLoading(false);
//         if (response.role) {
//             HttpService.setToken(response.tokens.access)
//             HttpService.setUser(response)

//             if (response.role == 'admin') {
//                 window.location.href = "/cms/dashboard"
//             }
//             else {
//                 window.location.href = "/"
//             }
//         }
//         else {
//             console.log(response)
//             if(response.detail) {
//                 setError(_t(response.detail));
//             }
//             else if(response.email){
//                 setError(_t(response.email));

//                 alert(response.email)
//             }
//             else{
//                 alert(response[0])
//             }

//         }
//     });
// }
  
//     return <div
//       className="d-flex flex-row align-items-center justify-content-center appear-animation animated fadeIn appear-animation-visible"
//       data-appear-animation="fadeIn"
//       data-appear-animation-delay="1s"
//       data-appear-animation-duration="3s"
//     >
//       <form
//         className="form-row slide-form form-fields-rounded "
//         onSubmit={handleLogin}
//       >
//         <div className="form-group col-lg-5">
//           <input
//             type="email"
//             {...username}
//             placeholder={_t("Email")}
//             maxLength="100"
//             className="form-control text-3 h-auto py-2 text"
//             name="username"
//             required=""
//           />
//         </div>
//         <div className="form-group col-lg-5 col-sm-7">
//           <input
//             type="password"
//             {...password}
//             placeholder={_t("Password")}
//             data-msg-email={_t("Please enter a valid email address")}
//             maxLength="100"
//             className="form-control text-3 h-auto py-2 text"
//             name="password"
//             required=""
//           />
//         </div>
//         <div className="form-group col-lg-2 col-sm-7">
//           <button
//             type="submit"
//             className="btn btn-dark  btn-block text-uppercase rounded-35 font-weight-bold text-3 "
//             data-loading-text="Loading..."
//           >
//             {_t("Login")}
//           </button>
//         </div>
//         {error && (
//           <div className="alert alert-danger text-2 ">{error}</div>
//         )}
//         {loading && (
//           <small className="loading">
//             {_t("Please Wait")}
//           </small>
//         )}
//         <div className="col-lg-12">
//           <a href="forgot" className="slide-link link-first ">
//             {_t("Forgot Password ?")}
//           </a>
//           {/* <a href="#signin" onClick={(e) => { e.preventDefault(); setLoginModalIsOpen(true); }} className="slide-link">
//             {translate("Sign Up")}
//           </a> */}
//         </div>
//       </form>
//     </div>
// }