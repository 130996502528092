import React, { useState } from 'react'
import Lessons from './Lessons';
import LecturesController from 'view/cms/controllers/course/lecture/LecturesController';
import { Utils } from 'utils';
import CustomModal from 'view/components/modals/CustomModal';
import LectureForm from '../../forms/course/LectureForm';
import DeleteDialog from '../../shared/DeleteDialog';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { DragHandle } from '@mui/icons-material';
import SortModal from '../shared/sort/SortModal';

function Lectures({ course }) {

    const { data, setData, setSelectedId, setEditModalIsOpen, _handleDelete, _edit, deleteDialogIsOpen, selectedId,
        setDeleteDialogIsOpen, _onDeleteDone, editModalIsOpen, _t, sortModalIsOpen, setSortModalIsOpen } = LecturesController(course)


    return (
        <div className='box mt-5'>
            <div className="box-header mb-5">
                <h5>{_t('Lectures')}</h5>
                <div className="box-header-btns">
                    <button className="btn btn-icon" title="Sort lectures" onClick={() => { setSelectedId(null); setSortModalIsOpen(true) }}><DragHandle /> </button>
                    <button className="btn btn-icon" onClick={() => { setSelectedId(null); setEditModalIsOpen(true) }}><i className="fa fa-plus"></i></button>
                </div>
            </div>
            <LecturesAccordion />

            <EditModal />
            <DeleteModal />
            <SortModal type="lecture" data={data} setData={setData} isOpen={sortModalIsOpen} setIsOpen={setSortModalIsOpen} />

        </div>
    )

    function DragDroppable({ items, onDragEnd }) {
        return (
            <DragDropContext onDragEnd={onDragEnd}>
                <div>
                    <Droppable droppableId="lecturesaccordion">
                        {(provided, snapshot) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {items.map((item, index) => (
                                    <Draggable key={item.id} draggableId={"lecture_draggable_" + item.id} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <AccordionItem item={item} />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </div>
            </DragDropContext>
        );
    }

    function LecturesAccordion() {
        const [items, setItems] = useState(data ?? []);

        const handleDragEnd = result => {
            if (!result.destination) return;

            const updatedItems = Array.from(items);
            const [removed] = updatedItems.splice(result.source.index, 1);
            updatedItems.splice(result.destination.index, 0, removed);

            setItems(updatedItems);
            // saveSortOrder(updatedItems.map(item => item.id),"week");
        };

        return (
            <div>
                <div className="accordion accordion-modern" id="lecturesaccordion">
                    {/* <DragDroppable items={items} onDragEnd={handleDragEnd} /> */}
                    {items.map((item, index) => (
                        <AccordionItem key={"ail"+index} item={item} />
                    ))}
                </div>
            </div>
        );
    }

    function AccordionItem({ item }) {
        return (
            <div className="card card-default mb-3" key={`clecture${item.id}`}>
                <div className="card-header">
                    <h4 className="card-title m-0">
                        <a className="accordion-toggle collapsed" data-bs-toggle="collapse"  data-bs-target={`#lecturecollapse${item.id}`} aria-controls={`#lecturecollapse${item.id}`}  aria-expanded="false">
                            <div>
                                <i className="fas fa-book"></i> {Utils.getDefaultName(item)}
                            </div>
                            <div className="accordion-btns">
                                <button className="btn btn-accordion" onClick={(e) => { e.preventDefault(); _edit(e, item) }}>
                                    <i className="fas fa-edit" ></i>
                                </button>
                                <button className="btn btn-accordion" onClick={(e) => { e.preventDefault(); _handleDelete(e, item.id) }}>
                                    <i className="fas fa-trash" ></i>
                                </button>
                            </div>
                        </a>
                    </h4>
                </div>
                <div id={`lecturecollapse${item.id}`} className="collapse">
                    <div className="card-body">
                        <Lessons lecture={item.id} />
                        {/* <Contents lecture={item.id} /> */}
                    </div>
                </div>
            </div>
        )
    }

    function DeleteModal() {
        if (!deleteDialogIsOpen) return;
        return (
            <DeleteDialog
                url={`/academy/course/lecture/${selectedId}`}
                isOpen={deleteDialogIsOpen}
                key={"mdl" + deleteDialogIsOpen + selectedId}
                setOpen={setDeleteDialogIsOpen}
                notifyDone={_onDeleteDone}
            />
        )
    }

    function EditModal() {
        if (!editModalIsOpen) return;
        return (
            <CustomModal className="" key={editModalIsOpen} setOpen={(value) => setEditModalIsOpen(value)}
                open={editModalIsOpen} >

                <LectureForm key={`gmd${selectedId}`} id={selectedId} course={course}
                    onEditDone={(lecture) => {
                        let index = data.findIndex(item => item.id === lecture.id);
                        if (index > -1) {
                            data[index].names = lecture.names;
                            data[index].week = lecture.week;
                        }
                        else {
                            data.push(lecture);
                        }
                        setData(data)
                    }}
                />
            </CustomModal>
        )
    }
}

export default Lectures