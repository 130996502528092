import { AccessTime, MenuBookOutlined } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { Utils } from '../../../../utils'
import TranslateController from 'controller/shared/TranslateController';
import LoginModal from 'view/components/user/LoginModal';
import CustomModal from 'view/components/modals/CustomModal';
import EnrollmentWidget from '../enrollment/EnrollmentWidget';
import { HttpService } from 'services/http';
import { Link, useNavigate } from 'react-router-dom';
import { CourseHttpService } from 'services/course';

function LectureSyllabus({ course, lecture, number }) {

    const { _t } = TranslateController()
    const [enrollment, setEnrollment] = useState(null);
    const [enrollmentModalIsOpen, setEnrollmentModalIsOpen] = useState(false);
    const [loginModalIsOpen, setLoginModalIsOpen] = useState(false);

    var user = HttpService.getUser()
    const navigate = useNavigate()


    useEffect(() => {
        if (course != null && course?.enrollment != null) {
            setEnrollment(course?.enrollment)
        }

        return () => {

        }
    }, [course])

    const handleEnrollment = (enrollment) => {
        setEnrollment(enrollment);
        setEnrollmentModalIsOpen(false);
        // Redirect to course page
        navigate(`/course/${course?.id}/lectures/`); // Example URL, adjust as needed
    };

    const handleLoggedIn = () => {
        setLoginModalIsOpen(false)

        if (course != null) {
            CourseHttpService.loadCourse(course.id).then(course => {
                if(course.enrollment){
                    setEnrollment(course?.enrollment)
                    navigate(`/course/${course?.id}/lectures/`);
                }
                else{
                    setEnrollmentModalIsOpen(true)
                }
            })
        }
    }

    var total_seconds = lecture.lecture_report?.Video?.total_duration ?? 0;
    var total_mins = lecture.lecture_report?.Video?.total_count ?? 0;
    if(total_seconds > 60) {
        total_mins = parseInt(total_seconds/60);
    }

    const handleClick = (e) => {
        e.preventDefault();
        if(enrollment){
            window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
            navigate(`/course/${course?.id}/lectures`)
        }
        else{
            if (!user) {
                setLoginModalIsOpen(true);
            } else setEnrollmentModalIsOpen(true)
        }
        
    }

    return (
        <div className='row week-syllabus mb-4'>
            <div className="col-md-3 syllabus-aside hide-on-small d-flex flex-center ">
                <p className="title opacity-3 lan-rtl">{_t("Lecture")} &nbsp; {number}</p>
            </div>
            <div className="col-md-9 syllabus-info">
                {/* <div className="hours">
                    <div className="circle-icon">
                        <AccessTime />
                    </div>
                    <p>7 hours to complete</p>
                </div> */}
                    <a href={`/course/${lecture.course}/lectures/`}
                    onClick={handleClick}>
                        <h3 className='mb-2 text-4'>{Utils.getDefaultName(lecture)} </h3>
                    </a>
                <div className="week-description">
                    {Utils.parseDescription(lecture)}
                </div>
                <div className="hours">
                    {/* <div className="circle-icon">
                        <MenuBookOutlined />
                    </div>
                    <p className='lan-rtl lan-kfont'>
                        {lecture.lecture_report?.Video?.total_count ?? 0} {_t("videos")} ({_t("Total")} {total_mins} {_t("min")})
                    </p> */}
                    {/* <a href={`/course/${lecture.course}/lecture/${lecture.id}`}>See All</a> */}
                       

                    <div className="lecture-information">
                        <div className='mr-3'>
                            <a href={`#`}
                            onClick={handleClick}
                            >
                                <img src="/assets/images/svg/play-circle.svg" width={28} alt="" />&nbsp;
                                {lecture.lecture_report.Video.total_count} <span className='hide-on-small'>{_t(`videos`)}</span>
                            </a>
                        </div>
                        {lecture.lecture_report.Reading.total_count > 0 && (
                            <div className='mr-3'>
                                <a href={`/course/${lecture.course}/lectures/`}
                                onClick={handleClick}>
                                    <img src="/assets/images/svg/book.svg" width={28} alt="" /> &nbsp;
                                    {lecture.lecture_report.Reading.total_count} <span className='hide-on-small'>{_t(`Reading`)}</span>
                                </a>
                            </div>
                        )}
                        {lecture.lecture_report.Quiz.total_count > 0 && (
                            <div className='mr-3'>
                                <a href={`/course/${lecture.course}/lectures/`}
                                onClick={handleClick}>
                                    <img src="/assets/images/svg/quiz.svg" width={28} alt="" />&nbsp;
                                    {lecture.lecture_report.Quiz.total_count} <span className='hide-on-small'>{_t(`Quizes`)}</span>
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <LoginModal key={loginModalIsOpen} setOpen={setLoginModalIsOpen} setLoggedIn={handleLoggedIn} isOpen={loginModalIsOpen} />
            <EnrollmentModal />
        </div>
    )


    function EnrollmentModal() {
        if (!enrollmentModalIsOpen) return;
        return (

            <CustomModal className="" key={enrollmentModalIsOpen} setOpen={(value) => setEnrollmentModalIsOpen(value)}
                open={enrollmentModalIsOpen} >

                <EnrollmentWidget course={course} onEnrollment={handleEnrollment} />


            </CustomModal>
        )
    }
}


export default LectureSyllabus