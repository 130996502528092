import { Button, FormControlLabel, Radio } from '@mui/material';
import { LanguageContext } from 'contexts/LanguageContext';
import React, { useContext, useState } from 'react';
import { useMutation } from 'react-query';
import { CourseHttpService } from 'services/course';
import { Utils, translate } from 'utils';

function VideoQuestionChoices({ question , ...props }) {

    const [selectedChoice, setSelectedChoice] = useState('');
    const [isCorrectAnswer, setIsCorrectAnswer] = useState('');
    const [error, setError] = useState('');

    const saveAnswerMutation = useMutation(() => {

        setError("");
        if(!selectedChoice){
            setError(_t('Please select an answer'));
            return;
        }

        // Add your logic here to save the selected choice using the `choiceId`
        // For example, you can make an API call to save the choice
        // saveAnswerMutation.isError = false;
        if (!selectedChoice) return;
        return CourseHttpService.saveContentChoice(question?.id, selectedChoice)
            .then(response => {
                // Update isCorrectAnswer based on the response
                console.log(response)

                setIsCorrectAnswer(response?.is_correct);
            })
            .catch(error => {
                console.error('Error occurred while saving:', error);
                //   throw error;
            });
    });

    const { translations } = useContext(LanguageContext);
    const _t = (key) => {
        return translate(key, translations)
    }


    const handleChoiceChange = (value) => {
        setSelectedChoice(value);
    };

    const _close = (e) => {
        if(props.onClose){
            props.onClose()
        }
    };

    



    return (
        <div className='video-question-choices'>
            {question?.choices.map(choice => (
                <div className='video-choice' key={choice.id}>
                    <FormControlLabel
                        control={
                            <Radio
                                checked={selectedChoice === choice.id}
                                value={choice.id}
                                onChange={() => handleChoiceChange(choice.id)}
                            />
                        }
                        label={Utils.getDefaultName(choice)}
                    />
                </div>
            ))}
            <Button className='kfont btn btn-primary btn-outline btn-outline-thin btn-border-blue mt-4' onClick={saveAnswerMutation.mutate}>{_t('Submit answer')}</Button>
            {isCorrectAnswer && <Button className='kfont btn btn-outline mt-4 text-light' onClick={_close}>{_t('Continue to video')}</Button> } 
            <div className="messages mt-4">
                {saveAnswerMutation.isLoading && <span>{_t('Saving...')}</span>}
                {saveAnswerMutation.isError && <span>{_t('Error occurred while saving.')}</span>}
                {error && <span>{error}</span>}
                {!error && saveAnswerMutation.isSuccess && (
                    <span className={isCorrectAnswer ? 'alert color-success  mb-2' : 'alert color-danger mb-2'}>{isCorrectAnswer ? _t('Correct answer.') : _t('Incorrect answer.')}</span>
                )}
            </div>
        </div>
    );
}

export default VideoQuestionChoices;