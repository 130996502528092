import React, { useContext, useState } from 'react'
import { useQuery } from 'react-query';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { CourseHttpService } from '../../../../services/course';
import { translate, Utils } from '../../../../utils';
import Contents from '../content/Contents';
import LessonQuizes from './LessonQuizes';
import CustomAccordion from 'view/components/shared/CustomAccordion';

function Lessons({ lecture, course }) {

    const { setLanguage, translations } = useContext(LanguageContext);
    const [week, setWeek] = useState(null);
    const _t = (key) => {
        return translate(key, translations)
    }

    const { isLoading, error, data } = useQuery(['lessons', lecture], () => { return CourseHttpService.loadLessons(lecture) }, {

    });

    return (
        <div>
            <div className="mt-2" id="lessonaccordion">
                {data?.results && data?.results.length == 1 && <LessonItem lesson={data?.results[0]} course={course} key={"lessonitem" + data?.results[0].id} />}
                {data?.results && data.results.length > 1 && data?.results.map((item, index) => (
                    <LessonAccordion lesson={item} open={1} course={course} key={"lessac" + item.id} />
                ))}
            </div>
        </div>
    )
}

function LessonItem({ lesson, course }) {

    return (

        <>
            <div className="lan-rtl">
                {Utils.parseDescription(lesson)}
            </div>
            <Contents lesson={lesson.id} className="pt-0 mt-0" course={course} />
            <LessonQuizes lesson={lesson.id} course={course} />
        </>

    )
}


function LessonAccordion({ lesson, course, open = false }) {

    let contentCount = lesson.content_count || 0 ;

    if(contentCount<2){
        return <>
            <div className="lan-rtl">
                {Utils.parseDescription(lesson)}
            </div>
            <Contents lesson={lesson.id} className="pt-0 mt-0" course={course} />
            <LessonQuizes lesson={lesson.id} course={course} />
        </>
    }


    return (

        <CustomAccordion open={open} title={Utils.getDefaultName(lesson)}>
            <div className="lan-rtl">
                {Utils.parseDescription(lesson)}
            </div>
            <Contents lesson={lesson.id} className="pt-0 mt-0" course={course} />
            <LessonQuizes lesson={lesson.id} course={course} />
        </CustomAccordion>

    )
}

export default Lessons