import React, { useEffect, useState } from 'react'
import Gallery from './Gallery'
import { TextField } from '@mui/material';

function MusicFileFieldWithGallery({ onDocumentSelected, defaultUrl = null, className = "" }) {

  const [gallery_modal_is_open, setGalleryModalIsOpen] = useState(false);
  const [music_url, setMusicUrl] = useState(defaultUrl);


  useEffect(() => {
    setMusicUrl(music_url)
  }, [defaultUrl])

  const openGallery = () => {

    setGalleryModalIsOpen(true)

  }

  const closeGallery = () => {
    setGalleryModalIsOpen(false)
  }

  const setSelectedDocument = (gallery_item) => {
    setMusicUrl(gallery_item?.document)
    onDocumentSelected(gallery_item?.document)
  }

  return (
    <>
      <Gallery
        type={"music"}
        setSelectedGalleryItem={setSelectedDocument}
        isOpen={gallery_modal_is_open}
        key={gallery_modal_is_open}
        closeGallery={() => {
          closeGallery();
        }}
      />
      <div className="form-group d-flex">
        <TextField
          fullWidth
          label="Music Url"
          value={music_url ?? ""}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          onChange={(event) => { 
            const value = event.target.value; 
            setMusicUrl(value); 
            onDocumentSelected(value); 
          }}
        />
        <button type="button" className="btn btn-primary ml-2" onClick={() => { setGalleryModalIsOpen(true) }}><i className="fa fa-upload"></i></button>

      </div>
    </>
  )
}

export default MusicFileFieldWithGallery