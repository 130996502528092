import TranslateController from 'controller/shared/TranslateController';
import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { CourseHttpService } from 'services/course';
import { Utils } from 'utils';

function FilterByCategoriesWidget({ onSelect }) {
  const [categories, setCategories] = useState(null);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);

  const { _t } = TranslateController();
  const { isLoading, error, data, refetch } = useQuery('course_categories', () => {
    return CourseHttpService.loadCourseCategories();
  }, {
    onSuccess: (data) => {
      if(data.results){
        setCategories(data.results);
      }
      // setCategories(Utils.BuildCategories(data));
    },
    refetchInterval: 60 * 10000,
  });


  const handleCheckboxChange = (categoryId, isChecked) => {
    setSelectedCategoryIds(prevSelectedCategoryIds => {
      var selectedIds = prevSelectedCategoryIds;
      if (isChecked) {
        // Add the ID if it's not already in the array
        if (!prevSelectedCategoryIds.includes(categoryId)) {
          selectedIds = [...prevSelectedCategoryIds, categoryId];
          onSelect(selectedIds)
        }
      } else {
        selectedIds = prevSelectedCategoryIds?.filter(id => id !== categoryId);
        onSelect(selectedIds)
        
      }

      return selectedIds;
    });
  };

  return (
    <div className='mb-4'>
      <h4>{_t("Subject")}</h4>
      <div>
        {categories?.map(category => (
          <div key={category.id}>
            <div className={`custom-checkbox-1 lan-rtl`}>
              <input 
                id={`createAccount` + category.id} 
                type="checkbox" 
                name={`createAccount` + category.id} 
                value="1" 
                onChange={(e) => handleCheckboxChange(category.id, e.target.checked)}
              />
              <label htmlFor={`createAccount` + category.id}>{Utils.getDefaultName(category)}</label>
            </div>
            {category?.childs?.length > 0 && (
              <div className={Utils.isRtl ? "pr-2" : "pl-2"}>
                {category.childs.map(child =>
                  <div key={child.id} className={`custom-checkbox-1`}>
                    <input 
                      id={`createAccount` + child.id} 
                      type="checkbox" 
                      name={`createAccount` + child.id} 
                      value="1" 
                      onChange={(e) => handleCheckboxChange(child.id, e.target.checked)}
                    />
                    <label htmlFor={`createAccount` + child.id}>{Utils.getDefaultName(child)}</label>
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default FilterByCategoriesWidget;
