import React from 'react'
import FaqsList from 'view/features/feature_home/components/FaqsList';
import PageLayout from 'view/components/shared/PageLayout'
import TranslateController from 'controller/shared/TranslateController';
import HomeTutorials from 'view/features/feature_home/components/HomeTutorials';

function FaqPage() {

	const {_t} = TranslateController()

	return (
		<PageLayout showbreadcrumbs={false} HeaderChild={
			<h1 className="text-color-light my-5 font-weight-bold text-10 lan-kfont">{_t("FAQ")}</h1>
		}>
			<div role="main" className="main">

				<div className="container">
					<div className="row pb-4 mb-4 full-width ">
						<div className="col-md-12 mx-md-auto my-3 ">
							<FaqsList className="faq-gray" />
						</div>
						
						<HomeTutorials padding={false} />
					</div>
				</div>

			</div>
		</PageLayout>
	)
}

export default FaqPage