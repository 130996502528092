import React from 'react'

function VideoList() {
    return (
        <div className='video-list nice-scroll'>
            <div className="row video-list-item">
                <div className="col-md-6 video-list-item-image">
                    <img src="assets/images/sample.jpg" title="" alt="" />
                </div>
                <div className="col-md-6 video-list-item-desc">
                    <h3>Episode 1</h3>
                    <p>33 min</p>
                </div>

            </div>
            <div className="row video-list-item">
                <div className="col-md-6 video-list-item-image">
                    <img src="assets/images/sample1.jpg" title="" alt="" />
                </div>
                <div className="col-md-6 video-list-item-desc">
                    <h3>Episode 1</h3>
                    <p>33 min</p>
                </div>

            </div>
            <div className="row video-list-item">
                <div className="col-md-6 video-list-item-image">
                    <img src="assets/images/sample.jpg" title="" alt="" />
                </div>
                <div className="col-md-6 video-list-item-desc">
                    <h3>Episode 1</h3>
                    <p>33 min</p>
                </div>

            </div>
            <div className="row video-list-item">
                <div className="col-md-6 video-list-item-image">
                    <img src="assets/images/sample1.jpg" title="" alt="" />
                </div>
                <div className="col-md-6 video-list-item-desc">
                    <h3>Episode 1</h3>
                    <p>33 min</p>
                </div>

            </div>
            
        </div>
    )
}

export default VideoList