import {  CheckCircleOutline, QuizOutlined } from '@mui/icons-material';
import React from 'react'
import { Link, useParams } from "react-router-dom";
import {  Utils } from '../../../../utils';
import LessonQuizesController from 'controller/course/lesson/LessonQuizesController';
import TranslateController from 'controller/shared/TranslateController';

function LessonQuizes({ lesson, course , isLight = false }) {

    const { data  } = LessonQuizesController(lesson )
  const { quiz } = useParams()

  const {_t} = TranslateController();
    

    return (
        <div className='box'>

            <div className="box-body ">
                <div >
                    <div>
                        {data?.map(item => {
                            let isDone = (item?.course_logs && item?.course_logs.length > 0) && item?.course_logs[0].ended != null
                            let pass = item?.quiz_result?.grade>75;
                            return (
                                <Link key={"lqs"+item.id} to={`/course/${course}/lesson/${lesson}/quiz/${item.id}`} className="no-decoration lan-rtl" >
                                    <div className={isLight ? `content-item content-item-light mb-2 ${quiz == item.id ? "active" : ""}` : `content-item mb-2`} key={`ccontent${item.id}`}>
                                        <div className={`icon ${isDone || pass ? "success" : ""}`}>
                                            
                                            {pass ? <CheckCircleOutline />  : <QuizOutlined />}

                                        </div>
                                        <div className="description">
                                            {Utils.getDefaultName(item)}
                                            <div className="info">
                                                <div className='content-length kfont'>{item.questions.length} {_t("questions")}</div>
                                                {/* {item.questions.length>0 && 's'} */}
                                                <span aria-hidden="true" >•</span>
                                                <div className='content-length kfont'> {_t("Grade")}: {item?.quiz_result?.grade ? item?.quiz_result.grade + '%' : '' ?? '-'}</div>
                                            </div>

                                        </div>
                                    </div>
                                </Link>
                            )
                        }
                        )}
                    </div>
                </div>
            </div>


        </div>
    )
}

export default LessonQuizes