import React from 'react'
import CMSLayout from 'view/cms/components/shared/CMSLayout'
import CategoryList from '../components/CategoryList'

function CategoryPage() {
  return (
    <CMSLayout>
      <CategoryList />
    </CMSLayout>
  )
}

export default CategoryPage