import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { CourseHttpService } from 'services/course'; // Adjust the path to your service
import { Avatar, LinearProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import { Utils } from 'utils';
import UserStatusDot from 'view/cms/features/feature_user/components/UserStatusDot';

const TopProgressedUsersWidget = () => {
  const { data, isLoading, error } = useQuery('topProgressedUsers', () => 
    CourseHttpService.loadEnrolments(1, '', '&size=10&sort_by=progress&order=desc')
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='widget'>
      {isLoading && <LinearProgress style={{ width:"100%" }} />}
      <table className="table">
        <tbody>
          {data?.results?.map((item) => (
            <tr key={item.id+"utr"} className="">
              <td className="d-flex">
                <Avatar 
                  alt={item.user.profile.first_name} 
                  src={Utils.getUserImage(item.user.profile)} 
                  style={{ marginRight: '10px' }}
                />
                <div>
                  <Link to={`/cms/user/${item.user.id}`} className="kfont">
                   <UserStatusDot last_online={item.user.last_online} /> {item.user.profile.first_name} {item.user.profile.last_name}</Link> 
                    
                  <br/>
                  <div className="badge badge-primary">{item.progress}%</div>
                  <Link to={`/cms/course/${item.course.id}/enrolled`} className="kfont">
                    <span className='text-1 ml-1'>{Utils.getDefaultName(item.course)}  </span>
                  </Link>
                  
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TopProgressedUsersWidget;
