import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { Utils } from '../../../../utils';
import { useQuery } from 'react-query';
import { CourseHttpService } from 'services/course';

function CourseSummaryAside({ course }) {

    const [lectures, setLectures] = useState(null)

    const { isLoading, error, data } = useQuery('lecture_by_course', () => { return CourseHttpService.loadLectures(course) }, {
        onSuccess: (data) => {
            if (data.results) {
                setLectures(data.results)
            }
            // Utils.initiateTheme()
        }
    });

    return (
        <div className='course-summary-aside'>
            {/* <img src={course?.image} alt="" className='course-aside-thumb' />
            <h3>{course && Utils.getDefaultName(course)}</h3> */}
            {/* <p className='text-gray'>Kurdish Academy</p> */}
            <ul className='hide-on-small'>
                {lectures && lectures.map(item => (
                    <Link to={`/course/${course}/lecture/${item.id}`}><li><i className='fa fa-chevron-right'></i>{Utils.getDefaultName(item)}</li></Link>
                ))}
            </ul>
        </div>

    )
}

export default CourseSummaryAside