import React from 'react'
import CmsHeader from '../../components/shared/CmsHeader'
import CmsCharts from '../../components/Widgets/CmsCharts'
import AdminView from 'view/cms/components/shared/Permissions'

function Dashboard() {
  return (
    <div className="body">
			<CmsHeader />

			<div role="main" className="main ">
          <div className="container">
          <h5>Dashboard</h5>
          <AdminView>
            <CmsCharts />
          </AdminView>
          </div>
			</div>
			{/* <Footer /> */}
		</div>
  )
}

export default Dashboard