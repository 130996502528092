import React from 'react'
import AuthorsList from 'view/cms/features/feature_blog/components/AuthorsList'
import CmsHeader from 'view/cms/components/shared/CmsHeader'

function AuthorsPage() {
    return (
        <div className="body">
            <CmsHeader />

            <div role="main" className="main">
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-lg-12">
                            <AuthorsList />
                        </div>
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </div>
    )
}

export default AuthorsPage