import React, { useContext, useState } from 'react'
import { UserHttpService } from '../../../services/user';
import { signInWithFacebook, signInWithGoogle, signInWithTwitter } from '../../../services/third-party/firebase';
import { setUserSession, Utils } from '../../../utils';
import TranslateController from 'controller/shared/TranslateController';
import { Link } from 'react-router-dom';

function SocialLogin({...props }) {

  const [loading,setLoading] = useState(false);
  const [error,setError] = useState("");

  const {_t} = TranslateController();

  const _signInWithSocial = async (provider = "google") => {

    
    setLoading(true);
    setError("")
    var response;
    if (provider === "facebook") {
      response = await signInWithFacebook()
    }
    if (provider === "google") {
      response = await signInWithGoogle()
    }

    if (provider === "twitter") {
      response = await signInWithTwitter()
    }

    // if(!response.email){
    //   response.email = response.uid+"@kurdishacademy.talent";
    // }

    // console.log(response);


    if (response?.uid) {
      UserHttpService.registerSocial(response).then((user) => {
        setLoading(false);
        if (user.role) { // user saved successfully
          // setDone(true)

          Utils.setUserSession(user.access, user);

          // setAuthenticate(user)
          // if (props.onLoggedIn) {
          //   props.onLoggedIn(user)
          // }
          // else 
          {
            if (user.role == 'admin') {
              window.location.href = "/cms"
            }
            else if(props?.setLoggedIn) {
              props.setLoggedIn()
            }
            else {
              Utils.redirectParticipant()
            }
          }
        }

      })
    }
    else {
      setLoading(false);
      setError("an error occurred while signing in")
    }
  }



  return (
    <div className="d-flex flex-column align-items-center social-images lan-kfont lan-rtl">
      <p className="text-center lan-kfont text-light">{_t("Please sign up or sign in to participate")}</p>
      <div className="d-flex justify-content-center py-3 flex-gap-3">
        {/* <div className="" onClick={() => { _signInWithSocial("facebook") }} title="Sign in with facebook"><img src="/assets/images/social/colored/facebook.png" alt="" /></div> */}
        <div className="" onClick={() => { _signInWithSocial("google") }} title="Sign in with google"><img src="/assets/images/social/colored/google.png" alt="" /></div>
        {/* <div className="mr-3" onClick={() => { _signInWithSocial("twitter") }} title="Sign in with twitter"><img src="assets/images/social/colored/twitter.png" alt="" /></div> */}
        {props?.goToEmailRegister && (
          <div className="" title="Sign in with email and password" onClick={() => { props.goToEmailRegister() }}><img src="/assets/images/social/colored/email.png" alt="" /></div>
        )}
      </div>
      {error}

      <hr style={{width:"80%", backgroundColor:"#525252", margin: "0px !important"}} />

      <Link to="/faq" className='text-light' >{_t('How to register')}</Link>

    </div>
  )
}

export default SocialLogin