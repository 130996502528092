import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { CourseHttpService } from '../../../services/course'
import { Utils } from '../../../utils'
import CourseSummaryAside from '../../components/course/components/CourseSummaryAside'
import Footer from '../../components/shared/Footer'
import Header from '../../components/shared/Header'
import PageHeader from 'view/components/shared/PageHeader'
import CourseLecturesList from 'view/components/course/week/CourseLecturesList'
import { PlayCircleOutline } from '@mui/icons-material'
import Lessons from 'view/components/course/lesson/Lessons'
import TranslateController from 'controller/shared/TranslateController'
import LectureBreadCrumb from 'view/components/course/content/LectureBreadCrumb'

function LectureDetails() {
	const { course_id, lecture_id } = useParams();

	const [lecture,setLecture] = useState(null);
	const [course,setCourse] = useState(null);
	const [breadcrumbs, setBreadcrumbs] = useState([]);

	const { _t } = TranslateController()

	const { isLoading, error, refetch, data } = useQuery(['lecture', lecture_id], () => { return CourseHttpService.loadLecture(lecture_id) }, {
		onSuccess: (data) => {
			setLecture(data)
			// Utils.initiateTheme()
		}
	});



	const { isLoading: isLoadingCourse, error: errorCourse, refetch: refetchCourse, data: courseData } = useQuery(['course', course_id], () => {
		return CourseHttpService.loadCourse(course_id)
	  }, {
		onSuccess: (data) => {
		  setCourse(data)
		  setBreadcrumbs([
			{ 
			  "title": Utils.getDefaultName(data),
			  "url": `/course/${course_id}/lectures`
			}
		  ]);
		  // Utils.initiateTheme()
		}
	  });



	useEffect(() => {
		refetch();
	}, [lecture_id, refetch])
	return (
		<div className="body">
			<Header dark={1} />

			<div role="main" className="main">
				<PageHeader showbreadcrumbs={false} /> {/** breadcrumbs={breadcrumbs} title={data && Utils.getDefaultName(data)}  */}
				<div id='main'>
					<div className='custom-page-content p-relative z-index-2'>
						<div className="container custom-padding-top-1 custom-padding-bottom-1 px-lg-5 bg-light">

							<div className="container">
								<div className="row pb-4 mb-4 full-width ">
									<div className="col-md-2 mx-md-auto p-0 hide-on-small">
										<CourseSummaryAside course={course_id} />
									</div>
									<div className="col-md-10 mx-md-auto">
										<div className="mb-4 mt-3" >
											<LectureBreadCrumb lecture={lecture} courseData={course} />
										</div>
										{isLoading && (<>Loading ...</>)}
										{!isLoading && (<LectureDetailsContent lecture={lecture} course={course_id} />
										)}
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>

			</div>

			<Footer />
		</div>


	)
}

function LectureDetailsContent({lecture, course}) {
	const { _t } = TranslateController()

	return (
		<>
			<div className="lecture-information row pt-0">
				<div className='col-md-6 pt-0'>
					<PlayCircleOutline />
					{lecture?.lecture_report.Video.remaining_duration > 0 ? <>
						<b>{lecture.lecture_report.Video.remaining_duration} min</b> {_t(`of videos left`)}
					</> : "All videos completed"}

				</div>
				<div className='col-md-6 pt-0'>
					<i className="fa fa-book"></i>
					{lecture?.lecture_report.Reading.remaining_duration > 0 ? <>
						<b>{lecture.lecture_report.Reading.remaining_duration} min</b> {_t(`of readings left`)}
					</> : "All readings completed"}

				</div>
				{/* <div className=''>
											<i className="fa fa-list"></i>
											All graded assessments completed
										</div> */}
			</div>
			<div className="card-body p-1 mt-2">

				{/* <div className="item-description mb-1">
					{Utils.parseDescription(lecture)}
				</div> */}
				{lecture && <Lessons lecture={lecture.id} course={course} />}
				
			</div>

		</>
	)
}

export default LectureDetails