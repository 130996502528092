import TranslateController from 'controller/shared/TranslateController'
import React, { useEffect } from 'react'
import ProfileForm from 'view/cms/components/forms/user/ProfileForm'
import PageLayout from '../shared/PageLayout'

function Profile() {
	const { _t } = TranslateController()

	return (
		<PageLayout showbreadcrumbs={false} title={"Profile"}
		HeaderChild={<h1 className="text-color-light my-3 font-weight-bold text-10 text-sm-8 lan-kfont">{_t("Profile")}</h1>}>
			<div className="container mt-3">
				<div className="row full-width ">
					<div className="col-md-12 mb-4 ">
						{/* <h2>{_t("Edit my profile")}</h2> */}
						<ProfileForm />
						
					</div>
				</div>
			</div>
		</PageLayout>


	)
}

export default Profile