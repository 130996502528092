import React, { useContext, useEffect, useState } from 'react'
import validator from 'validator'
import { translate, Utils } from '../../../utils';
import { UserHttpService } from '../../../services/user';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { HttpService } from 'services/http';

const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);


    const handleChange = e => {
        setValue(e.target.value);
    }
    return {
        value,
        onChange: handleChange
    }
}


export default function RegisterAndLoginForm({ showRegister = true }) {

    const { setLanguage, translations } = useContext(LanguageContext);
    const _t = (key) => {
        return translate(key, translations)
    }

    const username = useFormInput('');
    const password = useFormInput('');

    const register_username = useFormInput('');
    const register_password = useFormInput('');
    const register_name = useFormInput('');
    const register_surename = useFormInput('');
    const register_phone = useFormInput('');

    const [birthdate, setBirthdate] = useState([]);
    const [gender, setGender] = useState(null);
    const [register_user_info, setRegisterUserInfo] = useState([]);

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const [register_error, setRegisterError] = useState(null);
    const [register_loading, setRegisterLoading] = useState(false);
    const [register_done, setRegisterDone] = useState(false);
    const [register_email_sent, setRegisterEmailSent] = useState(false);
    const [email_sent, setEmailSent] = useState(false);
    const [login_email_not_verified, setLoginEmailNotVerified] = useState(false);


    useEffect(() => {
        // loadCountries(1, 10000).then(countries => {
        //     if (countries) {
        //         sortByKey(countries)
        //         setCountries(countries);
        //     }

        // });


    }, [])



    const onValue = (name, value) => {
        var tu = register_user_info;
        switch (name) {

            case "gender":
                setGender(value)
                break;
            default: break;
        }
    }



    const handleLogin = (e) => {
        e.preventDefault()
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        setError(null);
        setLoading(true);
        setEmailSent(false);
        setLoginEmailNotVerified(false)

        if (!validator.isEmail(username.value)) {
            setError('Email address is not valid');
            setLoading(false);
            return;
        }

        if (!validator.isLength(password.value, { min: 6 })) {
            setError('Password is not valid');
            setLoading(false);
            return;
        }


        UserHttpService.doLogin({ email: username.value, password: password.value }).then((data) => {
            setLoading(false)
            if (data.tokens) {
                Utils.setUserSession(data.tokens.access, data);

                if (data.role === "referee")
                    window.location.href = "referee"
                else //if (data.role === "participant" || data.role === "admin"){
                    Utils.redirectParticipant()
                // }
                // else if (data.role === "normal")
                // window.location.href = "/"
            }
            else {
                setError(data.detail);

                if (data.detail && data.detail.includes("Email")) {
                    setLoginEmailNotVerified(true)
                }
            }
        })

    }

    const handleRegister = () => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Origin', 'http://localhost:3001');

        setRegisterError(null);
        setRegisterLoading(true);
        setRegisterDone(false);
        setRegisterEmailSent(false);

        if (!validator.isLength(register_name.value, { min: 3 })) {
            setRegisterError(_t('Please Enter Your Name'));
            setRegisterLoading(false);
            return;
        }

        if (!validator.isLength(register_surename.value, { min: 3 })) {
            setRegisterError(_t('Please Enter Your Surname'));
            setRegisterLoading(false);
            return;
        }


        if (!validator.isEmail(register_username.value)) {
            setRegisterError(_t('Email address is not valid'));
            setRegisterLoading(false);
            return;
        }

        if (!validator.isLength(register_password.value, { min: 6 })) {
            setRegisterError(_t('Password must be 6 characters or more '));
            setRegisterLoading(false);
            return;
        }

        var body = {
            email: register_username.value,
            password: register_password.value,
            first_name: register_name.value,
            last_name: register_surename.value,
            // gender: gender,
            redirect_url: HttpService.SiteBase + "/validate"
        }


        UserHttpService.registerParticipant(body)
            .then(function (response) {

                if (response.status >= 400) {
                    setRegisterError(_t("This email address is already exists."));
                }

                if (response.errors) {
                    setRegisterError(_t("This email address is already exists."));
                }


                setRegisterLoading(false);

                if (response.data && response.data.email) {
                    //setRegisterError(data.email);
                    console.log(response.data)
                    setRegisterDone(true);
                }



            });


    }


    const resendRgisterVerificationEmail = (e) => {
        e.preventDefault();
        setRegisterError(false)
        if (!validator.isEmail(register_username.value)) {
            setRegisterError(_t('Email address is not valid'));
            setRegisterLoading(false);
            return;
        }

        let body = {
            "email": register_username.value
        }

        setRegisterLoading(true)
        UserHttpService.resendVerificationEmail(body).then((data) => {
            setRegisterLoading(false)
            setRegisterEmailSent(true);
            setRegisterDone(false)
        })
    }

    return (
        <div>
            <div className="container py-4">

                <div className="col-md-12 appear-animation pr-5  appear-animation-visible  " data-appear-animation="fadeInRightShorter" data-appear-animation-delay="300">

                    <h2 className="font-weight-bold text-5 mb-1 text-light lan-rtl">{_t('Login')}</h2>
                    <form onSubmit={handleLogin} className="needs-validation form-fields-rounded">
                        <div className="form-row">
                            <div className="form-group col">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-default"><i className="fa fa-user"></i></span>
                                    </div>
                                    <input type="email" placeholder={_t("Email")} name="username"  {...username} className="form-control border-radius-0 text-3 h-auto py-2" />
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-default"><i className="fa fa-lock"></i></span>
                                    </div>
                                    <input type="password" placeholder={_t("Password")} name="password" {...password} className="form-control border-radius-0 text-3 h-auto py-2" required />
                                </div>
                            </div>
                        </div>
                        <div className="form-row justify-content-between">
                            <div className="d-flex flex-space-between lan-rtl">
                                <div className="form-group col-md-auto">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="rememberme" />
                                        <label className="custom-control-label cur-pointer text-2 lan-mr-1" htmlFor="rememberme">{_t('Remember Me')}</label>
                                    </div>
                                </div>
                                <a className="text-decoration-none text-color-hover-white color-gray text-2 lan-kfont" href="forgot">{_t('Forgot Password?')}</a>

                                <button type="submit" className="btn  btn-primary  btn-block text-uppercase rounded-35 font-weight-bold text-3 py-10" value={loading ? 'Loading...' : 'Login'} disabled={loading} data-loading-text="Loading...">
                                    <i className="fas fa-user  op-7"></i>&nbsp;
                                    {_t('Login')}
                                </button>
                            </div>
                           
                        </div>
                        <div className="form-row">
                            <div >
                                {loading && <><small style={{ color: 'green' }}>Please Wait...</small><br /><br /></>}
                                {error &&
                                    <>
                                        <div className="alert alert-danger text-2">
                                            {error}
                                        </div>
                                    </>
                                }
                                {/* {login_email_not_verified &&
                                        <>
                                            <div className="alert alert-primary text-2">
                                                <a href="#resend_email" onClick={resendLoginVerificationEmail}>didn't get verification email ? Send  again</a>

                                            </div>
                                        </>
                                    } */}
                                {email_sent && <>
                                    <div className="alert alert-success">
                                        Email Sent. please check your email address to verify your account.
                                    </div>
                                </>


                                }


                            </div>
                        </div>
                    </form>

                    <hr className='solid-gray my-2' />

                    {showRegister && (<>
                        <h2 className="font-weight-bold text-5 mb-1 text-light lan-rtl">{_t('Register')}</h2>


                        <form action="" className="form-fields-rounded">
                            <div className="form-row">
                                <div className="form-group col-lg-12 lan-rtl">
                                    <input type="text" maxLength="100" className="form-control  border-radius-0 text-3 h-auto py-2" name="name"  {...register_name} placeholder={_t('Name')} />
                                </div>
                            </div>
                            <div className="form-row">

                                <div className="form-group col-lg-12 lan-rtl">
                                    <input type="text" maxLength="100" className="form-control  border-radius-0 text-3 h-auto py-2" name="surename"  {...register_surename} placeholder={_t('Surename')} />
                                </div>
                            </div>
                            {/* <div className="form-row">
            <div className="form-group col-md-6">
                <div className="custom-select-1">
                    <SelectGender placeholder={_t('Gender')} value={gender} callback={onValue} />
                </div>
            </div>
            <div className="form-group col-md-6">
                <div className="input-group">
                    <input type="phone" placeholder={_t("Phone Number")} name="register_phone" {...register_phone} className="form-control text-3 h-auto py-2" />
                </div>
            </div>
        </div> */}
                            <div className="form-row">
                                <div className="form-group col">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="inputGroup-sizing-default"><i className="fa fa-user"></i></span>
                                        </div>
                                        <input type="email" placeholder={_t('Email')} name="register_username" {...register_username} className="form-control  border-radius-0 text-3 h-auto py-2" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="inputGroup-sizing-default"><i className="fa fa-lock"></i></span>
                                        </div>
                                        <input type="password" placeholder={_t('Password')} name="register_password" {...register_password} className="form-control  border-radius-0 text-3 h-auto py-2" required />
                                    </div>
                                </div>
                            </div>
                            

                            {/* <div className="form-row">

            <label className="col-12">{_t("Date of Birth")}</label>
            <div className="form-group col-lg-4">

                <div className="custom-select-1">
                    <SelectYear placeholder={_t('Year')} name="year" width="100" value={year} callback={onValue} />
                </div>
            </div>
            <div className="form-group col-lg-4">
                <div className="custom-select-1">
                    <SelectMonth placeholder={_t('Month')} name="month" width="100" value={month} callback={onValue} />
                </div>
            </div>
            <div className="form-group col-lg-4">
                <div className="custom-select-1">
                    <SelectDay placeholder={_t('Day')} name="day" width="100" value={day} callback={onValue} />
                </div>
            </div>
        </div>
        <label>{_t('I am originally from')}</label>
        <div className="form-group row">
            <div className="col-lg-6 mb-3 ">
                <div className="custom-select-1">
                    <SelectCountries placeholder={_t('Countries')} kurds={1} callback={onValue} value={birth_country} name="birth_country" />
                </div>
            </div>
            <div className="col-lg-6">
                <div className="custom-select-1">
                    <SelectCities placeholder={_t('City')} key={birth_country} country={birth_country} name="birth_city" value={birth_city} callback={onValue} />
                </div>
            </div>
        </div>
        <label>{_t('I am living in')}</label>
        <div className="form-group row">
            <div className="col-lg-6 mb-3 ">
                <div className="custom-select-1">
                    <SelectCountries placeholder={_t('Countries')} kurdsOnTop={1} callback={onValue} value={living_country} name="living_country" />
                </div>
            </div>
            <div className="col-lg-6">
                <div className="custom-select-1">
                    <SelectCities placeholder={_t('City')} key={living_country} country={living_country} value={living_city} name="living_city" callback={onValue} />
                </div>
            </div>
        </div> */}
                            <div className="form-row lan-rtl ">
                                <div className="form-group col text-right lan-text-left">
                                    {register_loading && <><small className="loading">{_t('Please Wait...')}</small><br /><br /></>}

                                    <button type="button" onClick={handleRegister} disabled={register_loading} className="btn btn-primary  btn-block text-uppercase rounded-35 font-weight-bold text-3  py-10" data-loading-text="Loading...">
                                        <i className="fas fa-user-plus op-7"></i>&nbsp;
                                        {_t('Register')}
                                    </button>

                                    {register_error && <>
                                        <br />
                                        <div className="alert alert-danger text-2 ">
                                            {register_error}
                                        </div>
                                    </>}


                                    {register_done && <>
                                        <br />
                                        <div className="alert alert-success mt-3">
                                            {_t('Register Completed. please check your email address to verify your account.')} <br />
                                            <a href="#resend_email" onClick={resendRgisterVerificationEmail}>{_t("Didn't you get verification email ? Send again")}</a>
                                        </div>
                                    </>


                                    }
                                    {register_email_sent && <>
                                        <br />
                                        <div className="alert alert-primary mt-3 mb-2">
                                            {_t('Email Sent. please check your email address to verify your account.')}
                                        </div>
                                    </>


                                    }

                                </div>
                            </div>
                        </form>


                    </>)}


                </div>




            </div>
        </div>
    )
}

