import React, { useEffect } from 'react'
import Footer from '../../components/shared/Footer'
import Header from '../../components/shared/Header'
import SlideShow from '../../components/shared/home/SlideShow'
import Suggestions from '../../components/shared/watch/Suggestions'
import VideoListAside from '../../components/shared/watch/VideoListAside'
import WatchVideoPlayer from '../../components/shared/watch/WatchVideoPlayer'

function Watch() {

	return (
		<div className="body">
			<Header />

			<div role="main" className="main">

				<div className="container-large">
					<div className="row pb-4 mb-4 full-width ">
						<div className="col-md-9 mx-md-auto">
							<WatchVideoPlayer />
							<div className="video-information">
								<div className="video-tags">
									<div className="tags mt-3">
										<div>Tutorial</div>
										<div>Music</div>
									</div>
									<div className="count-of-reviews">
										<div className="stars">
											<span className="fa fa-star"></span>
											<span className="fa fa-star"></span>
											<span className="fa fa-star"></span>
											<span className="fa fa-star"></span>
											<span className="fa fa-star"></span>
										</div>
										<p>75 Reviews</p>

									</div>
								</div>
								<div className="video-title">
									<div>
										<h1>Episode 1 <span className="fa fa-bookmark ml-2"></span></h1>
										<div className="created-by">
											w/ Michael Brauer
										</div>
									</div>
									<div className="video-buy-buttons">
										<button type="button" class="btn btn-light mr-1 ">Become pro</button>
										<button type="button" class="btn btn-light"><i class="fas fa-shopping-cart"></i> Buy video</button>
									</div>
								</div>
								<div className="video-details">
									<div class="tabs tabs-simple bg-tabs ">
										<ul class="nav nav-tabs tabs-episodes">
											<li class="nav-item ">
												<a class="nav-link active" href="#description" data-toggle="tab">Description</a>
											</li>
											<li class="nav-item">
												<a class="nav-link" href="#equipment" data-toggle="tab">Equipment & Software</a>
											</li>
											<li class="nav-item">
												<a class="nav-link" href="#reviews" data-toggle="tab">Reviews (75)</a>
											</li>
										</ul>
										<div class="tab-content">
											<div class="tab-pane active p-3" id="description">
												It's here! For the first time on video, Michael Brauer unleashes his signature "Brauerize©" method, 100% in the box, featuring his famous multi-bus compression techniques.

												For the past 44 years, Michael Brauer has helped shape the sound of modern music with global superstars like Coldplay, John Mayer, Bob Dylan, Aretha Franklin, The Rolling Stones, Bon Jovi, and countless others. Michael's commitment to musicality during the mixing process has connected billions of listeners with the artists that call upon him. His ability to convey emotion and excitement in mixes is unparalleled, thanks in part to the tireless development of his signature "Brauerize©" method.
												Since the Brauerize © method began to accrue fame in the early 2000s, engineers have strived to re-create the technique in the box, with countless blog articles and videos surfacing in attempts to master the technique.

												In this pureMix.net exclusive video, Michael Brauer sits down to explain every component of the Brauerize © method in perfect detail in the box. After he and his assistant, Fernando Reyes, explain the history of creating the Brauerize© Pro Tools template, Michael mixes a song from scratch on camera.

												Once you have seen how Michael does it, download his actual template and try it for yourself!

												In this video, you will sit next to Michael as he:

												Explains his career history
												Discusses How he learned to hear compression
												Tells the story of how he had to fight with the balance of low end and compression on Aretha Franklin's "Freeway Of Love", which led to his development of the "Brauerize©" method on an SSL 6000 console that changed everything for him
												Explains the importance of performing a mix
												Discusses the evolution from analog to hybrid and eventually hybrid to fully digital in the box
												Explains how COVID limitations pushed transition to a 100% digital workflow
												Breaks down each section of his template, discussing his plugin and setting choices on each channel
												Teaches his multi-parallel compression technique on vocals
												This is your chance to learn from one of the world's most treasured mixing engineers. Watch Michael Brauer teach the Brauerize© method. Only on pureMix.net
											</div>
											<div class="tab-pane" id="equipment">
												Chapters
											</div>
											<div class="tab-pane" id="reviews">
												reviews
											</div>
										</div>
									</div>

								</div>
							</div>
						</div>
						<div className="col-md-3 mx-md-auto p-0">
							<VideoListAside />
							<Suggestions />
						</div>
					</div>
				</div>

			</div>

			<Footer />
		</div>


	)
}

export default Watch