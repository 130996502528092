import React from 'react';
import { useNavigate } from 'react-router-dom';

function UserCourseProgress({ progress = 0, url = null }) {

  const navigate = useNavigate();
  
  const handleClick = () => {
    if (url) {
      navigate(url);
    }
  };

  return (
    <div className="progress">
      <div
        className="progress-bar progress-bar-primary"
        data-appear-progress-animation={`${progress}%`}
        data-appear-animation-delay="20"
        role="progressbar"
        aria-valuenow="60"
        aria-valuemin="0"
        aria-valuemax="100"
        style={{ width: progress + "%", marginLeft: progress > 0 ? 0 : "10px", cursor: url ? 'pointer' : 'default' }}
        onClick={handleClick}
      >
        {parseInt(progress)}%
      </div>
    </div>
  );
}

export default UserCourseProgress;
