import React from 'react'
import LevelList from './LevelList'
import CMSLayout from 'view/cms/components/shared/CMSLayout'

function LevelPage() {
    return (
        <CMSLayout>
            <LevelList />
        </CMSLayout>
        
    )
}

export default LevelPage