import { HttpService } from "services/http";
import { Utils } from "utils";

export class SearchHttpService {

    static getSearchResults = async ({query="",pageParam =1,size=30})  =>  {
        if(!pageParam) pageParam = 1;
        let query_text = `?queru=${query}&page=${pageParam}&size=${size}`;
        // let language_id = Utils.getCurrentLanguageId();

        // if (language_id) query_text += "&language=" + language_id;

        var service = new HttpService();
        var response = await service.get(`/academy/course/${query_text}`);

        return response;

    };

}