import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CourseHttpService } from 'services/course';

function NotePageSquareInlineForm({ square, currentTime, className, onDelete, notePageId, ...props }) {
    const { register, handleSubmit, setValue, watch, formState: { errors }, } = useForm();
    const [loading, setLoading] = useState(false);
    const [done, setDone] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        for (const key in square) {
            setValue(key, square[key]);
        }
    }, [square, setValue]);

    const saveChanges = (data) => {
        setError(false);

        var body = {
            "width": data.width,
            "height": data.height,
            "top": data.top,
            "left": data.left,
            "time": data.time,
            "note_page": notePageId,
        };

        if (square) body.id = square.id;

        setDone(false);
        setLoading(true);
        CourseHttpService.saveNotePageSquare(body).then(response => {
            setLoading(false);
            setDone(true);
            setError(false);
            if (props.onEditDone) {
                props.onEditDone(response);
            }
        }).catch(err => {
            setLoading(false);
            setError(true);
        });
    };

    const handleSetCurrentTime = () => {
        setValue('time', Math.floor(currentTime * 1000)); // Set current time in milliseconds
    };

    const handleDuplicate = () => {
        const duplicatedSquare = { ...square, id: null }; // Create a duplicate without an id
        CourseHttpService.saveNotePageSquare(duplicatedSquare).then(response => {
            if (props.onEditDone) {
                props.onEditDone(response);
            }
        }).catch(err => {
            console.error("Error duplicating square: ", err);
        });
    };

    return (
        <tr>
            <td colSpan="7" className='p-0'>
                <form className="" onSubmit={handleSubmit(saveChanges)}>
                    <table className={`table ${className}`}>
                        <tbody>
                            <tr>
                                <td style={{ width: "80px", padding:"0px" }}>{square?.id}</td>
                                <td><input type="number" placeholder='Top' style={{ width: "80px" }} {...register("top")} /></td>
                                <td><input type="number" placeholder='Left' style={{ width: "80px" }} {...register("left")} /></td>
                                <td><input type="number" placeholder='Width' style={{ width: "80px" }} {...register("width")} /></td>
                                <td><input type="number" placeholder='Height' style={{ width: "80px" }} {...register("height")} /></td>
                                <td>
                                    <input type="number" placeholder='Time' style={{ width: "80px" }} {...register("time")} />
                                    <button type="button" title="Set current time" className="btn mr-2" onClick={handleSetCurrentTime}>
                                        <i className="fa fa-clock"></i>
                                    </button>
                                </td>
                                <td>
                                    <button title="Save" className="btn btn-success mr-2" type="submit"><i className="fa fa-save"></i></button>
                                    {square && (
                                        <button title="Duplicate" className="btn btn-warning mr-2" onClick={handleDuplicate}><i className="fa fa-copy"></i></button>
                                    )}
                                    {square && (
                                        <button title="Delete" className="btn btn-danger" onClick={(e) => { onDelete(e, square.id) }}><i className="fa fa-trash"></i></button>
                                    )}
                                    <div className="saving-inline">
                                        {loading && (<>Saving...</>)}
                                        {!done && (<> </>)}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </td>
        </tr>
    );
}

export default NotePageSquareInlineForm;
