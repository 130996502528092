import TranslateController from 'controller/shared/TranslateController'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { CourseHttpService } from 'services/course'
import { Utils } from 'utils'

function RecentlyViewedCoursesController() {
    const { _t } = TranslateController()


  const { isLoading, error, data } = useQuery(['recently_viewed_courses'], () => {
    return CourseHttpService.loadRecentlyViewedCourses()
  }, {
    onSuccess: (data) => {
      Utils.initiateTheme()
    }
  })

  return { data, isLoading, error,_t }
}

export default RecentlyViewedCoursesController