import { StarOutline } from '@mui/icons-material';
import React from 'react';

const ReviewStars = ({ rating }) => {
  const stars = Array.from({ length: 5 }, (_, index) => (
    <StarOutline
      key={index}
      className={index < rating ? 'gold-star' : ''}
    />
  ));

  return <div className="review-stars">{stars}</div>;
};

export default ReviewStars;