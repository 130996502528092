import {  useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CourseHttpService } from "services/course";

function ContentsController(lesson) {
  
    
    const [data, setData] = useState(null);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [quizModalIsOpen, setQuizModalIsOpen] = useState(false);
    const [questionModalIsOpen, setQuestionModalIsOpen] = useState(false);

    const [selectedId, setSelectedId] = useState(0);
    const { id } = useParams();
    const [refresh, setRefresh] = useState(0);

    let navigate = useNavigate();

   
    useEffect(() => {

        _getData()

        return () => {
        }
    }, [])

    const _getData = () => {
        setLoading(true);
        CourseHttpService.loadContents(lesson).then((items) => {
            if (items.results) {
                setData(items.results)
            }
        });
    }


    const _handleDelete = (e, id) => {
        setSelectedId(id);
        setRefresh(refresh + 1)
        setDeleteDialogIsOpen(true)
    }

    const _onDeleteDone = () => {
        var temp = data.filter(item => item.id !== selectedId)
        setData(temp)
        setDeleteDialogIsOpen(false);
        setRefresh(refresh + 1)
    }

    const _edit = (e, item) => {
        e.preventDefault();
        setSelectedId(item.id);
        setEditModalIsOpen(true)
        setRefresh(refresh + 1)
    }

    const _showQuizes = (e, item) => {
        e.preventDefault();
        setSelectedId(item.id);
        setQuizModalIsOpen(true)
        setRefresh(refresh + 1)
    }

    const _showQuestions = (e, item) => {
        e.preventDefault();
        setSelectedId(item.id);
        setQuestionModalIsOpen(true)
        setRefresh(refresh + 1)
    }




  return { data,setData,navigate, setSelectedId,selectedId, setEditModalIsOpen,_showQuestions,_showQuizes,editModalIsOpen,deleteDialogIsOpen
    ,setDeleteDialogIsOpen,questionModalIsOpen, setQuestionModalIsOpen,quizModalIsOpen,setQuizModalIsOpen,_onDeleteDone,_handleDelete,_edit }
}

export default ContentsController