import React from 'react'
import CMSLayout from 'view/cms/components/shared/CMSLayout'
import CourseCategoryList from 'view/cms/components/forms/course/CourseCategoryList'

function CourseCategoryPage() {
  return (
    <CMSLayout>
      <CourseCategoryList />
    </CMSLayout>
  )
}

export default CourseCategoryPage