import { BrowserRouter } from "react-router-dom";
import { UserContextProvider } from "./contexts/UserContext";
import { LanguageContextProvider } from "./contexts/LanguageContext";
import MainRoutes from "./routes/MainRoutes";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useEffect, useRef } from "react";
import { Utils } from "./utils";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from 'react-query'

const darkTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: "#4b4943",
    },
  },
});

const queryClient = new QueryClient()


var initiatedRef = false;

function App() {

  useEffect(() => {
    Utils.initialize()

		// if (!initiatedRef) {
		// 	Utils.initiateTheme()
		// 	initiatedRef = true;
		// }

    function hideError(e) {
      if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
          const resizeObserverErrDiv = document.getElementById(
              'webpack-dev-server-client-overlay-div'
          );
          const resizeObserverErr = document.getElementById(
              'webpack-dev-server-client-overlay'
          );
          if (resizeObserverErr) {
              resizeObserverErr.setAttribute('style', 'display: none');
          }
          if (resizeObserverErrDiv) {
              resizeObserverErrDiv.setAttribute('style', 'display: none');
          }
      }
  }

  window.addEventListener('error', hideError)
  return () => {
      window.addEventListener('error', hideError)
  }
  
	  }, [])

  return (
    <>
      <BrowserRouter basename="/">
      <QueryClientProvider client={queryClient}>
        <UserContextProvider>
          <LanguageContextProvider>
            <ThemeProvider theme={darkTheme}>
              <MainRoutes />
            </ThemeProvider>
          </LanguageContextProvider>
        </UserContextProvider>
      </QueryClientProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
