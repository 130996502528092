import React from 'react'
import { Utils } from 'utils';

function VideoQuestionIndicators({ playerRef, content,onQuestionClick }) {


    if (!playerRef) return;

    return (
        <>
            {content?.questions.map(question => {
                const player = playerRef.current; // Access the Video.js player instance

                if (!player) return null;  // Ensure player instance exists


                const videoElement = player.el();  // Get the player DOM element

                const progressBarWidth = videoElement.offsetWidth; // Get the video player's width
                const videoDuration = player.duration();  // Get the video's duration

                // Calculate the percentage based on the time the question should show
                let percentage = (Utils.timeInSeconds(question.show_at_time) / videoDuration) * 100;

                if (percentage > 100) percentage = 100;

                // Calculate the position of the question marker
                const divPosition = (percentage * progressBarWidth) / 100;

                // Return the indicator element positioned at the appropriate time
                return (
                    <div
                        key={'vqi' + question.id}
                        onClick={() => { onQuestionClick(question); }}
                        className='video-question-indicator'
                        style={{ left: `${divPosition}px` }}
                    />
                );
            })}

        </>
    )
}

export default VideoQuestionIndicators