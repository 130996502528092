import React from 'react'
import { Utils } from 'utils';

function AdminView({children}) {

    if(!Utils.isAdmin())
        return (<></>);
    
  return (
    <>{children}</>
  )
}

export function EditorView({children}) {

    if(!(Utils.isAdmin() || Utils.isEditor()))
        return (<></>);
    
  return (
    <>{children}</>
  )
}

export default AdminView