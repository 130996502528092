import { LanguageContext } from 'contexts/LanguageContext';
import React, { useContext, useEffect, useRef } from 'react'
import { Utils, translate } from 'utils';
import ArrowTops from './home/ArrowTops';

function PageHeader({ title, child, breadcrumbs=[], showbreadcrumbs = 1 }) {
	if(title){
		document.title = Utils.SiteTitle + title;
	}

	const svgRef = useRef(null);


	useEffect(() => {
		// Utils.initiateTheme()
		const svg = svgRef.current;

	}, [])

	const { translations } = useContext(LanguageContext);
	const _t = (key) => {
		return translate(key, translations)
	}
	return (
		<section className="page-header p-relative border-0 bg-quaternary overflow-hidden m-0 p-0">
			
			<div className="container">
				{/* <CourseSummary course={data} /> */}

				<div className="row align-items-center my-5 pt-5 pb-3">
					<div className="col text-center my-2 pb-2">
						{showbreadcrumbs  && (
							<div className="py-4">
								<ul className="breadcrumb d-flex justify-content-center text-4-5 font-weight-medium">
									<li><a href="/" className="text-color-primary text-decoration-none">HOME</a></li>
									{breadcrumbs && breadcrumbs.map(item=>(
										<li key={"br"+item.title}><a href={item.url} className="text-color-primary text-decoration-none">{item.title}</a></li>
									))}
									{/* <li className="text-color-primary active">{_t(title)}</li> */}
								</ul>

								<h1 className="text-color-light font-weight-bold text-10">{_t(title)}</h1>
							</div>
						)}
						{child}
					</div>
				</div>
			</div>
			{/* <ArrowTops /> */}
		</section>
	)
}

export default PageHeader