import React from 'react'
import UserForm from '../components/UserForm';
import { useParams } from 'react-router-dom';
import CmsHeader from 'view/cms/components/shared/CmsHeader';
import { CourseHttpService } from 'services/course';
import UserEnrolmentStatistics from '../components/UserEnrolmentStatistics';
import UserLinearPreview from '../components/UserLinearPreview';

function UserDetailsPage() {

    const { id } = useParams();

    return (
        <div className="body">
            <CmsHeader />
            <div role="main" className="main">
                <div className="container">
                    <div className="row mt-5">
                        {/* <div className="col-lg-12">
                            <UserForm id={id} onEditDone={() => { }} />
                        </div> */}
                        <div className="col-lg-12">
                            <UserLinearPreview id={id}  />
                        </div>
                        <div className="col-lg-12">
                            <UserEnrolmentStatistics userId={id} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserDetailsPage