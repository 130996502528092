import React from 'react'
import ProgressByCourseChart from './charts/ProgressByCourseChart'
import GenderStatisticsChart from './charts/GenderStatisticsChart'

function CmsCharts() {
    return (
        <>
            <div className="row mt-5">
                {/* <div className="col-lg-6">
                    <CourseStatisticsChart />
                </div> */}
                <div className="col-lg-12">
                    <ProgressByCourseChart />
                </div>
            </div>
            
            
        </>
    )
}

export default CmsCharts