import React from 'react'
import { Utils } from 'utils'
import VideoQuestionChoices from './VideoQuestionChoices'

function VideoQuestion({question , onClose=null}) {
  return (
    <div className='video-question'>
        <h2 className='mb-1 font-size-20'>{Utils.getDefaultName(question)}</h2>
        <VideoQuestionChoices question={question} onClose={onClose} />
    </div>
  )
}

export default VideoQuestion