import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { faBook, faPodcast, faFileAlt, faCog, faSignOutAlt, faLanguage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LanguageContext } from 'contexts/LanguageContext';
import { useQuery } from 'react-query'; 
import { CourseHttpService } from 'services/course';
import { Utils } from 'utils';
import { PostHttpService } from 'view/cms/features/feature_blog/services';

const MegaMenu = () => {
  const [open, setOpen] = useState(false);
  const [courses, setCourses] = useState(null);
  const [podcasts, setPodcasts] = useState(null);
  const [posts, setPosts] = useState(null);
  const { changeLanguage } = useContext(LanguageContext);

  const toggleMenu = () => setOpen(!open);

  // Fetch the latest items for each menu category
  const { data } = useQuery("latestCourses", ()=>{ return CourseHttpService.loadCourses() } , {
    onSuccess:(data)=>{
      if(data.results){
        setCourses(data.results);
      }
    }
  });

  const { data : pods } = useQuery("latestpodcasts", ()=>{ return CourseHttpService.loadPodcasts() } , {
    onSuccess:(data)=>{
      if(data.results){
        setPodcasts(data.results);
      }
    }
  });

  const { data : postsData } = useQuery("latestarticles", ()=>{ return PostHttpService.loadPosts(null,null,5) } , {
    onSuccess:(data)=>{
      if(data.results){
        setPosts(data.results);
      }
    }
  });

  const mainMenuItems = [
    {
      title: "Courses",
      icon: faBook,
      link: "/cms/course/",
      data: courses,
    },
    {
      title: "Podcasts",
      icon: faPodcast,
      link: "/cms/podcast/",
      data: podcasts,
    },
    {
      title: "Articles",
      icon: faFileAlt,
      link: "/cms/posts",
      data: posts,
    },
  ];

  const secondaryMenuItems = [
    {
      title: "Sign Out",
      icon: 'fa fa-sign-out',
      link: "/?signout",
    },
    {
      title: "Settings",
      icon: faCog,
      link: "/cms/settings",
    },
    {
      title: "Languages",
      icon: faLanguage,
      children: [
        { title: "کوردی", action: () => changeLanguage("so") },
        { title: "Kurdî", action: () => changeLanguage("kr") },
        { title: "English", action: () => changeLanguage("en") },
      ],
    },
    
  ];

  return (
    <div className="row">
      {mainMenuItems.map((item, index) => (
        <div key={index} className="col-md-3">
          <Link to={item.link || "#"} className="menu-link">
            <i className={`fa ${item.icon} menu-icon`} />
            <b>{item.title}</b>
          </Link>
          {item.data && (
            <ul className="list-unstyled pl-30">
              {item.data.map((subItem, idx) => (
                <li key={idx}>
                  <i className="fa fa-chevron-right mr-2 chevron-style"></i>
                  <Link to={`/cms/course/${subItem.id}`} className="menu-sub-link">
                    {subItem?.names ? Utils.getDefaultName(subItem).substring(0, 20) : subItem.title.substring(0, 20) + "..."}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}

      <div className="col-md-3">
        {secondaryMenuItems.map((item, index) => (
          <div key={index} className="mb-3">
            <Link to={item.link || "#"} onClick={item.action || undefined} className="menu-link d-block">
              <i className={`fa ${item.icon} menu-icon mr-2`} />
              {item.title}
            </Link>
            {item.children && (
              <ul className="list-unstyled pl-30">
                {item.children.map((child, idx) => (
                  <li key={idx}>
                    <i className="fa fa-chevron-right mr-2 chevron-style"></i>
                    <a
                      onClick={child.action}
                      className="menu-sub-link font-kurdi cursor-pointer"
                    >
                      {child.title}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MegaMenu;
