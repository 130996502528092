import React from 'react'
import QuizBreadCrumb from 'view/components/course/quiz/QuizBreadCrumb'
import { Utils } from 'utils'
import TranslateController from 'controller/shared/TranslateController';
import QuizGradeSummary from './QuizGradeSummary';
import SlidingDialog from 'view/components/modals/SlidingDialog';
import QuizViewController from 'controller/course/quiz/QuizViewController';
import QuizViewQuestions from './QuizViewQuestions';

function QuizViewDetails({ quiz}) {

    const { _t } = TranslateController()

    const { assignmentDialogIsOpen,setAssignmentDialogIsOpen,score,setScore , courseData } = QuizViewController()

    if (!quiz) return;
    return (
        <div className=''>
            <QuizBreadCrumb quiz={quiz} courseData={courseData} />
            
            <h2 className='mb-1'>{Utils.getDefaultName(quiz)}</h2>
            <div className="quiz-info mb-5">
                <div className='content-type'>{_t("Practice Quiz")}</div>
                <span aria-hidden="true" >•</span>
                <div className='content-length'>{Math.ceil(quiz.questions.length * 1.5)} {_t("min")}</div>
            </div>
            <div className="quiz-assignment">
                <h4>{_t("Submit your assignment")}</h4>
                <button className="btn btn-icon bg-primary" onClick={() => { setAssignmentDialogIsOpen(true) }}> {score>75 ? _t("Retake assignment") :_t("Start assignment")}</button>

            </div>

            <hr />

            <QuizGradeSummary quiz={quiz} grade={score} />

            <SlidingDialog open={assignmentDialogIsOpen} onClose={setAssignmentDialogIsOpen}
            title={_t("Test your knowledge")} subtitle={_t("Practice Quiz")} >  {/* +". • 8 min. • 4 total points available"   */}
                <QuizViewQuestions quiz={quiz} onScoreChange={(score)=>{ setScore(score) }} onBack={()=>{ setAssignmentDialogIsOpen(false) }} />
            </SlidingDialog>
            

            <hr />

        </div>

    )
}

export default QuizViewDetails