import { ArrowBack, ArrowForward } from '@mui/icons-material';
import TranslateController from 'controller/shared/TranslateController';
import React from 'react';
import { Utils } from 'utils';

function SlidingDialog(props) {

  const { _t } = TranslateController()


  const handleClose = () => {
    props.onClose(false);
  };

  return (
    <div className={`sliding-dialog ${props.open ? 'open' : ''}`}>
      <div className="sliding-dialog-content nice-scroll">
        <div className="sliding-dialog-header">
          <div className="container-sm">

            <div className="title">
              <div className='sliding-back kfont' onClick={handleClose}>

                
              {!Utils.isRtl() && (<ArrowBack />)}
              {Utils.isRtl() && (<ArrowForward />)}
                {_t("Back")}
              </div>
              <h2>{props?.title}</h2>
              <div className="subtitle">
                {props?.subtitle}
              </div>
            </div>
          </div>
        </div>
        <div className="container-sm">{props.children}</div>

      </div>
    </div>
  );
}

export default SlidingDialog;
