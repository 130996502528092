import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import React, { useState } from 'react'

function CustomAccordion({ children, title, TitleWidget , className , open=false }) {

    const [isOpen, setIsOpen] = useState(false)

    const toggleAccordion = (event) => {
        event.stopPropagation(); // This stops the click event from bubbling up
        setIsOpen(!isOpen);
    };

    return (
        <>
            <Accordion defaultExpanded={open && open>0 ? true : false ?? false}  >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className={`accordin-title-summary lan-rtl lan-kfont ${className}`}
                    
                >
                    {TitleWidget ? <TitleWidget /> : (<div className="text-4 lan-kfont">{title}</div>) }
                    
                </AccordionSummary>
                <AccordionDetails className='lan-kfont'>
                    {children}
                </AccordionDetails>
            </Accordion>
            
        </>
        // <div className={`toggle toggle-minimal toggle-dark`} onClick={toggleAccordion} data-plugin-toggle>
        //     <section className={`toggle ${isOpen ? "active" : ""} `}>
        //         <a className="toggle-title text-dark text-4">{title}</a>
        //         <div className="toggle-content"  style={{display: isOpen ? "block" : "none"}}>
        //             {children}
        //         </div>
        //     </section>
        // </div>
    )
}

export default CustomAccordion