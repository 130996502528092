import { CheckOutlined } from '@mui/icons-material'
import ContentReadingController from 'controller/course/content/ContentReadingController'
import TranslateController from 'controller/shared/TranslateController'
import React from 'react'
import { useEffect } from 'react'
import { Utils } from 'utils'
import Loading from 'view/cms/components/shared/Loading'
import CertificatePreview from '../../user/CertificatePreview'

function ContentReading({ content }) {
    const { _t } = TranslateController()
    var _controller = ContentReadingController(content)

    useEffect(() => {
        _controller.logContent("end")
    }, [])

    return (
        <div className='mt-5 ml-2'>
            <div className="content-reading">
                {Utils.parseDescription(content)}
                {content.document && (
                    <div className='mb-5'>
                        <a title={_t('Download document')} target='_blank' href={`${content.document}`}><img src={'/assets/images/svg/pdf.svg'} style={{ width: 50 }} />
                            {_t('Download document')} </a>

                    </div>
                )}
            </div>

            <div className="f-g-10">
                {_controller.loading && <Loading />}
                {!_controller.is_completed ? (
                    <button className="btn btn-primary" onClick={() => { _controller.logContent("end") }}>{_t("Mark as completed")} </button>

                ) : (
                    <>

                        <div className="completed-reading">
                            <CheckOutlined /> {_t("Completed")}
                        </div>
                        <button className="btn btn-primary" onClick={() => { _controller.goToNextItem() }}>{_t("Go to next item")} </button>
                    </>
                )}

            </div>


            <div className="mt-5">
                {content && content.course_certificate && (
                    <CertificatePreview certificate={content.course_certificate} />
                )}
            </div>


        </div>
    )
}

export default ContentReading