import React, { useContext } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link, useParams } from "react-router-dom";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { CheckCircleOutline, AutoStoriesOutlined } from '@mui/icons-material';
import { Utils } from 'utils';

function AdminUserContents({ lesson, course , userId }) {

    const { content } = useParams()
    return (
        <div className='box'>

            <div className="box-body ">
                <div >
                    <div>
                        {lesson?.contents?.map(item => {
                            let courseLog;

                            if(item?.course_logs && item?.course_logs.length > 0){
                                courseLog = item?.course_logs[0]
                            }
                            let isDone = courseLog && item?.course_logs[0].ended != null

                            return (

                                <Link to={`/course/${course}/lesson/${lesson}/content/${item.id}`} key={`content_light_${item.id}`} className={`no-decoration content-link ${content == item.id ? "active" : ""} `} >
                                    <div className={`content-item full-width content-item-light ${content == item.id ? "active" : ""} `} key={`ccontent${item.id}`}>
                                        <div className={`icon ${isDone ? "success" : ""}`}>
                                            {isDone ?
                                                (
                                                    <CheckCircleOutline />

                                                ) : item.url ? (
                                                    <>
                                                        {content == item.id ? <img src="/assets/images/svg/playon.svg" width="28" height="28" alt="" /> : <img src="/assets/images/svg/play-circle.svg" width="28" alt="" />}
                                                    </>
                                                    
                                                ) : (<img src="/assets/images/svg/book.svg" width="28" alt="" />)}

                                        </div>
                                        <div className="description d-flex space-between full-width">
                                            {/* <span className='content-type'>{item.type ?? "Reading"} : </span> */}
                                            <div className="text">{Utils.getDefaultName(item)}</div> <div title={courseLog && Utils.getDateTime(courseLog.updated_at)}> <i className="fa-regular fa-clock mr-1"></i> {courseLog && Utils.timeAgo(courseLog.updated_at)}</div>  
                                        
                                        </div>
                                    </div>
                                </Link>
                            )
                        }
                        )}
                    </div>
                </div>
            </div>


        </div>
    )
}

export default AdminUserContents