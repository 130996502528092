import TranslateController from 'controller/shared/TranslateController';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import PageLayout from 'view/components/shared/PageLayout';

function AccountDeletionPage() {
  const { uidb64, token } = useParams();
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);

  const {_t} = TranslateController();

  const handleConfirmDeletion = async () => {
    setLoading(true);
    try {
      const response = await fetch(`https://kurdishtalent.academy/api/auth/delete-account-confirm/${uidb64}/${token}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const data = await response.json();
        setStatus('Account deleted successfully.');
      } else {
        setStatus('Invalid link or error occurred.');
      }
    } catch (error) {
      setStatus('An error occurred. Please try again.');
    }
    setLoading(false);
  };

  return (
    <PageLayout
      showbreadcrumbs={false}
      HeaderChild={
        <h1 className="text-color-light my-3 font-weight-bold text-10 text-sm-8 lan-kfont">
          {_t('Account Deletion')}
        </h1>
      }
    >
      <div className="text-center mt-5">
        {status ? (
          <p>{_t(status)}</p>
        ) : (
          <>
          <p>{_t('Are you sure you want to delete your account on kurdish academy?')}</p>
          <button
            className="btn btn-danger"
            onClick={handleConfirmDeletion}
            disabled={loading}
          >
            {loading ? 'Processing...' : 'Confirm Account Deletion'}
          </button>
          </>
        )}
      </div>
    </PageLayout>
  );
}

export default AccountDeletionPage;
