import React from 'react'
import ReviewPercentageItem from './ReviewPercentageItem'

function ReviewPercentage({course}) {
    return (
        <>
            <ReviewPercentageItem rating={5} value={course?.review_counts.five_star.toFixed(2)} />
            <ReviewPercentageItem rating={4} value={course?.review_counts.four_star.toFixed(2)} />
            <ReviewPercentageItem rating={3} value={course?.review_counts.three_star.toFixed(2)} />
            <ReviewPercentageItem rating={2} value={course?.review_counts.two_star.toFixed(2)} />
            <ReviewPercentageItem rating={1} value={course?.review_counts.one_star.toFixed(2)} />
        </>
    )
}

export default ReviewPercentage