import React from 'react'
import { Link } from 'react-router-dom';
import { Utils } from 'utils';

function QuizBreadCrumb({quiz , courseData}) {
    return (
        <nav className='d-flex flex-space-between mb-4' aria-label="breadcrumb ">
            <ul className="breadcrumb text-5 negative-ls-1">
                <li><Link className='hide-on-small' to={`/course/${courseData && courseData.id}`}  onClick={(e)=>{ e.preventDefault(); }}>{courseData && Utils.getDefaultName(courseData)}</Link></li>
                {/* <li className="text-color-primary hide-on-small"><Link href={`/course/${courseData && courseData.id}/lectures/`} onClick={(e)=>{ e.preventDefault(); }}>{_t("lectures")}</Link></li> */}
                <li className="active text-color-primary">{quiz && Utils.getDefaultName(quiz)}</li>
            </ul>
            {/* <div className="content-top-next-prev hide-on-small">
                <Link  onClick={(e)=>{ e.preventDefault(); }}> <i className="fa fa-chevron-left"></i> {_t("Previous")}</Link>
                <Link  onClick={(e)=>{ e.preventDefault(); }}>{_t("Next")} <i className="fa fa-chevron-right"></i></Link>
            </div> */}
        </nav>
    )
}

export default QuizBreadCrumb