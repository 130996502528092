import { AutoStoriesOutlined, QuizOutlined } from '@mui/icons-material'
import TranslateController from 'controller/shared/TranslateController'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { Utils } from 'utils'

function NextContent({ content, onBack }) {
    const { _t } = TranslateController()
    const { course, lesson } = useParams();

    let showQuizNext = false;


    if (content?.next_content==null && content.next_quiz!= null) {
        showQuizNext = true;
    }

    if (content?.next_content!=null && content?.next_content?.lesson !== content.lesson && content.next_quiz!= null) {
        //next content is from next lesson, so show Quizes first
        showQuizNext = true;
    }

    return (
        <div className='video-next-content'>
            <div className="content">
                <div className="icon">
                    {showQuizNext ? <img src="/assets/images/svg/book-blue.svg" width="63" alt="" /> : <img src="/assets/images/svg/play-circle-blue.svg" width="63" alt="" />}
                </div>
                {showQuizNext ? <QuizPreview /> : <ContentPreview />}
            </div>
        </div>
    )

    function QuizPreview() {
        let quiz = content.next_quiz
        return (
            <div className="description">
                <h2 className='kfont'>{_t('Up Next')}</h2>
                <h1>{quiz && Utils.getDefaultName(quiz)}</h1>
                {/* <p>{content?.duration} {_t("min")}</p> */}
                <div className="btns-flex">
                    <Link to={`/course/${course}/lesson/${quiz?.lesson}/quiz/${quiz?.id}`} className="btn btn-primary" onClick={() => { }}> {_t("Start Quiz")}</Link>
                    <a href="#return" className="" onClick={(e) => { e.preventDefault(); onBack(false) }}><i className="fa fa-replay "></i> {_t("Return")}</a>
                </div>

            </div>
        )
    }

    function ContentPreview() {
        let next_content = content?.next_content;
        if(!next_content) return null;


        return (
            <div className="description">
                <h2 className='text-blue'>{_t('Up Next')}</h2>
                <h1>{next_content && Utils.getDefaultName(next_content)}</h1>
                <p>{next_content?.duration} {_t("min")}</p>
                <div className="btns-flex">
                    <Link to={`/course/${course}/lesson/${next_content?.lesson}/content/${next_content?.id}`} className="btn btn-primary" onClick={() => { }}> {_t("Start")}</Link>
                    <a href="#return" className="" onClick={(e) => { e.preventDefault(); onBack(false) }}><i className="fa fa-replay "></i> {_t("Return")}</a>
                </div>

            </div>
        )
    }
}

export default NextContent