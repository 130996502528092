import React from 'react'
import Header from './Header'
import PageHeader from './PageHeader'
import Footer from './Footer'

function PageLayout({children,title,showbreadcrumbs=true,HeaderChild,container=true}) {
    return (
        <div className="body">
            <Header />
            <div role="main" className="main">
                <PageHeader title={title} showbreadcrumbs={showbreadcrumbs} child={HeaderChild} />
                <div className='custom-page-content p-relative z-index-2'>
                    <div className={`${ container? "container" : "" } custom-padding-top-1 custom-padding-bottom-1 px-lg-5 bg-light`}>
                        {children}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PageLayout