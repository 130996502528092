import TranslateController from "controller/shared/TranslateController";

const { useState, useEffect, useContext } = require("react");
const { useParams } = require("react-router-dom");
const { CourseHttpService } = require("services/course");

export default function LecturesController(course) {

    const [data, setData] = useState(null);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [sortModalIsOpen,setSortModalIsOpen] = useState(false);
    
    const [selectedId, setSelectedId] = useState(0);
    const { id } = useParams();
    const [refresh, setRefresh] = useState(0);
    const { _t } = TranslateController()

    useEffect(() => {

        _getData()

        return () => {
        }
    }, [])

    const _getData = () => {
        setLoading(true);
        CourseHttpService.loadLectures(course).then((items) => {
            if (items.results) {
                setData(items.results)
            }
        });
    }


    const _handleDelete = (e, id) => {
        setSelectedId(id);
        setRefresh(refresh + 1)
        setDeleteDialogIsOpen(true)
    }

    const _onDeleteDone = () => {
        var temp = data.filter(item => item.id !== selectedId)
        setData(temp)
        setDeleteDialogIsOpen(false);
        setRefresh(refresh + 1)
    }

    const _edit = (e, item) => {
        e.preventDefault();
        setSelectedId(item.id);
        setEditModalIsOpen(true)
        setRefresh(refresh + 1)
    }

    return {data,setData,setSelectedId,setEditModalIsOpen,_handleDelete,_edit,deleteDialogIsOpen,selectedId,
        setDeleteDialogIsOpen,_onDeleteDone,editModalIsOpen,_t,sortModalIsOpen,setSortModalIsOpen};
    
}