import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useState } from 'react';
import { HttpService } from '../../../../services/http';
import TranslateController from 'controller/shared/TranslateController';
import { useForm } from 'react-hook-form';
import { UserHttpService } from 'services/user';
import validator from 'validator'
import { TextField } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteDialog({ url, passwordNeeded = true, isOpen, message = "Are you sure you want to delete this item ?", method = "delete", body, setOpen, notifyDone }) {

  const { register, handleSubmit, setValue, watch, formState: { errors }, } = useForm();

  const [error, setError] = useState(null);
  const [logedIn, setLogedIn] = useState(null);
  const [loading, setLoading] = useState(false)
  const { _t } = TranslateController()

  const _delete = async (data) => {

    if (passwordNeeded) {
      const result = await checkPassword(data);
      if (result) {
        handleDelete(data);
      }
    }
    else {
      handleDelete(data);
    }

  }

  const handleDelete = () => {
    // setOpen(false);
    if (loading) return false;


    setLoading(true);
    var http = new HttpService()
    if (method === "delete")
      http.delete(url).then(() => {
        setLoading(false);
        if (notifyDone !== undefined)
          notifyDone()
      })
    else if (method === "patch") {
      http.patch(url, body).then(() => {
        setLoading(false);
        if (notifyDone !== undefined)
          notifyDone()
      })
    }
  };

  const handleClose = () => {
    // console.log("delete")
    setOpen(false);
  };


  const checkPassword = async (data) => {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    setError(null);
    setLoading(true);

    if (!validator.isLength(data.password, { min: 6 })) {
      setError(_t('Password is not valid'));
      setLoading(false);
      return false;
    }


    let response = await UserHttpService.checkPassword({ "password": data.password });
    setLoading(false)
    if (response?.success) {
      return true
    }
    else {
      setError(_t("Password is not valid"));
      return false;
    }

  }



  return (
    <div>

      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        onKeyDown={(e) => {
          if (e.key == "Enter") {
            _delete()
          }
          if (e.key == "Escape") {
            handleClose()
          }
        }}
      // onKeyDown={(e)=>{
      //   if (e.key === 'Enter') {

      //   }
      //   }}
      >
        <form className="mt-4" onSubmit={handleSubmit(_delete)}>
          <DialogTitle>
            <div className="dialog-icon">
              {/* <Error style={{ fontSize: '58px' }} /> */}
            </div>
            <div className='dialog-delete-message'>
              {_t(message)}

              {passwordNeeded && (
                <div className="form-group mt-3">
                  <TextField
                    fullWidth

                    label="Password"
                    value={watch('password') ?? ""}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    {...register("password")}
                  />
                </div>
              )}
              {error && (
                <div className="alert alert-danger mt-3">
                  {error}
                </div>
              )}

            </div>
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>{_t("No")}</Button>
            <Button onClick={handleSubmit(_delete)}>{loading ? "..." : _t("Yes")}</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}