import React, { useEffect, useRef, useState } from "react";

import { useForm } from "react-hook-form";
import { Button, TextField } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { Edit } from "@mui/icons-material";
import NamesWidgets from "view/cms/components/forms/shared/NamesWidgets";
import Done from "view/cms/components/shared/Done";
import Error from "view/cms/components/shared/Error";
import Loading from "view/cms/components/shared/Loading";
import { CourseHttpService } from "services/course";
import TranslateController from "controller/shared/TranslateController";
import SelectStar from "../review/SelectStar";

function ReviewForm({ contentId, courseId, onEditDone }) {
    const { register, handleSubmit, setValue, watch, formState: { errors }, } = useForm();
    const [editingItem, setEditingItem] = useState(null);

    const [loading, setLoading] = useState(false);
    const [done, setDone] = useState(false);
    const [error, setError] = useState(false);

    const { _t } = TranslateController()

    useEffect(() => {
        setValue('star',5)
    },[contentId])


    const saveChanges = (data) => {

        setError(false)
        var body = {
            description: data.description,
            star: data.star ?? 3,
            course: courseId,
            content: contentId
        }

        // console.log(body)

        setDone(false)
        setLoading(true)
        CourseHttpService.saveReview(body).then(response => {
            setLoading(false)
            setDone(true)
            setError(false)
            onEditDone(response);
            setValue('description','')
        }).catch(err => {
            setLoading(false)
            // setError("Something went wrong")
        });
    };



    return (
        <div>


            <form className="" onSubmit={handleSubmit(saveChanges)}>
                <div className="form-group">
                    <SelectStar
                        initialRating={5}
                        onRatingChange={(value)=>{ setValue('star',value) }}
                    />
                </div>
                <div className="form-group kfont">
                    <TextField
                        fullWidth
                        label={_t("Review content")}
                        value={watch('description') ?? ""}
                        variant="outlined"
                        multiline={true}
                        InputLabelProps={{ shrink: true }}
                        {...register("description")}
                    />
                </div>
                <div className="form-group mt-3 text-right mb-1">
                    <Button
                        onClick={handleSubmit}
                        type="submit"
                        variant="contained"
                        startIcon={editingItem ? <Edit /> : <AddIcon />}
                    >
                        {_t("Submit Review")}
                    </Button>


                </div>
                <div className="form-group">
                    {done && <Done message={_t("Review saved. Thank you")} />}
                    {error && <Error message={error} />}
                    {loading && <Loading />}
                </div>
            </form>
        </div>
    );
}

export default ReviewForm;
