import React from 'react'
import LectureSyllabus from '../week/LectureSyllabus'
import TranslateController from 'controller/shared/TranslateController'

function CourseSyllabus({ course }) {
    const {_t} = TranslateController()

    return (
        <section id="syllabus" className="section section-no-border pt-3 pb-3 m-0 lan-rtl" >
            <div className="container ">
                <h2 className='mb-4 text-left lan-text-right kfont'>{_t("Syllabus")}</h2>
                {/* <p className='text-center mb-5'>Content Rating 98%(96,624 ratings)</p> */}
                {course?.lectures?.map((item,index) => (
                    <LectureSyllabus key={"w"+item.id} number={index+1} course={course} lecture={item} />
                ))}
            </div>
        </section>
    )
}

export default CourseSyllabus