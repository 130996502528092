import React from 'react'
import VideoList from './VideoList'

function VideoListAside() {
    return (
                <div className="tabs tabs-bottom tabs-simple bg-tabs mt-3 ">
                    <ul className="nav nav-tabs tabs-episodes">
                        <li className="nav-item ">
                            <a className="nav-link active" href="#episodes" data-toggle="tab">Episodes (9)</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#chapters" data-toggle="tab">Chapters</a>
                        </li>
                    </ul>
                    <div className="tab-content">
                        <div className="tab-pane active" id="episodes">
                            <VideoList /> 
                        </div>
                        <div className="tab-pane" id="chapters">
                                Chapters
                        </div>
                    </div>
                </div>

    )
}

export default VideoListAside