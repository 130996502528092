import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { Utils } from 'utils';
import { CourseHttpService } from 'services/course';

export default function SelectCourseCategory({ callback, value, width, placeholder, name="category" }) {

    const [SelectOptions, setSelectOptions] = useState([]);



    const customStyles = {
        control: (base, state) => ({
            ...base,

            background: "white",
            // match with the menu

            width: width ? width : "220px",
            // Overwrittes the different states of border
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                // Overwrittes the different states of border
            }
        }),
        menu: (base, state) => ({


            ...base,
            width: width ? width : "200px",
            '*::-webkit-scrollbar': {
                width: '0.4em'
            },
            '*::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
            },
            '*::-webkit-scrollbar-thumb': {
                backgroundColor: '#c7c7c7',
            },
            // override border radius to match the box
            borderRadius: 0,
            borderBottomRightRadius: 5,
            borderBottomLeftRadius: 5,
            // kill the gap
            marginTop: 0,
            backgroundColor: "#fff",
            opacity: "1"

        }),
        menuList: base => ({
            ...base,
            // kill the white space on first and last option
            padding: 10,

            "&:hover": {
                borderBottomRightRadius: 5,
                borderBottomLeftRadius: 5,
                transition: "all 0.5s ease-out"
            }
        }),
        option: (base, state) => {


            let backgroundColor = "transparent";

            if (state.isSelected) {
                backgroundColor = "#ccc";
            }

            if (state.isFocused) {
                backgroundColor = "#ccc";
            }
            if (state.isActive) {
                backgroundColor = "#ccc";
            }

            return {
                ...base,
                color: "#000",
                backgroundColor,
                transition: "all 0.5s ease-out"
            };
        }

    };

    const getChilds = (data, parentId, active_only) => {

        var childs = [];
        if (active_only)
            childs = data.filter(x => x.parent?.id === parentId && x.status);
        else
            childs = data.filter(x => x.parent?.id === parentId);
        childs.forEach(item => {
            item.childs = getChilds(data, item.id, active_only)
        })
        return childs;
    
    }


    const BuildCategories = (categories, active_only) => {

        if (categories != null) {
            var parents = [];

            if (active_only)
                parents = categories.filter(x => !x.parent && x.status);
            else
                parents = categories.filter(x => !x.parent);

            parents.forEach(parent => {
                parent.childs = getChilds(categories, parent.id, active_only)
            })

            return parents;
        }

    }


    useEffect(() => {
        CourseHttpService.loadCourseCategories().then(response => {
            if (response.detail) return;

            let categories = response.results;

            if (categories) {
                var built_categories = BuildCategories(categories)
                var temp = []
                var temp_options = []

                built_categories.map((item, i) => {
                    //var t = userTalents.find(x => x.talent.id === item.id)

                    temp.push(item)

                    var option = null;


                    option = {
                        label:
                            Utils.getDefaultName(item),
                        value: item.id
                    }

                    temp_options.push(option)

                    // item.childs.map((child, index) => {
                    //     temp_options.push({
                    //         label:
                    //             "   » " + Utils.getDefaultName(child)
                    //         , value: child.id
                    //     })
                    // })



                })
                //
                setSelectOptions(temp_options);
                // callback()
                //setTalents(temp)
            }

        })
    }, [])

    const handleSelect = (e) => {

        callback(e && e.value, name)


    }

    return (
        <>
            <div className="filter-select kfont">
                <Select
                    options={SelectOptions}
                    styles={customStyles}
                    onChange={handleSelect}
                    placeholder={placeholder ? placeholder : "Parent"}
                    isClearable={true}
                    value={SelectOptions && SelectOptions.filter(obj => obj.value == value)}


                />
            </div>

        </>
    )
}
