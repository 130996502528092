import React, { useEffect, useRef, useState } from "react";

import { useForm } from "react-hook-form";
import { Button, TextField } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { CourseHttpService } from "../../../../../services/course";
import { Edit } from "@mui/icons-material";
import Done from "view/cms/components/shared/Done";
import Error from "view/cms/components/shared/Error";
import Loading from "view/cms/components/shared/Loading";
import ImageFieldWithGallery from "view/cms/components/Widgets/shared/ImageFieldWithGallery";
import NamesWidgets from "view/cms/components/forms/shared/NamesWidgets";
import SelectLevel from "view/cms/components/forms/course/selects/SelectLevel";
import SelectScale from "./SelectScale";
import SelectTimeSignature from "./SelectTimeSignature";
import SelectPeopleMulti from "../../feature_blog/components/forms/SelectPeopleMulti";
import CustomModal from "view/components/modals/CustomModal";
import UploadToAmazon from "view/cms/components/Widgets/shared/UploadToAmazon";
import FileFieldWithGallery from "view/cms/components/Widgets/shared/FileFieldWithGallery";
import MusicFileFieldWithGallery from "view/cms/components/Widgets/shared/MusicFileFieldWithGallery";

function NotationForm({ id, ...props }) {
  const { register, handleSubmit, setValue, watch, formState: { errors }, } = useForm();
  const [editingItem, setEditingItem] = useState(null);

  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [namesRefresh, setNamesRefresh] = useState(0);
  const [course_image, setCourseImage] = useState(null);
  const [names, setNames] = useState([]);
  const [uploadModalIsOpen, setUploadModalIsOpen] = useState(false);
  const [music_url, setMusicUrl] = useState(false);
  

  useEffect(() => {
    if (id) {
      CourseHttpService.loadNotation(id).then(item => {
        onEditing(item)
      })
    }
    else {
      // setValue("music_url", "https://d2avkh8hsb0jpt.cloudfront.net/notation/")
    }
  }, [])

  const onEditing = (item) => {
    setEditingItem(item)

    for (const key in item) {
      setValue(key, item[key])
    }

    if (item.music_url) {
      setMusicUrl(item.music_url)
    }


    if (item.artists) {
      let artistIds = item.artists.map(artist => artist.id)
      setValue("artists", artistIds)
    }

    if (item.transcribers) {
      let transcriberIds = item.transcribers.map(transcriber => transcriber.id)
      setValue("transcribers", transcriberIds)
    }

    if (item.category) {
      setValue("category", item.category?.id)
    }


    if (item.level) {
      setValue("level", item.level?.id)
    }

    if (item.level) {
      setValue("scale", item.scale?.id)
    }

    if (item.level) {
      setValue("time_signature", item.time_signature?.id)
    }

    setNames(item.names)
    setNamesRefresh(namesRefresh + 1)
    setCourseImage(item.image)

  }

  const saveChanges = (data) => {

    setError(false)
    if (editingItem) data.id = editingItem.id;



    var body = {
      "id": id ? id : data.id,
      names: names,
      "level": data.level,
      "category": data.category,
      "music_url": data.music_url,
      "scale": data.scale,
      "time_signature": data.time_signature,
      "artists": data.artists,
      "transcribers": data.transcribers,
      // "image": course_image
    }

    // console.log(body)

    setDone(false)
    setLoading(true)
    CourseHttpService.saveNotation(body).then(response => {
      setLoading(false)
      setDone(true)
      setError(false)
      if (props.onEditDone) {
        props.onEditDone(response);
      }
    }).catch(err => {
      setLoading(false)
      // setError("Something went wrong")
    });
  };


  const handleValue = (value, name) => {
    switch (name) {

      case "category":
        setValue("category", value)
        break;
      case "level":
        setValue("level", value)
        break;
      case "scale":
        setValue("scale", value)
        break;
      case "time_signature":
        setValue("time_signature", value)
        break;
      case "artists":
        setValue("artists", value)
        break;
      case "transcribers":
        setValue("transcribers", value)
        break;
      default:
        break;
    }
  }



  return (
    <div>


      <form className="" onSubmit={handleSubmit(saveChanges)}>
        <div className="row">
          <div className="col-md-8">
            <div className="form-group">
              <NamesWidgets show_short_description={0} show_description={0} key={namesRefresh + "nms"} names={names} setNames={(names) => { setNames(names) }} />
            </div>
            
            <div className="col-md-12">
              <div className="form-group">
                <MusicFileFieldWithGallery key={music_url+"musicurl"}  defaultUrl={watch('music_url')} onDocumentSelected={(url)=> { setValue('music_url', url) }} />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            {/* <div className="form-group">
              <ImageFieldWithGallery type="notation" onImageSelected={setCourseImage} defaultImageUrl={course_image} key={course_image + "imgg"} />
            </div> */}
            {/* <div className="form-group">
              <SelectCategory width={"100%"}  name="category" placeholder="Categoty"  callback={handleValue} value={watch("category")} border={"1"} />
            </div> */}
            <div className="form-group">
              <SelectLevel width={"100%"} callback={handleValue} value={watch("level")} border={"1"} />
            </div>
            <div className="form-group">
              <SelectScale width={"100%"} callback={handleValue} value={watch("scale")} border={"1"} />
            </div>
            <div className="form-group">
              <SelectTimeSignature width={"100%"} callback={handleValue} value={watch("time_signature")} border={"1"} />
            </div>
            <div className="form-group">
              <SelectPeopleMulti type="artist" name="artists" width={"100%"} placeholder="Artists" callback={handleValue} value={watch("artists")} border={"1"} />
            </div>
            <div className="form-group">
              <SelectPeopleMulti type="transcriber" name="transcribers" width={"100%"} placeholder="Transcribers" callback={handleValue} value={watch("transcribers")} border={"1"} />
            </div>
          </div>
        </div>
        <div className="form-group mt-3 text-right mb-1">
          <Button
            onClick={handleSubmit}
            type="submit"
            variant="contained"
            startIcon={editingItem ? <Edit /> : <AddIcon />}
          >
            {editingItem ? "Save Changes" : "Add Notation"}
          </Button>


        </div>
        <div className="form-group">
          {done && <Done />}
          {error && <Error message={error} />}
          {loading && <Loading />}
        </div>
      </form>

      <CustomModal className="" big key={uploadModalIsOpen} setOpen={(value) => setUploadModalIsOpen(value)}
        open={uploadModalIsOpen} >

        <UploadToAmazon
          uploadFolder={`notations`}
          onEditDone={(url) => {
            setValue('music_url', url)
            setTimeout(() => {
              setUploadModalIsOpen(false)
            }, 1000)
          }}
        />

      </CustomModal>
    </div>
  );
}

export default NotationForm;
