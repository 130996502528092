import React, { useState } from 'react'
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { CourseHttpService } from '../../../../services/course';
import LessonsLightAccordion from '../lesson/LessonsLightAccordion';
import { Utils } from 'utils';
import TranslateController from 'controller/shared/TranslateController';

function ContentViewAside({ course }) {

    const [lectures, setLectures] = useState(null)

    const {_t} = TranslateController()

    const { isLoading, error, data } = useQuery('lecture_by_course', () => { return CourseHttpService.loadLectures(course) }, {
        onSuccess: (data) => {
            if (data.results) {
                setLectures(data.results)
            }
            // Utils.initiateTheme()
        }
    });
    return (
        <div className='mt-4 mt-sm-0 course-summary-aside'>
            <div className='mr-3'>
                {lectures && lectures.map((lecture,index) => (
                    <div key={"lecture_"+lecture.id}>
                        {/* <div className='aside-lecture-title kfont'> {_t("Lecture")}&nbsp;{ index+1 }</div> */}
                        <LessonsLightAccordion isOpen={index===0} key={`lessons_accordion_light_${lecture.id}`} lecture_index={index} lecture={lecture.id} course={course} />
                    
                    </div>
                ))}
                
            </div>
        </div>

    )
}

export default ContentViewAside