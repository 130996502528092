import React from 'react'
import { Utils } from '../../../../utils'
import InstructorItem from './InstructorItem'
import TranslateController from 'controller/shared/TranslateController'

function CourseInstructors({ course }) {

    const { _t } = TranslateController()

    return (
        <section id="instructors" className="section section-no-border pt-1 pb-1 m-0" >
            <div className="container ">
                {/* <h2 className='mb-4 font-weight-bold'>{_t("Instructors")}</h2> */}
                <div className="instructors">
                    {course?.instructors.map((instructor) => (
                        <InstructorItem instructor={instructor} key={`instructor ${instructor.id}`} />
                    )
                    )}

                </div>
            </div>
        </section>
    )
}

export default CourseInstructors