import TranslateController from 'controller/shared/TranslateController';
import React, { Suspense, lazy, useState } from 'react'

const CourseGridViewItem = lazy(() => import('./CourseGridViewItem'));
const CourseCard = lazy(() => import('./CourseCard'));


function CoursesGridView({ isLoading, error, data }) {
    const { _t } = TranslateController()
    const [showMore, setShowMore] = useState(false)

    const _toggleShowMore = () => {
        setShowMore(!showMore)
    }

    return (
        <div className=''>
            <div className="row py-3 ">
                {data?.results?.map((course, i) => (!showMore && i < 4) || (showMore) ? (
                    <div className="col-sm-8 col-md-4 mb-3 mb-md-0 " data-appear-animation="fadeIn" data-appear-animation-delay={i * 250} key={course.id}>
                        <Suspense fallback={<div>Loading...</div>}>
                            {/* <CourseGridViewItem course={course} /> */}
                            <CourseCard course={course} showDescription={false} />
                        </Suspense>
                    </div>
                ) : "")}

            </div>
            <div className="row py-3 ">
                {data?.count > 3 && <>
                    <div className="p-3">
                        <button onClick={_toggleShowMore} className="btn btn-primary lan-kfont" >{_t(!showMore ? _t('Show More') : _t('Show Less') )} </button>
                    </div>
                </>}
            </div>


        </div>
    )
}

export default CoursesGridView