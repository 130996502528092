import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { CourseHttpService } from "services/course";
import { UserHttpService } from "services/user";

function ProfileFormController() {
  const { register, handleSubmit, setValue, watch, formState: { errors }, } = useForm();
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [names, setNames] = useState([]);
  const fileRef = useRef();

  // const { isLoading, error_q, data } = useQuery('profile', UserHttpService.loadProfile, {
  //   onSuccess: (profile) => {
  //     console.log(profile)
  //     onEditing(profile);
  //   }
  // });

  useEffect(() => {
    UserHttpService.loadProfile().then(profile => {
      onEditing(profile);
    });
  }, []);

  const onEditing = (item) => {
    setProfile(item);

    for (const key in item) {
      setValue(key, item[key]);
    }

    setNames(item.names);
    setRefresh(refresh + 1);
  };

  const saveChanges = (data) => {
    setError(false);
    if (profile) data.id = profile.id;

    if (!data.first_name || !data.last_name) {
      setError("Please provide first name and last name");
      return;
    }

    var body = {
      id: profile ? profile.id : null,
      first_name: data.first_name,
      last_name: data.last_name,
      gender: data.gender,
      birth_date: data.birth_date,
      country_id: data.country_id,
      city_id: data.city_id,
      livingcountry_id: data.livingcountry_id,
      livingcity_id: data.livingcity_id,
      // phone_number: data.phone_number,
      // website: data.website ? data.website : "",
      // facebook: data.facebook ? data.facebook : "",
      // instagram: data.instagram ? data.instagram : "",
      // youtube: data.youtube ? data.youtube : "",
      // snapchat: data.snapchat ? data.snapchat : "",
      // spotify: data.spotify ? data.spotify : "",
    };

    setDone(false);
    setLoading(true);
    UserHttpService.saveProfile(body)
      .then(response => {
        setLoading(false);
        setDone(true);
        setError(false);
        // props.onEditDone(response);
      })
      .catch(err => {
        setLoading(false);
        // setError("Something went wrong")
      });
  };

  return {
    profile,
    handleSubmit,
    saveChanges,
    watch,
    register,
    setValue,
    names,
    setNames,
    refresh,
    done,
    error,
    loading
  };
}

export default ProfileFormController;
