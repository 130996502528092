import TranslateController from 'controller/shared/TranslateController';
import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { CourseHttpService } from 'services/course';
import { HttpService } from 'services/http';
import { Utils } from 'utils';

function FilterByLevelsWidget({ onSelect }) {
  const [levels, setLevels] = useState(null);
  const [selectedCategoryIds, setSelectedIds] = useState([]);

  const { _t } = TranslateController();
  const { isLoading, error, data, refetch } = useQuery('levels', () => {
    return CourseHttpService.loadLevels();
  }, {
    onSuccess: (data) => {
      setLevels(data.results);
    },
    refetchInterval: HttpService.DefaultRefetchInterval,
  });


  const handleCheckboxChange = (levelId, isChecked) => {
    setSelectedIds(prevSelectedIds => {
      var selectedIds = prevSelectedIds;
      if (isChecked) {
        // Add the ID if it's not already in the array
        if (!prevSelectedIds.includes(levelId)) {
          selectedIds = [...prevSelectedIds, levelId];
          onSelect(selectedIds)
        }
      } else {
        selectedIds = prevSelectedIds.filter(id => id !== levelId);
        onSelect(selectedIds)
        
      }

      return selectedIds;
    });
  };

  return (
    <div className='mb-4'>
      <h4>{_t("Level")}</h4>
      <div>
        {levels?.map(level => (
          <div key={level.id}>
            <div className={`custom-checkbox-1 lan-rtl`}>
              <input 
                id={`levelCheckbox` + level.id} 
                type="checkbox" 
                name={`levelCheckbox` + level.id} 
                value="1" 
                onChange={(e) => handleCheckboxChange(level.id, e.target.checked)}
              />
              <label htmlFor={`levelCheckbox` + level.id}>{Utils.getDefaultName(level)}</label>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FilterByLevelsWidget;
