import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { CourseHttpService } from 'services/course';
import { useQuery } from 'react-query';
import { HttpService } from 'services/http';
import { Utils } from 'utils';
import UserStatisticsChart from './UserStatisticsChart';
import TopProgressedUsersWidget from './TopProgressedUsersWidget';
import GenderStatisticsChart from './GenderStatisticsChart';
import NewestEnrolledUsersWidget from './NewestEnrolledUsersWidget';
import { useNavigate } from 'react-router-dom';
import TopOnlineUsersWidget from './TopOnlineUsersWidget';
import CMSUserTabs from './CMSUserTabs';

// Register the necessary Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
var courseIds

const ProgressByCourseChart = () => {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [],
    });

    const navigate = useNavigate(); // Initialize the useNavigate hook



    const { isLoading, error, data, refetch } = useQuery('course-statistics', () => {
        return CourseHttpService.loadCourseStatistics();
    }, {
        onSuccess: (data) => {
            const courseNames = data.map((item) => Utils.getDefaultName(item.course));
            courseIds = data.map((item) => item.course.id);
            const enrollmentCounts = data.map((item) => item.enrollment_count);
            const completionCounts = data.map((item) => item.completion_count);
            const averageProgress = data.map((item) => item.average_progress);

            setChartData({
                labels: courseNames,
                datasets: [
                    {
                        label: 'Enrollment Count',
                        data: enrollmentCounts,
                        backgroundColor: 'rgba(54, 162, 235, 0.6)',
                    },
                    {
                        label: 'Completion Count',
                        data: completionCounts,
                        backgroundColor: 'rgba(255, 99, 132, 0.6)',
                    },
                    {
                        label: 'Average Progress',
                        data: averageProgress,
                        backgroundColor: 'rgba(255, 206, 86, 0.6)',
                    },
                ],
            });

        },
        refetchInterval: HttpService.DefaultRefetchInterval,
    });

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error loading data</div>;
    }

    return (
        <section className="">
            <div className="row">
            <div className="col-md-4">
                <div className="mb-3">
                    <CMSUserTabs />
                </div>
                </div>
                <div className="col-md-8">
                    <div className="row">
                        <div className="col-md-12">
                            <Bar data={chartData} options={{
                            responsive: true,
                            plugins: {
                                legend: {
                                    display: false,
                                    position: 'bottom',
                                    labels: {
                                        padding: 30,
                                    }
                                },
                            },
                            onClick: (event, elements) => {
                                if (elements.length > 0) {
                                  const index = elements[0].index; // Get the index of the clicked bar
                                  const courseId = courseIds[index]; // Get the corresponding course ID
                                  navigate(`/cms/course/${courseId}/enrolled`); // Use navigate to redirect
                                }
                              }
                        }} />
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-6">
                            <GenderStatisticsChart />
                        </div>
                        <div className="col-md-6">
                            <UserStatisticsChart />
                        </div>
                    </div>
                </div>
                
            </div>
        </section>
    );
};

export default ProgressByCourseChart;
