import React from 'react'
import SupportMessagesList from 'view/cms/components/lists/SupportMessagesList'
import CmsHeader from 'view/cms/components/shared/CmsHeader'

function SupportMessagesPage() {
    return (
        <div className="body">
            <CmsHeader />

            <div role="main" className="main">
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-lg-12">
                            <SupportMessagesList />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SupportMessagesPage