import React, { useContext, useEffect, useState } from 'react'
import CoursesGrid from '../../components/course/components/CoursesGrid'
import PageLayout from 'view/components/shared/PageLayout'
import { LanguageContext } from 'contexts/LanguageContext'
import TranslateController from 'controller/shared/TranslateController'


const libraryItems = [
	{
		name: 'Courses',
		image: '/assets/images/library/courses.jpg',
		url: '/courses/'
	},
	{
		name: 'Articles',
		image: '/assets/images/library/articles.jpg',
		url: '/articles/'
	},
	// {
	// 	name: 'Publications',
	// 	image: '/assets/images/library/publications.jpg',
	// 	url: '/publications/'
	// },
	// {
	// 	name: 'Books',
	// 	image: '/assets/images/library/books.jpg',
	// 	url: '/books/'
	// },
	{
		name: 'Podcasts',
		image: '/assets/images/library/podcasts.jpg',
		url: '/podcasts/'
	},
	{
		name: 'Notations',
		image: '/assets/images/library/notations.jpg',
		url: '/notations/'
	},
	{
		name: 'Dictionary',
		image: '/assets/images/library/dictionary.jpg',
		url: '/dictionary/'
	}
	// Add more items as needed
];


function Library() {

	const [query, setQuery] = useState("")

	useEffect(() => {
		// Utils.initiateTheme()
	}, [])

	const filteredItems = libraryItems.filter(item => 
        item.name.toLowerCase().includes(query.toLowerCase())
    )


	return (
		<PageLayout showbreadcrumbs={false} HeaderChild={<HeaderChild query={query} setSearchQuery={setQuery} />}>
			<div className="row py-3 ">
				{filteredItems.map((item, index) => (
                    <div className="col-sm-6 col-md-4 mb-4 mb-md-0 " data-appear-animation="fadeIn" data-appear-animation-delay={index * 250} key={index}>
						<LibraryItem key={index} item={item} />
                    </div>
                ))}
            </div>
		</PageLayout>


	)
}

function LibraryItem({ item }) {
	const { _t } = TranslateController();

	return (
		<div className='mb-4'>
			<span className="thumb-info thumb-info-swap-content anim-hover-inner-wrapper">
				<span className="thumb-info-wrapper overlay overlay-show overlay-gradient-bottom-content">
					<img src={item.image} className="img-fluid" alt={_t(item.name)} />
					<span className="thumb-info-title thumb-info-title-vert-align bg-transparent w-100 text-center mw-100 p-0">
						<span className="anim-hover-inner-translate-top-40px transition-2ms d-inline-block">
							<span className="thumb-info-inner text-4 kfont">{_t(item.name)}</span>
						</span>

					</span>


					<span className="thumb-info-title bottom-30 bg-transparent w-100 mw-100 p-0 text-center">
						<span className="thumb-info-swap-content-wrapper">
							<span className="thumb-info-inner">
								<a href={`${item.url}`} className="btn btn-primary btn-circle"><i className="fas fa-arrow-right"></i></a>
							</span>
							<span className="thumb-info-inner text-2 lan-rtl">
								{/* {showDescription && (
                                        <div className="px-5 text-2 opacity-7 font-weight-medium text-light mb-2  kfont line-height-15">{Utils.parseShortDescription(course)}</div>
                                    )} */}
								<a href={`${item.url}`} className="btn btn-modern btn-primary btn-arrow-effect-1 py-2 px-3  kfont">{_t("View")} <i className="fas fa-arrow-right ms-2"></i></a>
							</span>
						</span>
					</span>
				</span>
			</span>
		</div>
	);
}


function HeaderChild({ setSearchQuery }) {
	const { _t } = TranslateController();

	const [query, setQuery] = useState("")


	return (
		<>
			<h1 className="text-color-light pt-3 pb-2 font-weight-bold text-10 text-sm-8">{_t("Academy")}</h1>
			<form action="" onSubmit={(e) => { e.preventDefault(); setSearchQuery(query) }}>
				<div className="search-container">
					<div className="simple-search input-group mt-2 mb-4">
						<input className="form-control text-1 text-light" value={query} name="q" onChange={(e) => { setQuery(e.target.value) }} type="search" placeholder="Search..." />
						<button className="btn" type="submit" aria-label="Search">
							<i className="fas fa-search header-nav-top-icon"></i>
						</button>
					</div>
				</div>
			</form>
		</>

	)
}

export default Library