import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { CourseHttpService } from '../../../services/course'
import { useState } from 'react'

function QuizViewController() {
  const { course, quiz } = useParams()

  const [assignmentDialogIsOpen,setAssignmentDialogIsOpen] = useState(false)
  const [score,setScore] = useState("-")
  const [courseData,setCourse] = useState(null)


  const { isLoading, error, data } = useQuery(['quiz', quiz], () => {
    return CourseHttpService.loadQuiz(quiz)
  }, {
    onSuccess: (data) => {
      const { quiz_result} = data
      if(quiz_result){
        setScore(quiz_result.grade)
      }
    }
  })

  const { isLoading: isLoadingCourse, error: errorCourse, refetch: refetchCourse, data: courseInfo } = useQuery(['course', course], () => {
		return CourseHttpService.loadCourse(course)
	  }, {
		onSuccess: (data) => {
		  setCourse(data)
		},
		refetchInterval: 600*1000,

	  });

  return { data, isLoading, error,assignmentDialogIsOpen,setAssignmentDialogIsOpen,score,setScore, courseData }
}

export default QuizViewController