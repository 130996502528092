import React from 'react';

const VideoTracks = ({ tracks }) => {

  return (
    <>
      {tracks && tracks.map((track, index) => (
        <track
          key={index+"tracks"}
          label={track.label}
          kind={"subtitles"}
          srcLang={track.srcLang}
          src={track.src}
          default={track.is_default}
        />
      ))}
    </>
  );
};

export default VideoTracks;
