import React, { useState } from 'react'
import { useQuery } from 'react-query';
import { CourseHttpService } from 'services/course';
import { Utils } from 'utils'

function ContentTracksSummary({ content, onTranscriptClicked, progress = -1 }) {

  const [tracks, setData] = useState(null);

  const { isLoading, error, data, refetch } = useQuery(['tracks_content', content.id], () => { return CourseHttpService.loadTracks({ content: content.id }) }, {
    onSuccess: async (data) => {

      // console.log(data);

      if (data.results && data.results.length > 0) {
        try {
          // Fetch the VTT file content from the URL
          const response = await fetch(data.results[0].src);
          const vttText = await response.text();

          console.log(vttText);
          
          // Parse the VTT file content
          const vttData = parseVTTFile(vttText);
          setData(vttData);
        } catch (err) {
          console.error("Error fetching or parsing VTT file:", err);
        }
      }

    },
    refetchInterval: 60 * 100000,
  });



  function parseVTTFile(vttText) {
    const lines = vttText.split('\n');
    const vttArrayParsed = [];
    let currentItem = null;

    lines.forEach(line => {
        const timeMatch = line.match(/(\d{2}:\d{2}\.\d{3}) --> (\d{2}:\d{2}\.\d{3})/);
        if (timeMatch) {
            // If a timing line is found, create a new item with start and end times
            const startTime = timeMatch[1];
            const endTime = timeMatch[2];
            currentItem = { startTime, endTime, text: '' };
            vttArrayParsed.push(currentItem);
        } else if (currentItem && line.trim() !== '') {
            // Add the text to the current item if it is part of a subtitle block
            currentItem.text += line.trim() + ' ';
        }
    });

    return vttArrayParsed;
}




  return (
    <div>
      {tracks?.map((lyric, index) => {

        let transcriptToShowIndex = null;
        for (let i = tracks.length - 1; i >= 0; i--) {
            if (Utils.isTrackVisible(tracks[i], progress)) {
                transcriptToShowIndex =i;
                break;
            }
        }

        return (
          <div key={index + "lrc"} className={`transcript-paragraph ${(lyric && transcriptToShowIndex==index) ? 'active' : ""}`} onClick={() => { onTranscriptClicked(lyric) }}>
            {lyric.text}
          </div>
        )
      }
      )}
    </div>
  )
}

export default ContentTracksSummary