import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { Utils } from 'utils';
import { BaseHttpService } from 'services/base';
import TranslateController from 'controller/shared/TranslateController';

export default function SelectCountry({ onValue, value, width, placeholder, name = "country" }) {

    const [SelectOptions, setSelectOptions] = useState([]);
    const [countries, setCountries] = useState([]);

    const { _t } = TranslateController()


    const customStyles = {
        control: (base, state) => ({
            ...base,

            background: "white",
            padding: 8,
            // match with the menu
            width: width ? width : "200px",
            // Overwrittes the different states of border
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                // Overwrittes the different states of border
            }
        }),
        menu: base => ({
            ...base,
            width:"200px",
            '*::-webkit-scrollbar': {
                width: '0.4em'
            },
            '*::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
            },
            '*::-webkit-scrollbar-thumb': {
                backgroundColor: '#c7c7c7',
            },
            // override border radius to match the box
            borderRadius: 0,
            
            borderBottomRightRadius: 5,
            borderBottomLeftRadius: 5,
            // kill the gap
            marginTop: 0,
            backgroundColor: "#fff",
            opacity: "0.97"

        }),
        menuList: base => ({
            ...base,
            // kill the white space on first and last option
            padding: 10,
            "&:hover": {
                borderBottomRightRadius: 5,
                borderBottomLeftRadius: 5,
                transition: "all 0.5s ease-out"
            }
        }),
        option: (base, state) => {


            let backgroundColor = "transparent";

            if (state.isSelected) {
                backgroundColor = "#ccc";
            }

            if (state.isFocused) {
                backgroundColor = "#ccc";
            }
            if (state.isActive) {
                backgroundColor = "#ccc";
            }

            return {
                ...base,
                color: "#000",
                backgroundColor,
                transition: "all 0.5s ease-out"
            };
        }

    };




    useEffect(() => {
        BaseHttpService.loadCountries().then(data => {
            setCountries(data.results);
            let countries = data.results
            if (countries) {
                var temp_options = []
                countries.map((item, i) => {
                    var option = null;
                    option = {
                        label: Utils.getDefaultName(item),
                        value: item.id
                    }
                    temp_options.push(option)
                })
                //
                setSelectOptions(temp_options);
                // callback()
                //setTalents(temp)
            }
        })
    }, [])

    const handleSelect = (e) => {

        onValue(e && e.value, name)


    }

    return (
        <>
            <div className="filter-select kfont">
                <Select
                    options={SelectOptions}
                    styles={customStyles}
                    onChange={handleSelect}
                    placeholder={placeholder ? _t(placeholder) : _t("Country")}
                    isClearable={true}
                    value={SelectOptions && SelectOptions.filter(obj => obj.value == value)}


                />
            </div>

        </>
    )
}
