import React, { useEffect, useRef, useState } from "react";

import { useForm } from "react-hook-form";
import { Button, TextField } from "@mui/material";
import { Edit, SaveOutlined } from "@mui/icons-material";
import ProfileFormController from "controller/user/ProfileFormController";
import Done from "../../shared/Done";
import Error from "../../shared/Error";
import Loading from "../../shared/Loading";
import ProfileImageWidget from "../../Widgets/user/ProfileImageWidget";
import SelectGender from "./select/SelectGender";
import CustomDatePicker from "../shared/DatePickerWidget";
import SelectCountry from "view/cms/features/feature_user/components/SelectCountry";
import SelectCity from "view/cms/features/feature_user/components/SelectCity";
import TranslateController from "controller/shared/TranslateController";
import { Utils } from "utils";

function ProfileForm() {
  const {
    handleSubmit,
    saveChanges,
    watch,
    register,
    setValue,
    done,
    error,
    loading,
    profile
  } = ProfileFormController()

  const { _t } = TranslateController()


  const handleValue = (value, name) => {
    switch (name) {

      case "country_id":
        setValue("country_id", value)
        break;
      case "city_id":
        setValue("city_id", value)
        break;
      case "livingcountry_id":
        setValue("livingcountry_id", value)
        break;
      case "livingcity_id":
        setValue("livingcity_id", value)
        break;
        
      default:
        break;
    }
  }



  return (
    <div>
      {/* {watch('email')} */}
      <form className="" onSubmit={handleSubmit(saveChanges)}>
        <div className="row mb-5">
          <div className="col-md-12">
            <ProfileImageWidget profile={profile} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <TextField
                fullWidth
                label={_t("First Name")}
                value={watch('first_name')}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                {...register("first_name", { required: true })}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <TextField
                fullWidth
                label={_t("Last Name")}
                value={watch('last_name')}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                {...register("last_name", { required: true })}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <SelectGender key={watch('gender')} value={watch('gender')} onSelect={(value) => { setValue("gender", value) }} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <CustomDatePicker label="Date of birth" value={watch('birth_date')} onChange={(value) => { setValue("birth_date", value) }} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group ">
              <SelectCountry width={"100%"} placeholder={'Birth Country'} onValue={handleValue} value={watch("country_id")} name="country_id" />
            </div>

          </div>
          <div className="col-md-6">
            <div className="form-group ">
              <SelectCity width={"100%"} placeholder={'Birth City'} key={watch("country_id")} countryId={watch("country_id")} name="city_id" value={watch("city_id")} onValue={handleValue} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group ">
              <SelectCountry width={"100%"} placeholder={'Living Country'} onValue={handleValue} value={watch("livingcountry_id")} name="livingcountry_id" />
            </div>

          </div>
          <div className="col-md-6">
            <div className="form-group ">
              <SelectCity width={"100%"} placeholder={'Living City'} key={watch("livingcountry_id")} countryId={watch("livingcountry_id")} name="livingcity_id" value={watch("livingcity_id")} onValue={handleValue} />
            </div>
          </div>
        </div>

        <div className="form-group mt-3 text-right mb-1">
          <Button
            onClick={handleSubmit}
            type="submit"
            variant="contained"
            startIcon={<SaveOutlined />}
          >
            {_t("Save Changes")}
          </Button>


        </div>
        <div className="form-group">
          {done && <Done />}
          {error && <Error message={error} />}
          {loading && <Loading />}
        </div>

        <div className="registered-date">
        {_t("Registered")} : {profile && Utils.getDate(profile?.created_at)}
				</div>
      </form>
    </div>
  );
}

export default ProfileForm;
