import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import TranslateController from "controller/shared/TranslateController";
import React, { useEffect, useState } from "react";

const sizes = [25,50,100,500];

const SelectPageSize = ({callback , value=25}) => {
  const [size, setSize] = useState(value);

  useEffect(() => {
    setSize(value);
  }, [value]);

  const handleSelect = (event) => {
    const selectedValue = event.target.value;
    setSize(selectedValue);
    callback(selectedValue,"size");
  };

  const { _t } = TranslateController()



  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">{_t("Size")}</InputLabel>
      <Select
        label={_t("Page Size")}
        fullWidth
        className="full-width p-0"
        variant="outlined"
        inputlabelprops={{ shrink: true }}
        value={size}
        onChange={handleSelect}
      >
        {sizes.map((size) => (
          <MenuItem key={size+"sk"} value={size}>
            {size}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectPageSize;