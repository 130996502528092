import React, { useState } from 'react';
import TopOnlineUsersWidget from './TopOnlineUsersWidget';
import TopProgressedUsersWidget from './TopProgressedUsersWidget';
import NewestEnrolledUsersWidget from './NewestEnrolledUsersWidget';
import ProgressTotalTable from './ProgressTotalTable';

const CMSUserTabs = () => {
    const [activeTab, setActiveTab] = useState('online');
    const [onlineCount, setOnlineCount] = useState(null);
    
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className='tabs tabs-top'>
            {/* Tab navigation */}
            <ul className="nav nav-tabs nav-tabs-custom" id="userTabs" role="tablist">
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link ${activeTab === 'online' ? 'active' : ''}`}
                        onClick={() => handleTabClick('online')}
                        type="button"
                        role="tab"
                    >
                        Online
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link ${activeTab === 'new' ? 'active' : ''}`}
                        onClick={() => handleTabClick('new')}
                        type="button"
                        role="tab"
                    >
                        New
                    </button>
                </li>
                
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link ${activeTab === 'progress' ? 'active' : ''}`}
                        onClick={() => handleTabClick('progress')}
                        type="button"
                        role="tab"
                    >
                        Top
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link ${activeTab === 'total' ? 'active' : ''}`}
                        onClick={() => handleTabClick('total')}
                        type="button"
                        role="tab"
                    >
                        Total
                    </button>
                </li>
                
            </ul>

            {/* Tab content */}
            <div className="tab-content mt-3">
                {activeTab === 'online' && (
                    <div className="tab-pane fade show active" role="tabpanel">
                        <TopOnlineUsersWidget  />
                    </div>
                )}
                {activeTab === 'new' && (
                    <div className="tab-pane fade show active" role="tabpanel">
                        <NewestEnrolledUsersWidget />
                    </div>
                )}
               
                {activeTab === 'progress' && (
                    <div className="tab-pane fade show active" role="tabpanel">
                        <TopProgressedUsersWidget />
                    </div>
                )}

                {activeTab === 'total' && (
                    <div className="tab-pane fade show active" role="tabpanel">
                        <ProgressTotalTable />
                    </div>
                )}
                
            </div>
        </div>
    );
};

export default CMSUserTabs;
