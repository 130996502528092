import { useContext } from 'react'
import {  translate } from 'utils'
import { LanguageContext } from 'contexts/LanguageContext'

function TranslateController() {

    const { translations } = useContext(LanguageContext);
    const _t = (key) => {
        return translate(key, translations)
    }

  return { _t }
}

export default TranslateController