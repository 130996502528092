import React from 'react'
import { Utils } from '../../../../utils'
import TranslateController from 'controller/shared/TranslateController'

function CourseAbout({ course }) {

    const {_t} = TranslateController()
    return (
        <section id="about" className="section section-no-border pt-1 pb-1 m-0 lan-rtl" >
            <div className="container ">
                <h2 className='mb-2 kfont'>{_t("About this course")}</h2>
                {/* <p className="font-weight-bold opacity-8 pb-2 mb-4">
                    20,233 recent views
                </p> */}

                <div className="font-weight-light text-dark text-justify pb-1 mb-1 p-mb-1 p">
                    {Utils.parseDescription(course)}

                </div>

            </div>
        </section>
    )
}

export default CourseAbout