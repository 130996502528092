import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, TextField, TextareaAutosize } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { Edit, MessageOutlined } from "@mui/icons-material";
import Done from "view/cms/components/shared/Done";
import Error from "view/cms/components/shared/Error";
import LoadingInside from "view/cms/components/shared/LoadingInside";
import { BaseHttpService } from "services/base";
import TranslateController from "controller/shared/TranslateController";

function SupportMessageReplyForm({ supportMessageId, ...props }) {
  const { register, handleSubmit, setValue, watch, formState: { errors }, } = useForm();
  const [editingItem, setEditingItem] = useState(null);

  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [data, setData] = useState(null);
  const {_t} = TranslateController();


  const saveChanges = (data) => {

    setError(false)
    if (editingItem) data.id = editingItem.id;

    var body = {
      "message": data.message,
    }

    setDone(false)
    setLoading(true)
    BaseHttpService.saveSupportMessageReply(supportMessageId,body).then(response => {
      setLoading(false)
      setDone(true)
      setError(false)
      setValue("message","")
      if (props.onDone) {
        props.onDone(response);
      }
    }).catch(err => {
      setLoading(false)
      // setError("Something went wrong")
    });
  };

  return (
    <div>
      {/* <h2 className="font-weight-bold text-color-dark text-6 mb-3">Reply to this message</h2> */}
      <form className="mt-4" onSubmit={handleSubmit(saveChanges)}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group col mb-3-5">
              <textarea
                value={watch('message') ?? ""}
                {...register("message")}
                maxLength="5000"
                data-msg-required="Please enter your message."
                rows="4"
                className="form-control text-3 h-auto py-3-5"
                name="message"
                placeholder="Reply"
                required=""></textarea>
            </div>
          </div>

        </div>
        <div className="form-group" style={{ width: 200 }}>
          <button type="submit" onClick={handleSubmit}
            className="btn btn-modern btn-dark w-100 font-weight-semibold text-capitalize text-3 py-3 anim-hover-translate-top-5px transition-2ms mb-5 mb-lg-0"
          >
            <span className="px-4 d-block ws-nowrap">Send Reply <i className="fas fa-arrow-right rotate-l-45 ms-2"></i></span></button>
        </div>
        <div className="form-group position-relative">
          {done && <Done message={_t("Message reply sent. Thank you")}  />}
          {error && <Error message={error} />}
          {loading && <LoadingInside />}
        </div>
      </form>
    </div>
  );
}

export default SupportMessageReplyForm;
