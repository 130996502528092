import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Utils } from 'utils'

function CourseListTable({ data, handleDelete, showDelete }) {

  var navigate = useNavigate()

  return (
    <div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th style={{ width: 30 }}>#</th>
            <th style={{ width: 400 }}>Course</th>
            <th>Enrolled Users</th>
            <th>Level</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data?.map(item => (
            <tr key={item.id}>
              <td>
                <Link to={`/cms/course/${item.id}`} className="">
                  {item.id}
                </Link>
              </td>
              <td>
                <Link to={`/cms/course/${item.id}`} className="">
                  {Utils.getDefaultName(item)}
                </Link>
              </td>
              <td>
                <Link to={`/cms/course/${item.id}/enrolled`} className="">
                  <i className="fa fa-user"></i> {item.enrolled_count}
                </Link>
              </td>
              <td>
                {item.level && Utils.getDefaultName(item.level)}
              </td>
              <td>
                {showDelete && (
                  <button className="btn" onClick={() => handleDelete(item.id)}>
                    <i className="fa fa-trash"></i>
                  </button>
                )}

              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default CourseListTable