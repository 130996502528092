import React, { useContext, useEffect, useState } from 'react'
import { Utils, translate } from '../../../utils'
import CoursesGrid from '../../components/course/components/CoursesGrid'
import PageLayout from 'view/components/shared/PageLayout'
import { LanguageContext } from 'contexts/LanguageContext'
import PodcastsGrid from 'view/components/course/components/PodcastsGrid'

function Podcasts() {

	const [query, setQuery] = useState()

	useEffect(() => {
		// Utils.initiateTheme()
	}, [])

	
	return (
		<PageLayout showbreadcrumbs={false} HeaderChild={<HeaderChild query={query} setSearchQuery={setQuery} />}>
			{/* <div className="col-md-4 mx-md-auto p-0">
								<FilterCoursesAside />
							</div> */}
			<PodcastsGrid query={query} />
		</PageLayout>


	)
}


function HeaderChild({setSearchQuery}) {
	const { translations } = useContext(LanguageContext);
	const _t = (key) => {
		return translate(key, translations)
	}

	const [query, setQuery] = useState("")


	return (
		<>
			<h1 className="text-color-light pt-3 pb-2 font-weight-bold text-10 text-sm-8">{_t("Podcasts")}</h1>
			<form action="" onSubmit={(e)=>{ e.preventDefault(); setSearchQuery(query) }}>
				<div className="search-container">
					<div className="simple-search input-group mt-2 mb-4">
						<input className="form-control text-1 text-light" value={query} name="q" onChange={(e)=>{ setQuery(e.target.value)}} type="search" placeholder="Search..." />
						<button className="btn" type="submit" aria-label="Search">
							<i className="fas fa-search header-nav-top-icon"></i>
						</button>
					</div>
				</div>
			</form>
		</>

	)
}

export default Podcasts