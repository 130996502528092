import React, { useState } from 'react'
import CustomModal from '../../../../components/modals/CustomModal';
import LessonForm from '../../forms/course/LessonForm';
import DeleteDialog from '../../shared/DeleteDialog';
import Contents from './Contents';
import { DragHandle, QuizOutlined } from '@mui/icons-material';
import Quizes from './quiz/Quizes';
import LessonController from 'view/cms/controllers/course/lesson/LessonController';
import { Utils } from 'utils';
import SortModal from '../shared/sort/SortModal';

function Lessons({ lecture }) {
    const [sortModalIsOpen, setSortModalIsOpen] = useState(false);

    const { data, setData, setSelectedId, setEditModalIsOpen, _t, _showQuizes, editModalIsOpen, selectedId, deleteDialogIsOpen
        , setDeleteDialogIsOpen, quizModalIsOpen, setQuizModalIsOpen, _onDeleteDone, _handleDelete, _edit } = LessonController(lecture)

    return (
        <div className='box '>
            <div className="box-header mb-5">
                <h5>{_t('Lessons')}</h5>
                <div className="box-header-btns">
                    <button className="btn btn-icon" title="Sort lessons" onClick={() => { setSelectedId(null); setSortModalIsOpen(true) }}><DragHandle /> </button>
                    <button className="btn btn-icon" onClick={() => { setSelectedId(null); setEditModalIsOpen(true) }}><i className="fa fa-plus"></i></button>
                </div>
            </div>
            <div className="box-body">
                <div >
                    <div className="accordion accordion-modern" id="lessonsaccordion">
                        {data?.map(item => (
                            <div className="card card-default" key={`clesson${item.id}`}>
                                <div className="card-header">
                                    <h4 className="card-title m-0">
                                        <a className="accordion-toggle collapsed" data-bs-toggle="collapse" data-bs-target={`#lessoncollapse${item.id}`} aria-controls={`#lessoncollapse${item.id}`} aria-expanded="false">
                                            <div>
                                                <i className="fas fa-book"></i> {Utils.getDefaultName(item)}
                                            </div>
                                            <div className="accordion-btns">
                                                <button className="btn btn-accordion" title={"Quizes"} onClick={(e) => { e.preventDefault(); _showQuizes(e, item) }}>
                                                    <QuizOutlined />
                                                </button>
                                                <button className="btn btn-accordion" onClick={(e) => { e.preventDefault(); _edit(e, item) }}>
                                                    <i className="fas fa-edit" ></i>
                                                </button>
                                                <button className="btn btn-accordion" onClick={(e) => { e.preventDefault(); _handleDelete(e, item.id) }}>
                                                    <i className="fas fa-trash" ></i>
                                                </button>
                                            </div>
                                        </a>
                                    </h4>
                                </div>
                                <div id={`lessoncollapse${item.id}`} className="collapse">
                                    <div className="card-body">
                                        <Contents lesson={item.id} />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <SortModal type="lesson" data={data} setData={setData} isOpen={sortModalIsOpen} setIsOpen={setSortModalIsOpen} />
            <EditModal />
            <DeleteModal />
            <QuizModal />


        </div>
    )

    function QuizModal() {
        if (!quizModalIsOpen) return;
        return (
            <CustomModal big className="" key={quizModalIsOpen} setOpen={(value) => setQuizModalIsOpen(value)}
                open={quizModalIsOpen} >

                <Quizes lessonId={selectedId} />

            </CustomModal>
        )
    }


    function DeleteModal() {
        if (!deleteDialogIsOpen) return;
        return (
            <DeleteDialog
                url={`/academy/course/lesson/${selectedId}`}
                isOpen={deleteDialogIsOpen}
                key={"mdl" + deleteDialogIsOpen + selectedId}
                setOpen={setDeleteDialogIsOpen}
                notifyDone={_onDeleteDone}
            />
        )
    }

    function EditModal() {
        if (!editModalIsOpen) return;
        return (
            <CustomModal className="" key={editModalIsOpen} setOpen={(value) => setEditModalIsOpen(value)}
                open={editModalIsOpen} >

                <LessonForm key={`gmd${selectedId}`} id={selectedId} lecture={lecture}
                    onEditDone={(lesson) => {
                        let index = data.findIndex(item => item.id === lesson.id);
                        if (index > -1) {
                            data[index].names = lesson.names;
                            data[index].lecture = lesson.lecture;
                        }
                        else {
                            data.push(lesson);
                        }
                        setData(data)
                    }}
                />
            </CustomModal>
        )
    }
}

export default Lessons