import TranslateController from 'controller/shared/TranslateController';
import React, { useContext, useState } from 'react'
import CustomModal from 'view/components/modals/CustomModal'

function HomeTutorials({padding=true}) {
    const [videoModalIsOpen, setVideoModalIsOpen] = useState(false);
    const {_t} = TranslateController();

    return (
        <section id="tutorials">
            <div className={`container p-relative z-index-2 ${padding && "pb-5"} `}>
                <div className={`row ${padding && "py-5 pb-5"} `}>
                    <div className="col-lg-3">
                        <div class="portfolio-item " onClick={(e) => { e.preventDefault(); setVideoModalIsOpen("p1") }}>
                            <a href="#">
                                <span class="thumb-info thumb-info-lighten border-radius-0">
                                    <span class="thumb-info-wrapper border-radius-0">
                                    <img src={"/assets/video/tutorial/p1.jpg"} className='full-width pointer'  />
                                            <span class="thumb-info-title">
                                                <span class="thumb-info-inner kfont">{_t("Promo 1")}</span>
                                            </span>
                                            <span class="thumb-info-action">
                                                <span class="thumb-info-action-icon bg-dark opacity-8"><i class="fas fa-play"></i></span>
                                            </span>
                                    </span>
                                </span>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div class="portfolio-item " onClick={(e) => { e.preventDefault(); setVideoModalIsOpen("p2") }}>
                            <a href="#">
                                <span class="thumb-info thumb-info-lighten border-radius-0">
                                    <span class="thumb-info-wrapper border-radius-0">
                                    <img src={"/assets/video/tutorial/p2.jpg"} className='full-width pointer'  />
                                            <span class="thumb-info-title">
                                                <span class="thumb-info-inner kfont">{_t("Promo 2")}</span>
                                            </span>
                                            <span class="thumb-info-action">
                                                <span class="thumb-info-action-icon bg-dark opacity-8"><i class="fas fa-play"></i></span>
                                            </span>
                                    </span>
                                </span>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div class="portfolio-item " onClick={(e) => { e.preventDefault(); setVideoModalIsOpen("p3") }}>
                            <a href="#">
                                <span class="thumb-info thumb-info-lighten border-radius-0">
                                    <span class="thumb-info-wrapper border-radius-0">
                                    <img src={"/assets/video/tutorial/p3.jpg"} className='full-width pointer'  />
                                            <span class="thumb-info-title">
                                                <span class="thumb-info-inner kfont">{_t("Promo 3")}</span>
                                            </span>
                                            <span class="thumb-info-action">
                                                <span class="thumb-info-action-icon bg-dark opacity-8"><i class="fas fa-play"></i></span>
                                            </span>
                                    </span>
                                </span>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div class="portfolio-item " onClick={(e) => { e.preventDefault(); setVideoModalIsOpen("p4") }}>
                            <a href="#">
                                <span class="thumb-info thumb-info-lighten border-radius-0">
                                    <span class="thumb-info-wrapper border-radius-0">
                                    <img src={"/assets/video/tutorial/p4.jpg"} className='full-width pointer'  />
                                            <span class="thumb-info-title">
                                                <span class="thumb-info-inner kfont">{_t("Promo 4")}</span>
                                            </span>
                                            <span class="thumb-info-action">
                                                <span class="thumb-info-action-icon bg-dark opacity-8"><i class="fas fa-play"></i></span>
                                            </span>
                                    </span>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <CustomModal className="p-0" padding={0} dark big key={videoModalIsOpen} setOpen={(value) => setVideoModalIsOpen(value)}
                open={videoModalIsOpen} >
                <video src={`/assets/video/tutorial/${videoModalIsOpen}.mp4`} poster={`/assets/video/tutorial/${videoModalIsOpen}.jpg`} controls className='full-width' />
            </CustomModal>
        </section>
    )
}

export default HomeTutorials