import React, { useState } from 'react'
import Lessons from './Lessons';
import { Utils } from 'utils';
import CustomModal from 'view/components/modals/CustomModal';
import LectureForm from '../../forms/course/LectureForm';
import DeleteDialog from '../../shared/DeleteDialog';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { DragHandle } from '@mui/icons-material';
import SortModal from '../shared/sort/SortModal';
import PodcastController from 'view/cms/controllers/course/podcast/PodcastController';
import PodcastEpisodeForm from '../../forms/course/PodcastEpisodeForm';

function PodcastEpisodes({ podcastId }) {

    const { data, setData, setSelectedId, setEditModalIsOpen, _handleDelete, _edit, deleteDialogIsOpen, selectedId,
        setDeleteDialogIsOpen, _onDeleteDone, editModalIsOpen, _t, sortModalIsOpen, setSortModalIsOpen } = PodcastController(podcastId)


    return (
        <div className='box mt-5'>
            <div className="box-header mb-5">
                <h5>{_t('Episodes')}</h5>
                <div className="box-header-btns">
                    <button className="btn btn-icon" title="Sort contents" onClick={() => { setSelectedId(null); setSortModalIsOpen(true) }}><DragHandle /> </button>
                    <button className="btn btn-icon" onClick={() => { setSelectedId(null); setEditModalIsOpen(true) }}><i className="fa fa-plus"></i></button>
                </div>
            </div>
            <div className="box-body">
                <div >
                    <div className="accordion accordion-modern" id="contentsaccordion">
                        {data?.map(item => (
                            <div className="card card-default" key={`ccontent${item.id}`}>
                                <div className="card-header">
                                    <h4 className="card-title m-0">
                                        <a className="accordion-toggle collapsed" data-bs-toggle="collapse" data-bs-target={`#contentcollapse${item.id}`} aria-controls={`#contentcollapse${item.id}`} aria-expanded="false">
                                            <div>
                                                <span className="sort_order">{item.sort_order+1}</span>
                                                {/* <i className="fas fa-check"></i>  */}
                                                {Utils.getDefaultName(item)} <span className='small'>( {item.type} )</span>
                                            </div>
                                            <div className="accordion-btns">
                                                
                                                <button className="btn btn-accordion" onClick={(e) => { e.preventDefault(); _edit(e, item) }}>
                                                    <i className="fas fa-edit" ></i>
                                                </button>
                                                <button className="btn btn-accordion" onClick={(e) => { e.preventDefault(); _handleDelete(e, item.id) }}>
                                                    <i className="fas fa-trash" ></i>
                                                </button>
                                            </div>
                                        </a>
                                    </h4>
                                </div>
                                <div id={`contentcollapse${item.id}`} className="collapse">
                                    <div className="card-body">
                                        url : {item.url} <br />
                                        {Utils.getDefaultDescription(item)}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>


            <SortModal type="podcastepisode" data={data} setData={setData} isOpen={sortModalIsOpen} setIsOpen={setSortModalIsOpen} />


            {editModalIsOpen && (
                <CustomModal className="" big key={editModalIsOpen} setOpen={(value) => setEditModalIsOpen(value)}
                    open={editModalIsOpen} >

                    <PodcastEpisodeForm key={`podepf${selectedId}`} podcastId={podcastId} id={selectedId}
                        onEditDone={(content) => {
                            let index = data.findIndex(item => item.id === content.id);
                            if (index > -1) {
                                data[index].names = content.names;
                                data[index].url = content.url;
                            }
                            else {
                                data.push(content);
                            }
                            setData(data)
                        }}
                    />

                </CustomModal>
            )}
            
            {deleteDialogIsOpen && (
                <DeleteDialog
                    url={`/academy/course/podcastepisode/${selectedId}`}
                    isOpen={deleteDialogIsOpen}
                    key={"mdl" + deleteDialogIsOpen + selectedId}
                    setOpen={setDeleteDialogIsOpen}
                    notifyDone={_onDeleteDone}
                />
            )}




        </div>
    )

   


    
}

export default PodcastEpisodes