import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Close';
import Slider from '@mui/material/Slider'; // Import Material-UI Slider
import FilterByGenderWidget from 'features/feature_search/components/shared/FilterByGenderWidget';

const EnrolledUsersFilter = ({ courseId, onQuery, query }) => {
  const [inputValue, setInputValue] = useState(query || "");
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [isFinished, setIsFinished] = useState(false);
  const [minProgress, setMinProgress] = useState(0); // State for minimum progress
  const [maxProgress, setMaxProgress] = useState(100); // State for maximum progress

  // Handle input change
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  // Handle checkbox change for "Completed"
  const handleProgressCheckboxChange = (event) => {
    setIsFinished(event.target.checked);
  };

  // Handle gender filter change
  const handleGenderSelect = (selectedGenders) => {
    setSelectedGenders(selectedGenders);
  };

  // Handle progress bar change for minimum progress
  const handleMinProgressChange = (event, newValue) => {
    setMinProgress(newValue);
  };

  // Handle progress bar change for maximum progress
  const handleMaxProgressChange = (event, newValue) => {
    setMaxProgress(newValue);
  };

  // useEffect to generate query when any filter changes
  useEffect(() => {
    generateQuery();
  }, [inputValue, selectedGenders, isFinished, minProgress, maxProgress]);

  // Generate query string
  const generateQuery = () => {
    let queryString = "";

    // Add search input to query
    if (inputValue) {
      queryString += `name=${inputValue}`;
    }

    // Add selected gender to query
    if (selectedGenders.length > 0) {
      const genderQuery = `gender=${selectedGenders.join(",")}`;
      queryString += queryString ? `&${genderQuery}` : genderQuery;
    }

    // Add completion status to query
    if (isFinished) {
      const finishedQuery = `is_finished=1`;
      queryString += queryString ? `&${finishedQuery}` : finishedQuery;
    }

    // Add min progress to query
    if (minProgress > 0) {
      const minProgressQuery = `min_progress=${minProgress}`;
      queryString += queryString ? `&${minProgressQuery}` : minProgressQuery;
    }

    // Add max progress to query
    if (maxProgress < 100) {
      const maxProgressQuery = `max_progress=${maxProgress}`;
      queryString += queryString ? `&${maxProgressQuery}` : maxProgressQuery;
    }

    // Send the query string back to the parent component
    onQuery(queryString);
  };

  // Clear search input
  const handleClearSearch = () => {
    setInputValue("");
  };

  return (
    <form onSubmit={(e) => e.preventDefault()} className="kfont">
      <TextField
        variant="outlined"
        size="small"
        label="Search..."
        value={inputValue}
        onChange={handleInputChange}
        InputProps={{
          endAdornment: inputValue ? (
            <IconButton onClick={handleClearSearch} aria-label="clear search">
              <CancelIcon />
            </IconButton>
          ) : (
            <IconButton type="submit" aria-label="search">
              <SearchIcon />
            </IconButton>
          ),
        }}
        style={{ marginRight: '8px', flexGrow: 1 }}
      />

      {/* Gender Filter */}
      <div className="mt-3">
        <FilterByGenderWidget courseId={courseId} onSelect={handleGenderSelect} />
      </div>

      {/* Status Filter */}
      <div className="mt-3">
        <h2>Status</h2>
        <div className="custom-checkbox-1 lan-rtl">
          <input
            id={`progressCheckbox`}
            type="checkbox"
            name={`progressCheckbox`}
            onChange={handleProgressCheckboxChange}
          />
          <label htmlFor={`progressCheckbox`}>Completed</label>
        </div>
      </div>

      {/* Min Progress Slider */}
      <div className="mt-3">
        <h2>Minimum Progress</h2>
        <Slider
          value={minProgress}
          onChange={handleMinProgressChange}
          aria-labelledby="min-progress-slider"
          valueLabelDisplay="auto"
          min={0}
          max={100}
        />
      </div>

      {/* Max Progress Slider */}
      <div className="mt-3">
        <h2>Maximum Progress</h2>
        <Slider
          value={maxProgress}
          onChange={handleMaxProgressChange}
          aria-labelledby="max-progress-slider"
          valueLabelDisplay="auto"
          min={0}
          max={100}
        />
      </div>
    </form>
  );
};

export default EnrolledUsersFilter;
