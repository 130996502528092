import React, { useState } from 'react'
import Gallery from './Gallery'

function FileFieldWithGallery({onDocumentSelected,defaultUrl=null,className=""}) {

    const [gallery_modal_is_open, setGalleryModalIsOpen] = useState(false);
    const [document_url, setDocumentUrl] = useState(defaultUrl);

    const openGallery = () => {

        setGalleryModalIsOpen(true)
    
      }
    
      const closeGallery = () => {
        setGalleryModalIsOpen(false)
      }
    
      const setSelectedDocument = (gallery_item) => {
        setDocumentUrl(gallery_item?.document)
        onDocumentSelected(gallery_item?.document)
      }
    
  return (
    <>
        <Gallery
        type={"document"}
        setSelectedGalleryItem={setSelectedDocument}
        isOpen={gallery_modal_is_open}
        key={gallery_modal_is_open}
        closeGallery={() => {
          closeGallery();
        }}
      />
        <img onClick={()=>{ setGalleryModalIsOpen(true)  }} className='cursor-pointer' src="/assets/images/svg/pdf.svg" width={100} />
        <small>{document_url ?? "Select File..."}</small>
    </>
  )
}

export default FileFieldWithGallery