import { QuestionMarkOutlined } from '@mui/icons-material';
import React, { useContext } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link, useParams } from "react-router-dom";
import { LanguageContext } from '../../../../../../contexts/LanguageContext';
import { CourseHttpService } from '../../../../../../services/course';
import { translate, Utils } from '../../../../../../utils';
import CustomModal from '../../../../../components/modals/CustomModal';
import QuizQuestionForm from '../../../forms/course/QuizQuestionForm';
import DeleteDialog from '../../../shared/DeleteDialog';
import { useQuery } from 'react-query';
import QuestionChoices from './QuestionChoices';

function ContentQuestions({ contentId }) {

    const { isLoading, error, content_questions } = useQuery(['content_questions',contentId], ()=>CourseHttpService.loadQuizQuestions(null,contentId), {
        onSuccess: (content_questions) => {
            if (content_questions.results) {
                setData(content_questions.results)
            }
        }
    });

    const [data, setData] = useState(null);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = React.useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = React.useState(false);
    const [questionModalIsOpen, setQuestionModalIsOpen] = React.useState(false);

    const [selectedId, setSelectedId] = React.useState(0);
    const { id } = useParams();
    const [refresh, setRefresh] = React.useState(0);

    const { setLanguage, translations } = useContext(LanguageContext);
    const _t = (key) => {
        return translate(key, translations)
    }
    // useEffect(() => {

    //     _getData()

    //     return () => {
    //     }
    // }, [])

    // const _getData = () => {
    //     setLoading(true);
    //     CourseHttpService.loadQuizeQuestions(quizId).then((items) => {
    //         if (items.results) {
    //             setData(items.results)
    //         }
    //     });
    // }


    const _handleDelete = (e, id) => {
        setSelectedId(id);
        setRefresh(refresh + 1)
        setDeleteDialogIsOpen(true)
    }

    const _onDeleteDone = () => {
        var temp = data.filter(item => item.id !== selectedId)
        setData(temp)
        setDeleteDialogIsOpen(false);
        setRefresh(refresh + 1)
    }

    const _edit = (e, item) => {
        e.preventDefault();
        setSelectedId(item.id);
        setEditModalIsOpen(true)
        setRefresh(refresh + 1)
    }

    return (
        <div className='box mt-5'>
            <div className="box-header mb-5">
                <h5>{_t('Questions')}</h5>
                <div className="btns">
                    <button className="btn btn-icon" onClick={() => { setSelectedId(null); setEditModalIsOpen(true) }}><i className="fa fa-plus "></i></button>
                </div>
            </div>
            <div className="box-body">
                <div >
                    {isLoading && (<>Loading...</>)}
                    <div className="accordion accordion-modern" id="questionsaccordion">
                        {data?.map(item => (
                            <div className="card card-default" key={`cquestion${item.id}`}>
                                <div className="card-header">
                                    <h4 className="card-title m-0">
                                        <a className="accordion-toggle collapsed" data-bs-toggle="collapse" data-bs-target={`#questioncollapse${item.id}`} aria-controls={`#questioncollapse${item.id}`} aria-expanded="false">
                                            <div>
                                                <QuestionMarkOutlined /> {item?.names ? Utils.getDefaultName(item) : `questione ${item.id}`}
                                            </div>
                                            <div className="accordion-btns">
                                                
                                                <button className="btn btn-accordion" onClick={(e) => { e.preventDefault(); _edit(e, item) }}>
                                                    <i className="fas fa-edit" ></i>
                                                </button>
                                                <button className="btn btn-accordion" onClick={(e) => { e.preventDefault(); _handleDelete(e, item.id) }}>
                                                    <i className="fas fa-trash" ></i>
                                                </button>
                                            </div>
                                        </a>
                                    </h4>
                                </div>
                                <div id={`questioncollapse${item.id}`} className="collapse">
                                    <div className="card-body">
                                        <QuestionChoices quiz_question={item} />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {editModalIsOpen && (
                <CustomModal className="" key={editModalIsOpen} setOpen={(value) => setEditModalIsOpen(value)}
                    open={editModalIsOpen} >

                    <QuizQuestionForm key={`qqf${selectedId}`} contentId={contentId} id={selectedId}
                        onEditDone={(question) => {
                            let index = data.findIndex(item => item.id === question.id);

                            if (index > -1) {
                                data[index].names = question.names;
                                data[index].content = question.content;
                                data[index].question_type = question.question_type;
                            }
                            else {
                                data.push(question);
                            }
                            setData(data)
                        }}
                    />
                </CustomModal>
            )}


            {deleteDialogIsOpen && (
                <DeleteDialog
                    url={`/academy/course/question/${selectedId}`}
                    isOpen={deleteDialogIsOpen}
                    key={"mdl" + deleteDialogIsOpen + selectedId}
                    setOpen={setDeleteDialogIsOpen}
                    notifyDone={_onDeleteDone}
                />
            )}

        </div>
    )
}

export default ContentQuestions