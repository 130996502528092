import { DragHandleOutlined } from '@mui/icons-material';
import { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CourseHttpService } from 'services/course';
import { Utils } from 'utils';

function Sortable({ items,setItems, type }) {
    const [sortedItems, setSortedItems] = useState(items);

    const onDragEnd = result => {
        if (!result.destination) return;

        const updatedItems = Array.from(sortedItems);
        const [removed] = updatedItems.splice(result.source.index, 1);
        updatedItems.splice(result.destination.index, 0, removed);

        setSortedItems(updatedItems);
        setItems(updatedItems)

        // Send the updated item order to the server
        const updatedIds = updatedItems.map(item => item.id);
        _saveSortOrder(updatedIds);
    };

    const _saveSortOrder = updatedIds => {
        // Send the updatedIds to the server
        let body = {
            ids : updatedIds,
            type : type
        }
        CourseHttpService.saveSortOrder(body).then((data) => {
            console.log(data)
        })
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <div>
                <Droppable droppableId="weeksaccordion">
                    {(provided, snapshot) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {items.map((item, index) => (
                                <Draggable key={item.id} draggableId={"week_draggable_" + item.id} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <DraggableItem item={item} />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
        </DragDropContext>
    );

    function DraggableItem({ item }) {
        return (
            <div className="card card-default mb-2" key={`cweek${item.id}`}>
                <div className="card-header">
                    <h4 className="card-title m-0 draggable-cursor">
                        <DragHandleOutlined /> {item?.names ? Utils.getDefaultName(item) : "item " + item.id}
                    </h4>
                </div>
            </div>
        )
    }
}

export default Sortable;