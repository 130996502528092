import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  signInWithRedirect,
  FacebookAuthProvider,
  TwitterAuthProvider,
  OAuthProvider
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDA0OY8RZki79T5isxNahXkTMTBxJDrXlo",
  authDomain: "kurdishacademy-b02cd.firebaseapp.com",
  projectId: "kurdishacademy-b02cd",
  storageBucket: "kurdishacademy-b02cd.appspot.com",
  messagingSenderId: "972409456931",
  appId: "1:972409456931:web:dcf22ee822e891e3ac6496",
  measurementId: "G-92XBJDKWYS"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);



const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();
// const googleProvider = new OAuthProvider('google.com');
// googleProvider.addScope('profile');
// googleProvider.addScope('email');
const facebookProvider = new FacebookAuthProvider();
const twitterProvider = new TwitterAuthProvider();

const signInWithFacebook = async (e) => {
  try {
    const res = await signInWithPopup(auth, facebookProvider);
    const user = res.user;  
    var _userObj = {
      role: "participant",
      uid: user.uid,
      email: user.email,
      image_path: user.photoURL,
      name: user.displayName,
      authProvider: "facebook",
    };

    



    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      addDoc(collection(db, "users"), _userObj);
    }
    return _userObj;
  } catch (err) {
    console.error(err);
    return null; 
  }
};

const signInWithTwitter = async (e) => {
  try {
    const res = await signInWithPopup(auth, twitterProvider);
    const user = res.user;  
    console.log(user)
    var _userObj = {
      role: "participant",
      uid: user.uid,
      email: user.email,
      image_path: user.photoURL,
      name: user.displayName,
      authProvider: "twitter",
    };
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      addDoc(collection(db, "users"), _userObj);
    }
    return _userObj;
  } catch (err) {
    console.error(err);
    return null; 
  }
};

const signInWithGoogle = async (e) => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;  

    // console.log(res)
    // console.log(res.user)

    // if(!user.email){
    //   user.email = user.uid+"@kurdishacademy.talent"
    // }

    var _userObj = {
      role: "participant",
      uid: user.uid,
      email: user.email  , // ??  user.uid+"@kurdishacademy.talent"
      image_path: user.photoURL,
      name: user.displayName,
      authProvider: "google",
    };

    console.log(_userObj)

    
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      addDoc(collection(db, "users"), _userObj);
    }
    return _userObj;
  } catch (err) {
    console.error(err);
    return null; 
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

export {
  auth,
  db,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  signInWithFacebook,
  signInWithTwitter,
};