import React, { useState } from 'react'
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { BaseHttpService } from 'services/base';
import { Utils } from 'utils';
import GalleryForm from 'view/cms/components/forms/shared/GalleryForm';
import CMSLayout from 'view/cms/components/shared/CMSLayout'
import DeleteDialog from 'view/cms/components/shared/DeleteDialog';
import CustomModal from 'view/components/modals/CustomModal';

function MediaPage() {

    const [galleries, setData] = useState(null);
    const [refresh, setRefresh] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1);



    const { isLoading, error, data, refetch } = useQuery(['galleries', pageNumber], () => { return BaseHttpService.loadGalleries(pageNumber, "all", 30) }, {
        onSuccess: (data) => {
            setData(data.results)
            setTotalPages(Math.ceil(data.count / 30));

        },
        // cacheTime: 60*60*24*30*1000// Cache results for 30 days
        refetchInterval: 60 * 100000,
    });

    const [selectedId, setSelectedId] = useState(0);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);

    const handlePageChange = (newPageNumber) => {
        setPageNumber(newPageNumber);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    };

    const _handleEdit = (e, id) => {
        setSelectedId(id);
        setRefresh(refresh + 1)
        setEditModalIsOpen(true)
    }

    const _handleDelete = (e, id) => {
        setSelectedId(id);
        setRefresh(refresh + 1)
        setDeleteDialogIsOpen(true)
    }

    const _onDeleteDone = () => {
        var temp = galleries.filter(item => item.id !== selectedId)
        setData(temp)
        setDeleteDialogIsOpen(false);
        setRefresh(refresh + 1)
    }

    return (
        <CMSLayout>
            <div className='box'>
                <div className="box-header mb-5">
                    <h5>Media</h5>
                    <div className="btns d-flex">

                        {/* <Link to="/cms/category/" className="btn btn-icon bg-warning mr-2" title="categories"><i className="fa fa-folder "></i></Link> */}
                        <button className="btn btn-icon bg-success" onClick={() => { setSelectedId(null); setEditModalIsOpen(!editModalIsOpen) }}><i className="fa fa-plus "></i></button>
                    </div>
                </div>
                <div className="box-body general-list">

                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>
                                    #
                                </th>
                                <th></th>
                                <th>Title</th>
                                <th>type</th>
                                <th>Date</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {galleries && galleries.map(item => (
                                <tr key={"cat" + item.id}>
                                    <td>{item.id}</td>
                                    <td><Link onClick={(e) => { _handleEdit(e, item.id) }}><img src={item.image} style={{ width: "100px" }} /></Link></td>
                                    <td className='kfont'><Link onClick={(e) => { _handleEdit(e, item.id) }}>{item.title}</Link></td>
                                    <td className='kfont'>{item.type}</td>
                                    <td>{Utils.getShortDate(item.created_at)}</td>
                                    <td className='text-right'>
                                        <a href="#" onClick={(e) => { _handleDelete(e, item.id) }} className="btn btn-outline btn-swap-1">
                                            <span><i className="fas fa-trash color-gray "></i></span>
                                            <span>Delete <i className="fas fa-trash ms-2 color-gray "></i></span>
                                        </a>

                                    </td>

                                </tr>
                            ))}


                        </tbody>


                    </table>

                    <ul className="pagination float-end">
                        <li className={`page-item ${pageNumber === 1 ? 'disabled' : ''}`}>
                            <button className="page-link" onClick={() => handlePageChange(pageNumber - 1)} disabled={pageNumber === 1}>
                                <i className="fas fa-angle-left"></i>
                            </button>
                        </li>
                        {Array.from({ length: totalPages }, (_, index) => (
                            <li key={index} className={`page-item ${pageNumber === index + 1 ? 'active' : ''}`}>
                                <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                                    {index + 1}
                                </button>
                            </li>
                        ))}
                        <li className={`page-item ${pageNumber === totalPages ? 'disabled' : ''}`}>
                            <button className="page-link" onClick={() => handlePageChange(pageNumber + 1)} disabled={pageNumber === totalPages}>
                                <i className="fas fa-angle-right"></i>
                            </button>
                        </li>
                    </ul>
                </div>

                <CustomModal big key={editModalIsOpen} setOpen={(value) => { setEditModalIsOpen(value) }}
                    open={editModalIsOpen} >

                    <GalleryForm id={selectedId} onEditDone={() => { refetch(); setEditModalIsOpen(false) }} />

                </CustomModal>

                <DeleteDialog
                    url={`/postapi/gallery/${selectedId}`}
                    isOpen={deleteDialogIsOpen}
                    key={"mdl" + deleteDialogIsOpen + selectedId}
                    setOpen={setDeleteDialogIsOpen}
                    notifyDone={_onDeleteDone}
                />
            </div>
        </CMSLayout>
    )
}

export default MediaPage