import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { HttpService } from 'services/http';
import TranslateController from 'controller/shared/TranslateController';
import { Utils } from 'utils';

function CertificatePreview({ certificate }) {

    const { _t } = TranslateController()

    return (
        <Link to={`/certificate/${certificate.uid}`} >
            <div className='certificate-preview-container mb-3'>
                <div className="certificate-preview-image mr-2">
                    <img src={HttpService.ApiBase + "/" + certificate.file_url} alt="View certificate" className='full-width' />
                </div>
                <div className="certificate-preview-title kfont">
                    {_t('Completion date')} : {Utils.getDate(certificate.created_at)}
                </div>
            </div>
        </Link>
    )
}

export default CertificatePreview