import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ContentViewController from 'controller/course/content/ContentViewController'
import ContentViewAside from 'view/components/course/content/ContentViewAside';
import ContentViewDetails from 'view/components/course/content/ContentViewDetails';
import Header from 'view/components/shared/Header';
import Footer from 'view/components/shared/Footer';
import PageHeader from 'view/components/shared/PageHeader';
import { Utils } from 'utils';
import { CourseHttpService } from 'services/course';

function ContentView() {
	const { course, content } = useParams();

	const { data, breadcrumbs, isLoading, error,courseData } = ContentViewController(course)


	useEffect(() => {
		window.scrollTo({top: 0, left: 0, behavior: 'smooth' });

	}, [content])
	

	return (
		<div className="body">
			<Header dark={1} />

			<div role="main" className="main "> {/* mt-header */}
				<PageHeader showbreadcrumbs={false} />
				<div id='main'>
					<div className='custom-page-content p-relative z-index-2'>
						<div className="container custom-padding-top-1 custom-padding-bottom-1 px-lg-5 bg-light">
								<div className="row pb-4 mb-4 lan-rtl ">
									<div className="col-md-4 p-0 order-md-1 order-2 ">
										<ContentViewAside course={course} />
									</div>
									<div className="col-md-8 mt-sm-10p order-md-2 order-1">
										<ContentViewDetails key={content+"cvd"} courseData={courseData} content={data} />
									</div>

								</div>
						</div>
					</div>
				</div>

			</div>

			<Footer />
		</div>


	)
}

export default ContentView