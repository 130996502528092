import { AutoStoriesOutlined, CheckCircleOutline, PlayCircleOutline } from '@mui/icons-material';
import React from 'react'
import { Link } from "react-router-dom";
import {  Utils } from '../../../../utils';
import ContentController from 'controller/course/content/ContentController';
import TranslateController from 'controller/shared/TranslateController';

function Contents({ lesson, course }) {

    const { data } = ContentController(lesson )
    const { _t } = TranslateController()


    
    

    return (
        <div className='box'>

            <div className="box-body ">
                <div >
                    <div>
                        {data?.map(item => {
                            let isDone = (item?.course_logs && item?.course_logs.length > 0) && item?.course_logs[0].ended != null


                            var total_seconds = item.duration ?? 0;
                            var total_mins = 1;
                            if(total_seconds > 60) {
                                total_mins = parseInt(total_seconds/60);
                            }

                            var seconds = total_seconds % 60;

                            return (
                                <Link to={`/course/${course}/lesson/${lesson}/content/${item.id}`} key={"content-a-"+item.id} className="no-decoration lan-rtl" >
                                    <div className="content-item mb-2" key={`ccontent${item.id}`}>
                                        <div className={`icon ${isDone ? "success" : ""}`}>
                                            {isDone ?
                                                (
                                                    <CheckCircleOutline />

                                                ) : item.url ? (
                                                    <PlayCircleOutline />
                                                ) : (<AutoStoriesOutlined />)}

                                        </div>
                                        <div className="description">
                                            {Utils.getDefaultName(item)}
                                            <div className="info">
                                                <div className='content-type'>{item.type ?? "Reading"}</div>
                                                <span aria-hidden="true" >•</span>
                                                <div className='content-length'>{total_mins} {_t("min")} {seconds} {_t("sec")}</div>
                                            </div>

                                        </div>
                                    </div>
                                </Link>
                            )
                        }
                        )}
                    </div>
                </div>
            </div>


        </div>
    )
}

export default Contents