import React, { useContext, useEffect, useRef, useState } from 'react'
import PageLayout from 'view/components/shared/PageLayout'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { CourseHttpService } from 'services/course'
import TranslateController from 'controller/shared/TranslateController'
import { Utils } from 'utils'
import MusicNotationImage from '../components/MusicNotationImage'
import NotationsGrid from 'view/components/course/components/NotationsGrid'

function Notation() {

	const { id } = useParams();


	const { isLoading, error, data,refetch } = useQuery(['notation', id], () => { return CourseHttpService.loadNotation(id) }, {
		onSuccess: (notation) => {
			if (notation) {
				console.log(notation);
				setNotation(notation)
				setNotePages(notation.note_pages)
			}
		},
		staleTime: Infinity,
		cacheTime: 1000 * 60 * 60 * 24,
		refetchOnWindowFocus: false,
	});

	useEffect(() => {
		refetch();
  }, [id])

	useEffect(() => {
		const handleContextmenu = e => {
			e.preventDefault()
		}
		document.addEventListener('contextmenu', handleContextmenu)
		return function cleanup() {
			document.removeEventListener('contextmenu', handleContextmenu)
		}
  }, [])


	const [notePages, setNotePages] = useState(null);
	const [notation, setNotation] = useState([]);

	

	return (
		<PageLayout showbreadcrumbs={false} HeaderChild={<HeaderChild notation={notation} />}>
			<div className="text-center">
				{(notation && notePages) && (
					<MusicNotationImage
						notePages={notePages}
						audioSrc={notation.music_url}
					/>
				)}

			</div>

			{(notation && notation.artists && notation.artists?.length>0) && (
				<NotationsGrid loadMore={1} key={notation.artists?.length} size={6} artistId={notation.artists[0].id} excludeId={id} />
			)}

		</PageLayout>


	)
}



function HeaderChild({ notation }) {
	const { _t } = TranslateController();


	return (
		<div className='pb-5'>
			<h1 className="text-color-light pt-3 pb-2 font-weight-bold text-10 text-sm-8">{Utils.getDefaultName(notation)}</h1>
			<div className="text-color-light pt-1 pb-4 text-3-4 lan-kfont">
			   {(notation && notation.scale) && (
				<><b>{_t("Scale")}</b>: {Utils.getDefaultName(notation.scale)}</>
			   )}	<br /> 
			   {(notation && notation.time_signature) && (
				<><b>{_t("Time Signature")}</b>:  {Utils.getDefaultName(notation.time_signature)}</>
			   )}
				
			</div>
		</div>

	)
}

export default Notation