import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PageLayout from 'view/components/shared/PageLayout'
import { LanguageContext } from 'contexts/LanguageContext'
import { Utils, translate } from 'utils'
import BlogStyle6 from '../components/blog_styles/BlogStyle6'

function BlogByKeyword() {

	var { keyword } = useParams();

    const [formattedKeyword, setFormattedKeyword] = useState("");

    useEffect(() => {
        if (keyword) {
            const formatted = keyword.replaceAll('-', ' ');
            setFormattedKeyword(formatted);
        }
    }, [keyword]);

    if(!keyword) keyword = "";

    const [query, setQuery] = useState()

    return (
        <PageLayout showbreadcrumbs={false} HeaderChild={<HeaderChild  keyword={formattedKeyword} setSearchQuery={setQuery} />}>
            <div className=''>
                <BlogStyle6 keyword={formattedKeyword} query={query} />
            </div>
        </PageLayout>
    )
}



function HeaderChild({keyword,setSearchQuery }) {
    const { translations } = useContext(LanguageContext);
    const _t = (key) => {
        return translate(key, translations)
    }
    const [query, setQuery] = useState("")


    return (
        <>

            <h1 className="text-color-light pt-3 pb-3 font-weight-bold text-10">{keyword ? keyword : _t("Articles")}</h1>
            <form action="" onSubmit={(e) => { e.preventDefault(); setSearchQuery(query) }}>
                <div className="search-container">
                    <div className="simple-search input-group mt-3 mb-4">
                        <input className="form-control text-1 text-light" value={query} name="q" onChange={(e) => { setQuery(e.target.value) }} type="search" placeholder="Search..." />
                        <button className="btn" type="submit" aria-label="Search">
                            <i className="fas fa-search header-nav-top-icon"></i>
                        </button>
                    </div>
                </div>
            </form>
        </>

    )
}

export default BlogByKeyword