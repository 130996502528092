import React, { useState } from 'react'
import CMSLayout from 'view/cms/components/shared/CMSLayout'
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Utils } from 'utils';
import DeleteDialog from 'view/cms/components/shared/DeleteDialog';
import CustomModal from 'view/components/modals/CustomModal';
import { UserHttpService } from '../service';
import { Avatar } from '@mui/material';
import UserForm from '../components/UserForm';
import SearchQuery from 'view/cms/components/forms/shared/SearchQuery';
import UserStatusDot from '../components/UserStatusDot';

function Users() {

    const [selectedId, setSelectedId] = useState(0);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = React.useState(false);
    const [count, setCount] = React.useState(10);
    const [showDelete, setShowDelete] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");


    const UsersTable = ({ role,searchQuery, setCount, showDelete }) => {

        const [users, setData] = useState(null);
        const [refresh, setRefresh] = useState(0);
        const [pageNumber, setPageNumber] = useState(1);
        const [totalPages, setTotalPages] = useState(1);

        const _handleEdit = (e, id) => {
            setSelectedId(id);
            setRefresh(refresh + 1)
            setEditModalIsOpen(true)
        }

        const _handleDelete = (e, id) => {
            setSelectedId(id);
            setRefresh(refresh + 1)
            setDeleteDialogIsOpen(true)
        }

        const _onDeleteDone = () => {
            var temp = users.filter(item => item.id !== selectedId)
            setData(temp)
            setDeleteDialogIsOpen(false);
            setRefresh(refresh + 1)
        }

        const { isLoading, error, data, refetch } = useQuery(['users_list', role, pageNumber], () => { return UserHttpService.loadUsers(role, pageNumber,searchQuery) }, {
            onSuccess: (data) => {
                setData(data.results)
                setCount(data.count)
                setTotalPages(Math.ceil(data.count / 50));
            },
            // cacheTime: 60*60*24*30*1000// Cache results for 30 days
            // refetchInterval: 60 * 100000,
        });


        const handlePageChange = (newPageNumber) => {
            setPageNumber(newPageNumber);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        };

        return (
            <>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>
                                #
                            </th>
                            <th></th>
                            <th>Name</th>
                            <th className='hide-on-small'>Enrolled</th>
                            <th className='hide-on-small'>Gender</th>
                            <th className='hide-on-small'>Registered </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {users && users.map(item => (
                            <tr key={"user_" + item.id}>
                                <td>{item.id}</td>
                                <td ><Link onClick={(e) => { _handleEdit(e, item.id) }}><Avatar alt={item.first_name} src={Utils.getUserImage(item)} /></Link></td>
                                <td className='kfont'>
                                    <Link onClick={(e) => { _handleEdit(e, item.id) }}>
                                        <UserStatusDot last_online={item.last_online} /> {item.first_name} {item.last_name}
                                        <br />
                                        <small>{item.email}</small>
                                    </Link>
                                </td>
                                <td className='hide-on-small kfont'>
                                    {item.enrollments.map(enrollment=>(
                                        <div className='badge badge-primary mr-1'>{Utils.getDefaultName(enrollment.course)}</div>
                                    ))}
                                </td>
                                <td className='hide-on-small'>{item.gender == 0 ? "Male" : item.gender == 1 ? "Female" : item.gender == 2 ? "Other" : ""}</td>
                                <td className='hide-on-small'>{Utils.getShortDate(item.created_at)} <br /> <small title='Last login'>{item.last_login && Utils.getShortDate(item.last_login)}</small> </td>
                                <td className='text-right'>
                                    {(showDelete && item.role != 'admin') && (
                                        <a href="#" onClick={(e) => { _handleDelete(e, item.user) }} className="btn btn-outline btn-swap-1">
                                            <span><i className="fas fa-trash color-gray "></i></span>
                                            <span>Delete <i className="fas fa-trash ms-2 color-gray "></i></span>
                                        </a>

                                    )}

                                    <Link to={`/cms/user/${item.user}`} className="btn btn-outline btn-swap-1">
                                        <span><i className="fas fa-eye color-gray "></i></span>
                                        <span>Details <i className="fas fa-eye ms-2 color-gray "></i></span>
                                    </Link>

                                </td>

                            </tr>
                        ))}


                    </tbody>

                    <CustomModal big key={editModalIsOpen} setOpen={(value) => setEditModalIsOpen(value)}
                        open={editModalIsOpen} >
                        <UserForm id={selectedId} onEditDone={() => { refetch(); setEditModalIsOpen(false) }} />
                    </CustomModal>


                </table>
                <ul className="pagination">
                    <li className={`page-item ${pageNumber === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageChange(pageNumber - 1)} disabled={pageNumber === 1}>
                            <i className="fas fa-angle-left"></i>
                        </button>
                    </li>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <li key={index} className={`page-item ${pageNumber === index + 1 ? 'active' : ''}`}>
                            <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                                {index + 1}
                            </button>
                        </li>
                    ))}
                    <li className={`page-item ${pageNumber === totalPages ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageChange(pageNumber + 1)} disabled={pageNumber === totalPages}>
                            <i className="fas fa-angle-right"></i>
                        </button>
                    </li>
                </ul>
                <DeleteDialog
                    url={`/auth/user/del/${selectedId}`}
                    isOpen={deleteDialogIsOpen}
                    key={"mdl" + deleteDialogIsOpen + selectedId}
                    setOpen={setDeleteDialogIsOpen}
                    notifyDone={_onDeleteDone}
                />
            </>
        )
    }


    return (
        <CMSLayout>
            <div className='box'>
                <div className="box-header mb-5">
                    <h5>Users ( {count} )</h5>
                    <div className="btns box-header-btns d-flex align-items-center">
                        <SearchQuery onQuery={(query) => setSearchQuery(query)} query={searchQuery} />
                        {/* <Link to="/cms/category/" className="btn btn-icon bg-warning mr-2" title="categories"><i className="fa fa-folder "></i></Link> */}
                        <button className="btn btn-icon bg-danger mr-2" onClick={() => { setSelectedId(null); setShowDelete(!showDelete) }}><i className="fa fa-trash "></i></button>
                        <button className="btn btn-icon bg-success" onClick={() => { setSelectedId(null); setEditModalIsOpen(true) }}><i className="fa fa-plus "></i></button>
                    </div>

                </div>
                <div className="box-body general-list">

                    <div className="tabs tabs-top mt-3 full-width ">
                        <ul className="nav nav-tabs nav-tabs-custom ">
                            <li className="nav-item ">
                                <a className="nav-link active" href="#users" data-bs-toggle="tab" role="tab">Users </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#editors" data-bs-toggle="tab" role="tab">Editors</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#admins" data-bs-toggle="tab" role="tab">Admins</a>
                            </li>
                        </ul>
                        <div className="tab-content custom-tabs-content">
                            <div className="tab-pane active" id="users">
                                <UsersTable searchQuery={searchQuery} showDelete={showDelete} role={"participant"} setCount={setCount} />
                            </div>
                            <div className="tab-pane" id="editors">
                                <UsersTable searchQuery={searchQuery} showDelete={showDelete} role={"editor"} setCount={() => { }} />

                            </div>
                            <div className="tab-pane" id="admins">
                                <UsersTable searchQuery={searchQuery} showDelete={showDelete} role={"admin"} setCount={() => { }} />

                            </div>
                        </div>
                    </div>


                </div>


            </div>
        </CMSLayout>
    )


}


export default Users