import React from 'react'
import CmsLoginForm from '../../components/forms/shared/CmsLoginForm'

function CmsLogin() {

    
    return (
        <div role="main" className="main">
            <div className="container">
                <div className="row justify-content-center my-5">
                    <div className="col-md-6 col-lg-5 mb-5 mb-lg-0">
                        <h2 className="font-weight-bold text-5 mb-0">Login</h2>
                        <CmsLoginForm />
                    </div>

                </div>

            </div>

        </div>
    )
}

export default CmsLogin