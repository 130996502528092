import React from 'react';
import { Utils } from "utils"; // Assuming you have a utility function to format time

const UserStatusDot = ({ last_online }) => {
    const getDotClass = (lastOnline) => {
        const now = new Date();
        const lastOnlineDate = new Date(lastOnline);
        const diffMinutes = Math.floor((now - lastOnlineDate) / (1000 * 60)); // difference in minutes

        if (!lastOnline) {
            return 'dot-default'; // Default color if no last_online data
        }
        if (diffMinutes <= 20) {
            return 'dot-success'; // Green for less than 20 minutes
        } else if (diffMinutes <= 120) {
            return 'dot-warning'; // Orange for less than 2 hours
        } else {
            return 'dot-danger'; // Red for more than 2 hours (offline)
        }
    };

    return (
        <span title={Utils.timeAgo(last_online)} className={`dot ${getDotClass(last_online)}`}></span>
    );
};

export default UserStatusDot;
