import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import TranslateController from "controller/shared/TranslateController";
import React, { useEffect, useState } from "react";

const types = [
  { value: "", label: "" },
  { value: "notation", label: "Notation" },
  { value: "article", label: "Article" },
  { value: "podcast", label: "Podcast" },
  { value: "people", label: "People" },
];

const SelectMediaType = ({callback , value}) => {
  const [type, setType] = useState(value);

  useEffect(() => {
    setType(value);
  }, [value]);

  const handleSelect = (event) => {
    const selectedValue = event.target.value;
    setType(selectedValue);
    callback(selectedValue,"type");
  };

  const { _t } = TranslateController()



  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">{_t("Type")}</InputLabel>
      <Select
        label={_t("Type")}
        fullWidth
        className="full-width"
        variant="outlined"
        inputlabelprops={{ shrink: true }}
        value={type}
        onChange={handleSelect}
      >
        {types.map((type) => (
          <MenuItem key={type.value} value={type.value}>
            {type.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectMediaType;