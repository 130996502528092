import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { CourseHttpService } from 'services/course';
import AdminUserLessonsSummary from './AdminUserLessonsSummary';

const AdminUserCourseSummary = ({ courseId, userId }) => {

    const [data, setData] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const { isLoading, error, user_courses } = useQuery(['user-course-summary', courseId, userId], () => CourseHttpService.loadUserCourseSummary(courseId, userId), {
        onSuccess: (data) => {
            setData(data)
        }
    });


    return (
        <div>
            
            <div className="d-flex justify-content-center p-2 pointer chevron-down-container" onClick={()=>{ setIsOpen(!isOpen)  }}>
                <i className={`fa fa-chevron-${isOpen ? 'up' : 'down'} text-3`}></i>
            </div>

            {isOpen && data?.map((lecture, index) => (
                <AdminUserLessonsSummary lecture={lecture} userId={userId} key={"aulss" + lecture.id} />
            ))}
        </div>
    );
};

export default AdminUserCourseSummary;