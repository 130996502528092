import { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { CourseHttpService } from '../../../services/course'
import { Utils, translate } from 'utils'
import { LanguageContext } from 'contexts/LanguageContext'

function LessonQuizesController(lesson) {
  const [data, setData] = useState(null);


    const { isLoading, error, quizes } = useQuery(['quizes',lesson], ()=> CourseHttpService.loadQuizes({lesson : lesson}) , {
        onSuccess: (quizes) => {
            setData(quizes.results)
        }
    });


  return { data, isLoading }
}

export default LessonQuizesController