import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { TextField } from '@mui/material';
import React, {  useState } from 'react'
import { useForm } from 'react-hook-form';
import { BaseHttpService } from '../../../../../services/base';
import { useQuery } from 'react-query';

function NamesWidgets({ setNames, names = [] , show_short_description=false , show_description=true }) {

    const { isLoading, error, data } = useQuery('languages', BaseHttpService.loadLanguages, {
        onSuccess: (data) => {
            setLanguages(data.results)
        },
        cacheTime: 60*60*24*30*1000// Cache results for 30 days
    });
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [languages, setLanguages] = useState(null);



    const handleOnChange = (e, language_id) => {

        var name = null;
        if (names) {
            name = names.find(x => x.language === parseInt(language_id));
        }
        if (name) {
            var temp_names = [...names];
            temp_names.find(x => x.language === parseInt(language_id)).name = e.target.value;
            setNames(names)
        }
        else {

            var new_name = { name: e.target.value, language: parseInt(language_id) }
            var temp_talentNamesa = [];
            if (names)
                temp_talentNamesa = [...names, new_name];
            else temp_talentNamesa = [new_name]
            setNames(temp_talentNamesa)
        }
    }

    const handleDescriptionChange = (language_id, data) => {
        //alert(e.target.value)

        var name = null;
        if (names) {
            name = names.find(x => x.language === parseInt(language_id));
        }
        if (name) {
            var temp_names = [...names];
            temp_names.find(x => x.language === parseInt(language_id)).description = data;
            setNames(temp_names)
        }
        else {

            var new_name = { name: "",short_description: "", description: data, language: parseInt(language_id) }
            var temp_namesa = [...names];
            if (names)
                temp_namesa = [...names, new_name];
            else temp_namesa = [new_name]
            setNames(temp_namesa)
        }
    }

    const handleShortDescriptionChange = (language_id, data) => {
        //alert(e.target.value)

        var name = null;
        if (names) {
            name = names.find(x => x.language === parseInt(language_id));
        }
        if (name) {
            var temp_names = [...names];
            temp_names.find(x => x.language === parseInt(language_id)).short_description = data;
            setNames(temp_names)
        }
        else {

            var new_name = { name: "",description: "", short_description: data, language: parseInt(language_id) }
            var temp_namesa = [...names];
            if (names)
                temp_namesa = [...names, new_name];
            else temp_namesa = [new_name]
            setNames(temp_namesa)
        }
    }

    return (
        <div className="tabs tabs-bottom tabs-simple bg-tabs">
            <ul className="nav nav-tabs tabs-episodes">

                {languages && languages.map((language, i) =>
                (
                    <li key={"lng" + language.id} className={`nav-item kfont`} onClick={() => { setSelectedTabIndex(i) }}>
                        <a className={selectedTabIndex === i ? "nav-link active" : "nav-link"} href={`#`} onClick={(e)=>{ e.preventDefault()}} data-toggle="tab">{language.name}</a>
                    </li>
                )
                )}

            </ul>
            <div className="tab-content">
                {languages && languages.map((language, i) =>
                (
                    <div id={`namestab${language.id}`} key={"lngupne" + i} className={selectedTabIndex === i ? "tab-pane p-3 active" : "tab-pane p-3"} >
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group kfont">
                                    <TextField
                                        fullWidth
                                        label="Name"
                                        defaultValue={names && names.find(x => x.language === language.id) ? names.find(x => x.language === language.id).name : ""}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) => { handleOnChange(e, language.id) }}
                                    />
                                </div>
                            </div>
                        </div>
                        {show_short_description>0 && (
                            <div className="row">
                                <div className="col-md-12">
                                    <div className={`form-group ${language.id==2 ? "kurdi" : ""}`}>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={names && names.find(x => x.language === language.id) ? names.find(x => x.language === language.id)?.short_description ?? "" : ""}
                                            onReady={editor => {
                                                // You can store the "editor" and use when it is needed.
                                            }}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                handleShortDescriptionChange(language.id, data)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        {show_description>0 && (
                            <div className="row">
                                <div className="col-md-12">
                                    <div className={`form-group ${language.id==2 ? "kurdi" : ""}`}>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={names && names.find(x => x.language === language.id) ? names.find(x => x.language === language.id).description ?? "" : ""}
                                            onReady={editor => {
                                                // You can store the "editor" and use when it is needed.
                                            }}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                handleDescriptionChange(language.id, data)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}


                    </div>
                ))}

                <div className="tab-pane" id="chapters">
                    Chapters
                </div>
            </div>
        </div>

    )
}

export default NamesWidgets