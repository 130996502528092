import { LanguageContext } from 'contexts/LanguageContext';
import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { Utils, translate } from 'utils';

function AuthorSummary({ author }) {

    const { setLanguage, translations } = useContext(LanguageContext);
    const _t = (key) => {
        return translate(key, translations)
    }

    return (
        <section className="course-summary section section-concept section-no-border section-angled section-angled-reverse pt-5 m-0" >
            {/* <div className="section-angled-layer-bottom section-angled-layer-increase-angle bg-light"></div> */}
            <div className="container ">
                <div className="row pt-3 pb-lg-0 pb-xl-0">
                    <div className="col-lg-6 mb-5 mb-lg-0 text-left">
                        <ul className="breadcrumb font-weight-semibold text-4 negative-ls-1 mb-3 kfont">
                            <li><Link to="/home">{_t("Home")}</Link></li>
                            <li className="text-color-primary"><Link to="/articles/">{_t("Articles")}</Link></li>
                            <li className="active text-color-primary">Author</li>
                        </ul>
                        <h1 className="font-weight-bold text-10 text-xl-12 line-height-2 mb-3">{author && Utils.getDefaultName(author)}</h1>
                        <p className="font-weight-light opacity-8 pb-2 mb-4">{author && Utils.parseDescription(author)}</p>
                        {/* <Link to={`/author/${author?.id}/#main`} data-hash data-hash-offset="100" className="btn btn-gradient-primary text-light opacity-5 font-weight-semi-bold px-4 btn-py-2 text-3">View details <i className="fas fa-arrow-down ml-1"></i></Link> */}

                    </div>
                    <div className="col-lg-6 pt-4 mb-5 mb-lg-0 ">
                        <img src={Utils.getImage(author)} className="instructor-image mx-auto d-block mb-3" alt={author && Utils.getDefaultName(author)} />
                    </div>

                </div>
            </div>
        </section>
    )
}

export default AuthorSummary