import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, TextField, TextareaAutosize } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { Edit } from "@mui/icons-material";
import Done from "view/cms/components/shared/Done";
import Error from "view/cms/components/shared/Error";
import LoadingInside from "view/cms/components/shared/LoadingInside";
import { BaseHttpService } from "services/base";
import SelectMediaType from "./SelectMediaType";
// import MWEditor from "view/cms/components/Widgets/editor/CKEditor";

function GalleryForm({ id, ...props }) {
  const { register, handleSubmit, setValue, watch, formState: { errors }, } = useForm();
  const [editingItem, setEditingItem] = useState(null);

  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [item_image, setImage] = useState(null);
  const [data, setData] = useState(null);

  const [file, setFile] = useState(false);

  const inputFileRef = useRef()

  useEffect(() => {
    if (id) {
      BaseHttpService.loadGallery(id).then(item => {
        setData(item)
        onEditing(item)
      })
    }
  }, [])

  const onEditing = (item) => {
    setEditingItem(item)


    for (const key in item) {
      setValue(key, item[key])
    }

    setRefresh(refresh + 1)
    setImage(item.image)
  }

  const saveChanges = (data) => {

    setError(false)
    if (editingItem) data.id = editingItem.id;

    var body = {
      "id": id ? id : data.id,
      "title": data.title,
      "type": data.type ?? "",
      "file": file
    }

    console.log(body)



    setDone(false)
    setLoading(true)
    BaseHttpService.SaveGalleryForm(body).then(response => {
      setLoading(false)
      setDone(true)
      setError(false)
      props.onEditDone(response);
    }).catch(err => {
      setLoading(false)
      // setError("Something went wrong")
    });
  };

  const handleValue = (value, name) => {
    switch (name) {
      case "type":
          // console.log(value)
          setValue("type", value)
          break;
      default:
        break;
    }
  }

  const handleFileChange = (e) => {
    var file = e.target.files[0]
    if (e.target.files)
        setFile(file)

}



  return (
    <div>


      <form className="mt-4" onSubmit={handleSubmit(saveChanges)}>
        <div className="row">
          <div className="col-md-8">
            <div className="form-group">
              <TextField
                fullWidth
                label="Title"
                value={watch('title') ?? ""}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                {...register("title")}
              />
            </div>
            
            <div className="form-group">
              <SelectMediaType callback={handleValue} value={watch("type")} border={"1"} key={refresh+"ts"} />
            </div>
          </div>

          <div className="col-md-4">
          <div className="form-group">
              <h5>image</h5>
              <input type="file" ref={inputFileRef} onChange={handleFileChange} className="form-control" />
              {editingItem && (
                <a href={editingItem.image} target="_blank"><img src={editingItem.image} style={{width:"200px", marginTop:20}} /></a>
              )}
            </div>
            
          </div>

        </div>
        <div className="form-group mt-3 text-right mb-1">
          <Button
            onClick={handleSubmit}
            type="submit"
            variant="contained"
            startIcon={editingItem ? <Edit /> : <AddIcon />}
          >
            {editingItem ? "Save Changes" : "Add Gallery"}
          </Button>


        </div>
        <div className="form-group">
          {done && <Done />}
          {error && <Error message={error} />}
          {loading && <LoadingInside />}
        </div>
      </form>
    </div>
  );
}

export default GalleryForm;
