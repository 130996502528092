import { HttpService } from "services/http";
import { Utils } from "utils";


export class SettingsHttpService {

    static async saveAboutItem(body) {

        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/api/about_item/${body.id}`, body);
        }
        else
            response = await service.post('/api/about_item/', body);

        return await response
    }

    static async loadAboutItem(id){
        var service = new HttpService();
        var response = await service.get(`/api/about_item/${id}`,null);
        return response;
    }

    static async loadAboutItems(){
        var query = "?size=100"

        var service = new HttpService();
        var response = await service.get(`/api/about_item/${query}`,null);
        return response;
    }

    static async saveSettings(body) {

        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/api/settings/${body.id}`, body);
        }
        else
            response = await service.post('/api/settings/', body);

        return await response
    }

    static async loadSettings(id){
        var service = new HttpService();
        var response = await service.get(`/api/settings/${id}`,null);
        return response;
    }

    
    static async saveFaq(body) {

        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/api/faq/${body.id}`, body);
        }
        else
            response = await service.post('/api/faq/', body);

        return await response
    }

    static async loadFaq(id){
        var service = new HttpService();
        var response = await service.get(`/api/faq/${id}`,null);
        return response;
    }

    static async loadFaqs(categoryId,q){
        var query = "?size=100"
        if(categoryId){
            query += "&category=" + categoryId
        }

        // let language = Utils.getCurrentLanguageId()

        // if(language!= null){
        //     query = query + `&language=${language}`
        // }


        if(q){
            query += "&query=" + q
        }

        var service = new HttpService();
        var response = await service.get(`/api/faq/${query}`,null);
        return response;
    }


    static async loadFaqsByCurrentLanguage(q){
        var query = "?size=100"

        let language = Utils.getCurrentLanguageId()

        if(language!= null){
            query = query + `&language=${language}`
        }


        if(q){
            query += "&query=" + q
        }

        var service = new HttpService();
        var response = await service.get(`/api/faq/${query}`,null);
        return response;
    }

}

