import React, { useEffect, useState } from 'react'
import { Utils } from 'utils'
import { useQuery } from 'react-query';
import { SettingsHttpService } from 'view/cms/features/feature_settings/services';
import PageLayout from 'view/components/shared/PageLayout'
import TranslateController from 'controller/shared/TranslateController';
import RegisterAndLoginForm from 'view/components/user/RegisterAndLoginForm';
import { useParams } from 'react-router-dom';
import ResetPanel from 'view/components/user/ResetPanel';

function ResetPasswordPage() {

	const { _t } = TranslateController();

	useEffect(() => {
		Utils.initiateTheme()
	}, [])

	// ?email_valid=True


	return (
		<PageLayout showbreadcrumbs={false} HeaderChild={
			<h1 className="text-color-light my-3 font-weight-bold text-10 text-sm-8 lan-kfont">{_t("Reset password")}</h1>
		}>

			<div className="row pt-md-2 pt-3 pb-4 lan-rtl lan-kfont ">
				<div className="col-lg-12 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="300">
					<ResetPanel />
				</div>
			</div>
		</PageLayout>


	)
}

export default ResetPasswordPage