import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { CourseHttpService } from 'services/course';

function FilterByGenderWidget({ courseId, onSelect }) {
  // Define the gender mapping based on the API response
  const genderMap = {
    Male: { value: 0, label: "Male" },
    Female: { value: 1, label: "Female" },
    Other: { value: 2, label: "Other" }
  };

  // State to store genders fetched from the server
  const [genders, setGenders] = useState([]);

  // Query to load genders from the server
  const { isLoading, error, data } = useQuery('genders', () => {
    return CourseHttpService.loadEnrolledGenders(courseId);
  }, {
    onSuccess: (data) => {
      // Convert the API response to an array of gender objects
      const formattedGenders = Object.keys(data).map(gender => ({
        ...genderMap[gender],  // Map the gender to the predefined value and label
        count: data[gender]    // Add the count from the API response
      }));
      setGenders(formattedGenders);  // Set the genders with count
    },
    refetchInterval: 10000,  // Adjust refetch interval if needed
  });

  const [selectedGenders, setSelectedGenders] = useState([]);

  const handleCheckboxChange = (genderValue, isChecked) => {
    setSelectedGenders(prevSelectedGenders => {
      let updatedGenders = prevSelectedGenders;

      if (isChecked) {
        // Add the gender value if it's not already selected
        if (!prevSelectedGenders.includes(genderValue)) {
          updatedGenders = [...prevSelectedGenders, genderValue];
          onSelect(updatedGenders); // Call the parent component's callback
        }
      } else {
        // Remove the gender value if it's unselected
        updatedGenders = prevSelectedGenders.filter(value => value !== genderValue);
        onSelect(updatedGenders); // Call the parent component's callback
      }

      return updatedGenders;
    });
  };

  // Handle loading state
  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Handle error state
  if (error) {
    return <div>Error loading genders.</div>;
  }

  return (
    <div className="mb-4">
      <h2>Gender</h2>
      <div>
        {genders.map(gender => (
          <div key={gender.value}>
            <div className="custom-checkbox-1 lan-rtl">
              <input
                id={`genderCheckbox` + gender.value}
                type="checkbox"
                name={`genderCheckbox` + gender.value}
                value={gender.value}
                onChange={(e) => handleCheckboxChange(gender.value, e.target.checked)}
              />
              <label htmlFor={`genderCheckbox` + gender.value}>
                {gender.label} ({gender.count})
              </label>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FilterByGenderWidget;
