import React from 'react'
import CmsHeader from './CmsHeader'

function CMSLayout({children}) {
  return (
    <div className="body">
            <CmsHeader />
            <div role="main" className="main">
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-lg-12">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </div>
  )
}

export default CMSLayout