import React, { useState } from 'react'
import { Utils } from 'utils';
import { useQuery } from 'react-query';
import CustomModal from 'view/components/modals/CustomModal';
import DeleteDialog from 'view/cms/components/shared/DeleteDialog';
import { Link } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CourseHttpService } from 'services/course';
import CourseCategoryForm from './CourseCategoryForm';

function CourseCategoryList() {
    const [categories, setCategories] = useState(null);
    const [selectedId, setSelectedId] = useState(0);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = React.useState(false);
    const [refresh, setRefresh] = useState(0);

    const { isLoading, error, data, refetch } = useQuery('course_categories', ()=>{ return CourseHttpService.loadCourseCategories() }, {
        onSuccess: (data) => {
            if(data.results){
                setCategories(data.results)
            }
        },
        refetchInterval: 60 * 10000,

        // cacheTime: 60*60*24*30*1000// Cache results for 30 days
    });



    const _handleEdit = (e, id) => {
        setSelectedId(id);
        setRefresh(refresh + 1)
        setEditModalIsOpen(true)
    }

    const _handleDelete = (e, id) => {
        setSelectedId(id);
        setRefresh(refresh + 1)
        setDeleteDialogIsOpen(true)
    }

    const _onDeleteDone = () => {
        var temp = categories.filter(item => item.id !== selectedId)
        setCategories(temp)
        setDeleteDialogIsOpen(false);
        setRefresh(refresh + 1)
    }

    const onDragEnd = (result) => {
        if (!result.destination) return; // dropped outside the list
        const items = Array.from(categories);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setCategories(items);
        // Print new ids
        var ids=[]
        items.forEach((item, index) => {
            ids.push(item.id);
        });

        var body={
            "ids": ids
        }

        CourseHttpService.saveCourseCategorySortOrder(body).then(() => {

        })
    };

    return (
        <div className='box'>
            <div className="box-header mb-5">
                <h5>Course Categories</h5>
                <div className="btns d-flex">

                    {/* <Link to="/cms/category/" className="btn btn-icon bg-warning mr-2" title="categories"><i className="fa fa-folder "></i></Link> */}
                    <button className="btn btn-icon bg-success" onClick={() => { setSelectedId(null); setEditModalIsOpen(true) }}><i className="fa fa-plus "></i></button>
                </div>
            </div>
            <div className="box-body general-list">
                <DragDropContext onDragEnd={onDragEnd}>

                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>
                                    #
                                </th>
                                <th> Title</th>
                                {/* <th>Parent</th> */}
                                <th>Status</th>
                                <th>

                                </th>
                            </tr>
                        </thead>
                        <Droppable droppableId="categories">
                            {(provided) => (
                                <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                    {categories &&
                                        categories.map((category, index) => (
                                            <Draggable key={"cat" + category.id} draggableId={"cat" + category.id} index={index}>
                                                {(provided) => (
                                                    <tr
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <td>{category.id}</td>
                                                        <td><Link onClick={(e) => { _handleEdit(e, category.id) }}>{Utils.getDefaultName(category)}</Link></td>
                                                        {/* <td>{category.parent && Utils.getDefaultName(category.parent)}</td> */}
                                                        <td>{category.status ? <> <i className="fa fa-check color-success"></i> </> : <><i className="fa fa-remove color-danger"></i></>}</td>
                                                        {/* <td>{Utils.getDate(category.created_at)}</td> */}
                                                        <td className='text-right'>
                                                            <a href="#" onClick={(e) => { _handleDelete(e, category.id) }} className="btn btn-outline   btn-swap-1">
                                                                <span><i className="fas fa-trash color-gray"></i></span>
                                                                <span>Delete <i className="fas fa-remove ms-2"></i></span>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                )}
                                            </Draggable>
                                        ))}
                                    {provided.placeholder}
                                </tbody>
                            )}
                        </Droppable>

                    </table>

                </DragDropContext>

                <CustomModal big key={editModalIsOpen} setOpen={(value) => setEditModalIsOpen(value)}
                    open={editModalIsOpen} >

                    <CourseCategoryForm id={selectedId} onEditDone={() => { refetch(); setEditModalIsOpen(false) }} />

                </CustomModal>


                <DeleteDialog
                    url={`/academy/course/category/${selectedId}`}
                    isOpen={deleteDialogIsOpen}
                    key={"mdl" + deleteDialogIsOpen + selectedId}
                    setOpen={setDeleteDialogIsOpen}
                    notifyDone={_onDeleteDone}
                />
            </div>
        </div>
    )
}

export default CourseCategoryList