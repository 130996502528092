import React, { useContext } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link, useParams } from "react-router-dom";
import { CourseHttpService } from '../../../../services/course';
import { translate, Utils } from '../../../../utils';
import { CheckCircleOutline } from '@mui/icons-material';
import { useQuery } from 'react-query';
function ContentsLightList({ lesson, course , expandParentAccordion=null,lecture_index }) {

    const [loading, setLoading] = useState(false);
    const { content } = useParams()

    const { isLoading, error, data } = useQuery(
        ['contents_by_lesson', lesson], 
        () => CourseHttpService.loadContents(lesson), 
        {
          refetchInterval: 3000, // Refetch every 15 seconds (15,000 milliseconds)
        }
      );

    return (
        <div className='box'>

            <div className="box-body ">
                <div >
                    <div>
                        {data?.results.map(item => {
                            let isDone = (item?.course_logs && item?.course_logs.length > 0) && item?.course_logs[0].ended != null
                            return (

                                <Link to={`/course/${course}/lesson/${lesson}/content/${item.id}`} key={`content_light_${item.id}`} className={`no-decoration content-link ${content == item.id ? "active" : ""} `} >
                                    <div className={`content-item content-item-light ${content == item.id ? "active" : ""} `} key={`ccontent${item.id}`}>
                                        <div className={`icon ${isDone ? "success" : ""}`}>
                                            {isDone ?
                                                (
                                                    <CheckCircleOutline />

                                                ) : item.url ? (
                                                    <>
                                                        {content == item.id ? <img src="/assets/images/svg/playon.svg" width="28" height="28" alt="" /> : <img src="/assets/images/svg/play-circle.svg" width="28" alt="" />}
                                                    </>
                                                    
                                                ) : (<img src="/assets/images/svg/book.svg" width="28" alt="" />)}
                                        </div>
                                        <div className="description">
                                            {Utils.getDefaultName(item)}
                                        </div>
                                    </div>
                                </Link>
                            )
                        }
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContentsLightList