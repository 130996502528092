import { Utils } from "utils";
import { HttpService } from "../http";


export class UserHttpService {


    static async resetPassword(body) {

        var service = new HttpService();
        var response = await service.patch('/auth/password-reset-complete', body);
        return response;
    }

    static async login(body) {
        var service = new HttpService();
        var response = await service.post('/auth/login/', body);
        return response;
    }

    static async checkPassword(body) {
        var service = new HttpService();
        var response = await service.post('/auth/check-password/', body);
        return response;
    }

    static async logout() {
        Utils.removeUserSession();
    };

    static async clearAuthentication() {
        
    };

    static async loadUser(id) {
        var service = new HttpService();
        var response = await service.get(`/auth/user/${id}`, null);
        return response;
    }

    static async saveUser(body) {
        var service = new HttpService();
        var response;

        if (body.id) {
            var id = body.id;
            delete body["id"]
            delete body["user"]

            response = await service.put(`/auth/user/${id}`, body);
        }
        else
            response = await service.post('/auth/register/', body);
        return response;
    }

    static async registerSocial(body) {
        var service = new HttpService();
        var response;

        response = await service.post('/auth/register/social/', body);
        return response;
    }

    static async loadProfile() {
        var service = new HttpService();
        var response = await service.get(`/auth/profile/me/`, null);
        return response;
    }

    static async saveProfile(body) {
        var service = new HttpService();
        var response;
        response = await service.post(`/auth/profile/update/`, body);
        return response;
    }

    static async updateProfileImage(body) {
        const formData = new FormData();
        formData.append("first_name", body.first_name);
        formData.append("last_name", body.last_name);

        if (body.file) formData.append("image", body.file, body.file.name);

        var service = new HttpService();
        var response;
        response = await service.post(`/auth/profile/update/`, formData, true);
        return response;
    }


    static async doLogin(user) {
        let body = {
            email: user.email,
            password: user.password,
        };

        var service = new HttpService();
        var response;
        response = await service.post(`/auth/login/`, body);

        return response;
    }

    static async registerParticipant(body) {
        var service = new HttpService();
        var response;

        response = await service.post('/auth/registerparticipant/', body);
        return response;
    }

    static async resendVerificationEmail(body) {
        var service = new HttpService();
        var response;

        response = await service.post('/auth/resendverificationemail/', body);
        return response;
    }




}

