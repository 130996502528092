import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { CourseHttpService } from 'services/course';
import { useQuery } from 'react-query';
import { HttpService } from 'services/http';
import GenderStatisticsChart from './GenderStatisticsChart';

const UserStatisticsChart = () => {

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [],
    });

    const { isLoading, error, data, refetch } = useQuery('statistics_country', () => { return CourseHttpService.loadCountryStatistics() }, {
        onSuccess: (data) => {
            const groups = data.map((item) => item.group);
            const userCounts = data.map((item) => item.user_count);

            const barColors = [
                'rgba(255,0 , 0, 0.6)', // Color for the first bar
                'rgba(75, 192, 192, 0.6)', // Color for the second bar
                'rgba(255, 206, 86, 0.6)', // Color for the third bar
                'rgba(153, 255, 102, 0.6)', // Color for the fourth bar
                // Add more colors if you have more groups
            ];
            

            setChartData({
                labels: groups,
                datasets: [
                    {
                        label: 'User Count',
                        data: userCounts,
                        // fillColor: ["rgba(54, 162, 235, 0.6)","rgba(220,0,10,0.5)","rgba(220,0,0,0.5)","rgba(120,250,120,0.5)" ],
                        backgroundColor: barColors.slice(0, userCounts.length), // Use only as many colors as there are bars
                    },
                ],
            });
        },
        // cacheTime: 60*60*24*30*1000// Cache results for 30 days
        refetchInterval: HttpService.DefaultRefetchInterval,
    });




    return (
        <div className='row'>
            <div className="col-md-12">
                <section className="">
                    {/* <p className="p-2">Number of users</p> */}
                    <div className="">

                        <Bar data={chartData} options={{ responsive: true  ,
                            plugins: {
                                legend: {
                                display: false,
                                position: 'bottom',
                                labels: {
                                    padding : 30,
                                }
                                },
                            },
                          }} />


                    </div>
                </section>
            </div>
            {/* <div className="col-md-4">
                <GenderStatisticsChart />
            </div> */}
        </div>

    );
};

export default UserStatisticsChart;
