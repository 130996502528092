import React, { useState } from 'react';
import AWS from 'aws-sdk';
import Done from '../../shared/Done';
import Error from '../../shared/Error';
import LoadingInside from '../../shared/LoadingInside';

const UploadToAmazon = ({ uploadFolder="podcasts", file_type="audio/*", onEditDone }) => {
  const [uploading, setUploading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);

  // Configure AWS SDK
  AWS.config.update({
    accessKeyId: "", //process.env.REACT_APP_AWS_ACCESS_KEY_ID, // Use environment variables to protect your keys
    secretAccessKey: "", //process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];

    if (!file) {
      alert('No file selected');
      return;
    }

    const S3_BUCKET ="kurdishacademy-video-streaming-bucket"; //process.env.REACT_APP_AWS_BUCKET_NAME;
    const REGION = "eu-north-1"; // process.env.REACT_APP_AWS_REGION;

    const s3 = new AWS.S3({
      region: REGION,
    });

    const params = {
      Bucket: S3_BUCKET,
      Key: uploadFolder+`/${Date.now()}_${file.name}`, // Include a timestamp to avoid name conflicts
      Body: file,
      ContentType: file.type,
    };

    try {
      setUploading(true);

      const data = await s3.upload(params).promise();

      console.log('File uploaded successfully:', data);
      setDone('File uploaded successfully.');

      const urlPath = new URL(data.Location).pathname;

      const cloudFrontUrlBase = 'https://d2avkh8hsb0jpt.cloudfront.net'

      var location = cloudFrontUrlBase + urlPath

      // Call the onEditDone callback with the uploaded file's URL
      onEditDone(location);
    } catch (err) {
      console.error('Error uploading file:', err);
      setError('There was an error uploading your file.')
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className='relative'>
      <p>{file_type=='video/*' ? 'Please select a video file' : 'Please select an audio file'}</p>
      <input
        type="file"
        accept={file_type}
        onChange={handleFileUpload}
        disabled={uploading}
      />
        {done && <Done message={done} />}
        {error && <Error message={error} />}
        {uploading && <LoadingInside />}
    </div>
  );
};

export default UploadToAmazon;
