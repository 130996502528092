import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, TextField, TextareaAutosize } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { Edit } from "@mui/icons-material";
import { PostHttpService } from "../../services";
import Done from "view/cms/components/shared/Done";
import Error from "view/cms/components/shared/Error";
import LoadingInside from "view/cms/components/shared/LoadingInside";
import ImageFieldWithGallery from "view/cms/components/Widgets/shared/ImageFieldWithGallery";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import SelectStatus from "./SelectStatus";
import SelectLanguage from "./SelectLanguage";
import SelectCategory from "./SelectCategory";
import SelectTags from "./SelectTags";
import SelectPeople from "./SelectPeople";
// import MWEditor from "view/cms/components/Widgets/editor/CKEditor";

function PostForm({ id, lesson, ...props }) {
  const { register, handleSubmit, setValue, watch, formState: { errors }, } = useForm();
  const [editingItem, setEditingItem] = useState(null);

  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [item_image, setImage] = useState(null);
  const [data, setData] = useState(null);
  const [authorModalIsOpen, setAuthorModalIsOpen] = useState(null);
  const [selectedAuthor, setSelectedAuthor] = useState(null);


  const fileRef = useRef()

  useEffect(() => {
    if (id) {
      PostHttpService.loadPost(id).then(item => {
        setData(item)
        onEditing(item)
      })
    }
  }, [])

  const onEditing = (item) => {
    setEditingItem(item)


    for (const key in item) {
      setValue(key, item[key])
    }

    if (item.category) {
      setValue("category", item.category.id)
    }

    if (item.author) {
      setValue("author", item.author.id)
    }

    if (item.language) {
      setValue("language", item.language.id)
    }

    setRefresh(refresh + 1)
    setImage(item.image)
  }

  function generateSlug(str) {
    // Convert the string to lowercase
    str = str.toLowerCase();

    // Replace spaces with hyphens
    str = str.replace(/\s+/g, '-');

    // Remove all non-alphanumeric characters (except hyphens and underscores)
    // str = str.replace(/[^\w\-]+/g, '');

    // Remove multiple consecutive hyphens
    str = str.replace(/\-\-+/g, '-');

    // Trim hyphens from the start and end of the string
    str = str.replace(/^-+/, '').replace(/-+$/, '');

    return str;
}

  const saveChanges = (data) => {

    setError(false)
    if (editingItem) data.id = editingItem.id;

    let slug = generateSlug(data.title);
    
    if(data.slug)
      slug = generateSlug(data.slug);

    var body = {
      "id": id ? id : data.id,
      "title": data.title,
      "slug": slug,
      "description": data.description,
      "short_description": data.short_description,
      "language": data.language,
      "category": data.category,
      "status": data.status ?? 1,
      "tags": data.tags,
      "keywords": data.keywords,
      "author": data.author,
      "image": item_image
    }

    console.log(body)



    setDone(false)
    setLoading(true)
    PostHttpService.savePost(body).then(response => {
      console.log(response)
      setLoading(false)
      setDone(true)
      setError(false)
      props.onEditDone(response);
    }).catch(err => {
      setLoading(false)
      // setError("Something went wrong")
    });
  };

  const handleValue = (value, name) => {
    switch (name) {

      case "status":
        setValue("status", value)
        break;
      case "author":
        setValue("author", value)
        break;
      case "category":
        setValue("category", value)
        break;
      case "language":
        setValue("language", value)
        break;
      case "tags":
        // console.log(value)
        setValue("tags", value)
        break;
      default:
        break;
    }
  }



  return (
    <div>


      <form className="mt-4" onSubmit={handleSubmit(saveChanges)}>
        <div className="row">
          <div className="col-md-8">
            <div className="form-group">
              <TextField
                fullWidth
                label="Title"
                value={watch('title') ?? ""}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                {...register("title")}
              />
            </div>
            <div className="form-group">
              <TextField
                fullWidth
                label="Slug"
                value={watch('slug') ?? ""}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                {...register("slug")}
              />
            </div>
            <hr className="solid" />

            <div className="form-group">
              <h5>Short description</h5>
              <CKEditor
                editor={ClassicEditor}
                // config={editorConfig}
                data={data && data.short_description}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setValue("short_description", data)
                }}
              />
            </div>
            <hr className="solid" />

            <div className="form-group">
              <h5>Description</h5>

              {/* <MWEditor
              data={data && data.description}
              onChange={(data) => {
                setValue("description", data)
              }} */}
              {/* /> */}
              <CKEditor
                editor={ClassicEditor}
                data={data && data.description}
                onReady={editor => {
                  // You can store the "editor" and use when it is needed.
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setValue("description", data)
                }}
              />
            </div>

          </div>

          <div className="col-md-4">
            <div className="form-group">
              <ImageFieldWithGallery type="article" key={item_image + "itimg"} defaultImageUrl={item_image} onImageSelected={setImage} />
            </div>
            <div className="form-group">
              <SelectCategory name="category" placeholder="Categoty" width={250} callback={handleValue} value={watch("category")} border={"1"} />
            </div>
            <div className="form-group">
              <SelectPeople type="author" name="author" placeholder="Author" width={250} callback={handleValue} value={watch("author")} border={"1"} />
            </div>
            {/* <div className="form-group">
              <button type="button" className="btn btn-secondary lan-kfont" onClick={() => setAuthorModalIsOpen(true)}>
                {"Select Author"}
              </button>
              <div className="general-list my-4">
                {selectedAuthor && (
                    <div>{Utils.getDefaultName(selectedAuthor)}, </div>
                )}
              </div>
            </div> */}
            <div className="form-group">
              <SelectLanguage placeholder="Language" width={250} callback={handleValue} value={watch("language")} border={"1"} />
            </div>

            <div className="form-group">
              <SelectStatus placeholder="Status" width={250} callback={handleValue} value={watch("status") ? 1 : 0} border={"1"} />
            </div>
            <div className="form-group hide">
              <SelectTags key={refresh + "tag"} onSelect={handleValue} defaultValue={watch("tags")} />
            </div>
            <div className="form-group ">
              <TextField
                fullWidth
                multiline
                label="Keywords"
                value={watch('keywords') ?? ""}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                {...register("keywords")}
              />
              <p className="text-2">split keywords using comma (,)</p>
            </div>
            {id && (
              <div className="form-group mt-3 text-right mb-1">
                <Button
                  onClick={handleSubmit}
                  type="submit"
                  variant="contained"
                  startIcon={editingItem ? <Edit /> : <AddIcon />}
                >
                  {editingItem ? "Save Changes" : "Add Post"}
                </Button>
              </div>
            )}

          </div>

        </div>
        <div className="form-group mt-3 text-right mb-1">
          <Button
            onClick={handleSubmit}
            type="submit"
            variant="contained"
            startIcon={editingItem ? <Edit /> : <AddIcon />}
          >
            {editingItem ? "Save Changes" : "Add Post"}
          </Button>
        </div>
        <div className="form-group">
          {done && <Done />}
          {error && <Error message={error} />}
          {loading && <LoadingInside />}
        </div>
      </form>
    </div>
  );
}

export default PostForm;
