import React from 'react'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import TranslateController from 'controller/shared/TranslateController';

function CustomDatePicker({ value, onChange , label="date" }) {

    const handleChange = (value) => {
        var gregorian_date = value.format('YYYY-MM-DD');
        console.log(gregorian_date)
        onChange(gregorian_date);
      };

      const { _t } = TranslateController()


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>

        <DesktopDatePicker
            label={_t(label)}
            inputFormat="YYYY-MM-DD"
            value={dayjs(value)}
            onChange={handleChange}
            className="full-width"
            renderInput={(params) => <TextField {...params} />} />
        </LocalizationProvider>
    )
}

export default CustomDatePicker