import React from 'react'
import CustomModal from 'view/components/modals/CustomModal';
import Sortable from './Sortable';

function SortModal({isOpen , setIsOpen , data , setData, type}) {
    if (!isOpen) return;
    return (
        <CustomModal className="" key={isOpen} setOpen={(value) => setIsOpen(value)}
            open={isOpen} >
            <Sortable type={type} items={data} setItems={setData} />
        </CustomModal>
    )
}
export default SortModal