import React, { useContext, useState } from 'react'
import { useQuery } from 'react-query';
import { CourseHttpService } from '../../../../services/course';
import Lessons from '../lesson/Lessons';
import TranslateController from 'controller/shared/TranslateController';
import { Utils } from 'utils';
import CustomAccordion from 'view/components/shared/CustomAccordion';

function CourseLecturesList({ course }) {

    const [lectures, setLectures] = useState(null)

    const { isLoading, error, data } = useQuery('lecture_by_course', () => { return CourseHttpService.loadLectures(course) }, {
        onSuccess: (data) => {
            if (data.results) {
                setLectures(data.results)
            }
            // Utils.initiateTheme()
        }
    });

    return (
        <div>
            <div >
                {lectures && lectures.map((item, i) => (
                    <LectureAccordion index={i} lecture={item} course={course} key={"laco" + item.id} />
                ))}
            </div>
        </div>
    )
}

function LectureAccordion({ lecture, course,index, open = false }) {
    const { _t } = TranslateController()


    const _getTitle = () => {
        return (
            <div className='d-block full-width'>
                <div className="text-3 text-md-4">{index+1} - {Utils.getDefaultName(lecture)}</div>
                <div className="lecture-information">
                    <div className='mr-3'>
                        <img src="/assets/images/svg/play-circle.svg" width={28} alt="" />

                        {lecture.lecture_report.Video.total_count} <span className='hide-on-small'>{_t(`videos`)}</span>
                    </div>
                    {lecture.lecture_report.Reading.total_count > 0 && (
                        <div className='mr-3'>
                            <img src="/assets/images/svg/book.svg" width={28} alt="" />
                            {lecture.lecture_report.Reading.total_count} <span className='hide-on-small'>{_t(`Reading`)}</span>
                        </div>
                    )}
                    {lecture.lecture_report.Quiz.total_count > 0 && (
                        <div className='mr-3'>
                            <img src="/assets/images/svg/quiz.svg" width={28} alt="" />
                            {lecture.lecture_report.Quiz.total_count} <span className='hide-on-small'>{_t(`Quizes`)}</span>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    const [isOpen, SetIsOpen] = useState(true);
    return (
        <div className="card card-default mb-3">
            <CustomAccordion open={open} TitleWidget={_getTitle} className="week-accordion">
                <div className="card-body p-1 mt-2">

                    {/* <div className="item-description mb-1">
                    {Utils.parseDescription(lecture)}
                </div> */}
                    <hr className='solid my-0' />
                    <Lessons lecture={lecture.id} course={course} />
                </div>
            </CustomAccordion>
        </div>

    )
}

export default CourseLecturesList