import React from 'react'
import { useParams } from 'react-router-dom'
import ContentViewAside from 'view/components/course/content/ContentViewAside';
import Header from 'view/components/shared/Header';
import Footer from 'view/components/shared/Footer';
import QuizViewController from 'controller/course/quiz/QuizViewController';
import QuizViewDetails from 'view/components/course/quiz/QuizViewDetails';
import PageHeader from 'view/components/shared/PageHeader';
import { Utils } from 'utils';

function QuizView() {
	const { course, lesson } = useParams();

	const { data, isLoading, error } = QuizViewController()


	return (
		<div className="body">
			<Header />

			<div role="main" className="main">
				<PageHeader showbreadcrumbs={false} title={data && Utils.getDefaultName(data)} />
				<div id='main'>
					<div className='custom-page-content p-relative z-index-2'>
						<div className="container custom-padding-top-1 custom-padding-bottom-1 px-lg-5 bg-light">

							<div className="container-large">
								<div className="row pb-4 mb-4 full-width lan-rtl ">
									<div className="col-md-4 mx-md-auto p-0">
										<ContentViewAside lesson_id={lesson} content={data} course={course} />
									</div>
									<div className="col-md-8 mx-md-auto ">
										<QuizViewDetails quiz={data} />

									</div>

								</div>
							</div>
						</div>
					</div>
				</div>

			</div>

			<Footer />
		</div>


	)
}

export default QuizView