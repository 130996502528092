import React, { useEffect, useState } from 'react'
import CourseForm from 'view/cms/components/forms/course/CourseForm'
import CmsHeader from 'view/cms/components/shared/CmsHeader'
import { useParams } from 'react-router-dom'
import { Utils } from 'utils'
import EnrolledUsersList from 'view/cms/components/lists/EnrolledUsersList'

function EnrolledUsersPage() {

    const [data, setData] = useState(null);
    const [count, setCount] = useState(0);
    const [show_form, setShowForm] = useState(false);
    const [loading, setLoading] = useState(false);

    const { id } = useParams();

    return (
        <div className="body">
            <CmsHeader />

            <div role="main" className="main">
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-lg-12">
                            <EnrolledUsersList courseId={id} />
                        </div>
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </div>
    )
}

export default EnrolledUsersPage