import { Avatar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { BaseHttpService } from 'services/base';
import { Utils } from 'utils';
import SupportMessageForm from 'view/cms/components/forms/shared/SupportMessageForm';
import CustomModal from 'view/components/modals/CustomModal';
import UserForm from './UserForm';

function UserLinearPreview({ id }) {
  const [user, setData] = useState(null);
  const [composeModalIsOpen, setComposeModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);

  useEffect(() => {
    if (id) {
      BaseHttpService.loadUserDetails(id).then(item => {
        setData(item)
      })
    }
  }, [])

  return (
    <>
      {user && (
        <div className='d-flex full-width flex-space-between align-items-center'>
          <div className="user-linear-preview d-flex align-items-center pointer" onClick={() => {  setEditModalIsOpen(true) }}>
            <Avatar alt={user.profile.first_name} src={Utils.getUserImage(user.profile)} className='mr-2' />
            <span className="kfont">{user.profile.first_name} {user.profile.last_name}</span>
          </div>
          <button className="btn btn-icon bg-primary pull-right" onClick={() => { setComposeModalIsOpen(!composeModalIsOpen) }}><i className="fa fa-envelope "></i></button>
        </div>
      )}

      <CustomModal medium key={composeModalIsOpen + "smf"} setOpen={(value) => setComposeModalIsOpen(value)}
        open={composeModalIsOpen}>

        <SupportMessageForm user={user} count={1} onDone={() => { }} />

      </CustomModal>

      <CustomModal big key={editModalIsOpen} setOpen={(value) => setEditModalIsOpen(value)}
        open={editModalIsOpen} >
        {user && (<UserForm id={user.profile.id} onEditDone={() => { setEditModalIsOpen(false) }} />)}
      </CustomModal>
    </>
  );
}

export default UserLinearPreview;
