import React, { useEffect, useState } from 'react'
import CourseForm from 'view/cms/components/forms/course/CourseForm'
import CmsHeader from 'view/cms/components/shared/CmsHeader'
import { useParams } from 'react-router-dom'
import { Utils } from 'utils'
import CourseInstructors from 'view/cms/components/Widgets/course/CourseInstructors'
import { CourseHttpService } from 'services/course'
import CourseReviews from 'view/cms/components/Widgets/course/CourseReviews'
import Lectures from 'view/cms/components/Widgets/course/Lectures'

function CourseDetailsPage() {

    const [data, setData] = useState(null);
    const [count, setCount] = useState(0);
    const [show_form, setShowForm] = useState(false);
    const [loading, setLoading] = useState(false);

    const { id } = useParams();

    useEffect(() => {

        if (id) {
            _getData()
        }

    }, [])

    const _getData = () => {
        setLoading(true);
        CourseHttpService.loadCourse(id).then(item => {
            setData(item)
            setLoading(false);
        })
    }

    return (
        <div className="body">
            <CmsHeader />

            <div role="main" className="main">
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-lg-12">
                            <div className="flex-align-items-center flex-space-between mb-3">
                                <h2 className='mb-0'>{data && Utils.getDefaultName(data)}</h2>
                                <button className="btn btn-icon bg-gray" onClick={(e) => { e.preventDefault(); setShowForm(!show_form) }}>
                                    <i className="fas fa-edit" ></i>
                                </button>
                            </div>
                            {show_form && (
                                <CourseForm id={id} />
                            )}

                            <Lectures course={id} />
                            <CourseInstructors course={data} />
                            <CourseReviews course={data} />
                        </div>
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </div>
    )
}

export default CourseDetailsPage