import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Avatar, LinearProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import { Utils } from 'utils';
import { UserHttpService } from 'view/cms/features/feature_user/service';
import UserBadge from 'view/cms/features/feature_user/components/UserBadge';

const TopOnlineUsersWidget = () => {
  const { isLoading, error, data, refetch } = useQuery(['users_list_online'], () => { return UserHttpService.loadOnlineUsers() }, {});

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='widget'>
      {isLoading && <LinearProgress style={{ width:"100%" }} />}
      {/* <p className='border-bottom'><b>Online</b></p> */}
      <table className="table">
        <tbody>
          {data?.results?.map((item) => (
            <tr key={item.id+"utr"} className="">
              <td className="d-flex">
                <Avatar 
                  alt={item.first_name} 
                  src={Utils.getUserImage(item)} 
                  style={{ marginRight: '10px' }}
                />
                <div className='d-flex space-between align-items-center full-width'>
                  <Link to={`/cms/user/${item.user}`} className="kfont">
                    {item.first_name} {item.last_name}
                    </Link> 
                    
                  <UserBadge last_online={item.last_online} />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TopOnlineUsersWidget;
