import TranslateController from 'controller/shared/TranslateController';
import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Utils } from 'utils';
import CustomModal from 'view/components/modals/CustomModal';
import EnrollmentWidget from '../enrollment/EnrollmentWidget';
import { HttpService } from 'services/http';
import LoginModal from 'view/components/user/LoginModal';
import { CourseHttpService } from 'services/course';

function CourseSummary({ course }) {

    const [enrollment, setEnrollment] = useState(null);
    const [enrollmentModalIsOpen, setEnrollmentModalIsOpen] = useState(false);
    const [loginModalIsOpen, setLoginModalIsOpen] = useState(false);
    var user = HttpService.getUser()

    const { _t } = TranslateController()

    const navigate = useNavigate()

    useEffect(() => {
        if (course != null && course?.enrollment != null) {
            setEnrollment(course?.enrollment)
        }

        return () => {

        }
    }, [course])



    const handleEnrollment = (enrollment) => {
        setEnrollment(enrollment);
        setEnrollmentModalIsOpen(false);
        // Redirect to course page
        navigate(`/course/${course?.id}/lectures/`); // Example URL, adjust as needed
    };

    const handleLoggedIn = () => {
        setLoginModalIsOpen(false)

        if (course != null) {
            CourseHttpService.loadCourse(course.id).then(course => {
                if(course.enrollment){
                    setEnrollment(course?.enrollment)
                    navigate(`/course/${course?.id}/lectures/`);
                }
                else{
                    setEnrollmentModalIsOpen(true)
                }
            })
        }
    }




    return (
        <section className="course-summary section section-concept section-no-border section-angled section-angled-reverse pt-5 m-0" >
            {/* <div className="section-angled-layer-bottom section-angled-layer-increase-angle bg-light"></div> */}
            <div className="container ">
                <div className="row pt-3 pb-lg-0 pb-xl-0 lan-rtl">
                    <div className="col-lg-6 pt-4  mb-lg-0 text-left lan-text-right">
                        <ul className="breadcrumb font-weight-semibold text-4 kfont negative-ls-1 hide-on-small">
                            <li className=''><a href="/explore">{_t("Browse")}</a></li>
                            <li className="text-color-primary"><a href="/courses/">{_t("Courses")}</a></li>
                            {/* <li className="active text-gray">Piano</li> */}
                        </ul>
                        <h1 className="font-weight-bold text-10 text-xl-12 text-sm-8  line-height-2 mb-1 full-width lan-text-right lan-rtl  ">{course && Utils.getDefaultName(course)}</h1>
                        <p className='text-2 font-weight-light level-intro mb-3'>{_t("Level")+" : "}{course && Utils.getDefaultName(course?.level)}</p>

                        <div className="font-weight-light hide-on-small opacity-8 pb-2 mb-2 text-light text-justify kfont">{course && Utils.parseShortDescription(course, 100)}</div>
                        {/* <Link href={`/course/${course?.id}/#main`} data-hash data-hash-offset="100" className="btn btn-gradient-primary font-weight-semi-bold px-4 btn-py-2 text-3">View details <i className="fas fa-arrow-down ml-1"></i></Link> */}
                        <EnrollButton />
                        {/* <p className="font-weight-light text-light text-4 opacity-8 mt-5 pb-2 mb-4">22,125 already enrolled</p> */}

                    </div>
                    <div className="col-lg-6 pt-4 mb-lg-0">
                        <video src={(course && course.intro_url !=null) ? course.intro_url : "/assets/video/kt.mp4"} poster={(course && course.image !=null) ? course.image : ""}  controls className='full-width' />
                    </div>
                </div>
            </div>
            <LoginModal key={loginModalIsOpen} setOpen={setLoginModalIsOpen} setLoggedIn={handleLoggedIn} isOpen={loginModalIsOpen} />
            <EnrollmentModal />

        </section>
    )

    function EnrollButton() {
        return (
            <>
                {!enrollment && (
                    <button onClick={() => {
                        if (!user) {
                            setLoginModalIsOpen(true);
                        } else setEnrollmentModalIsOpen(true)
                    }} className="kfont btn btn-primary btn-outline btn-outline-thin btn-outline-light-opacity-2 font-weight-semi-bold px-4 btn-py-2 text-4 text-color-light text-color-hover-dark ml-2" >
                        {_t("Enroll For Free")} <i className="fa fa-star ml-1"></i>
                    </button>
                )}

                {enrollment && (
                    <Link to={`/course/${course?.id}/lectures`} className="kfont btn btn-primary btn-outline btn-outline-thin btn-outline-light-opacity-2 font-weight-semi-bold px-4 btn-py-2 text-4 text-color-light text-color-hover-dark ml-2" >
                        {_t("Go to the Course")} <i className="fa fa-star ml-1"></i>
                    </Link>
                )}

            </>
        )
    }

    function EnrollmentModal() {
        if (!enrollmentModalIsOpen) return;
        return (

            <CustomModal className="" key={enrollmentModalIsOpen} setOpen={(value) => setEnrollmentModalIsOpen(value)}
                open={enrollmentModalIsOpen} >

                <EnrollmentWidget course={course} onEnrollment={handleEnrollment} />


            </CustomModal>
        )
    }

}

export default CourseSummary