import React, { useState } from 'react'
import Footer from 'view/components/shared/Footer'
import Header from 'view/components/shared/Header'
import PageHeader from 'view/components/shared/PageHeader'
import SearchList from '../components/main/SearchList'
import SearchAside from '../components/shared/SearchAside'
import PageLayout from 'view/components/shared/PageLayout'

function SearchPage() {
	
	const [filterOptions, setFilterOptions] = useState({
        categories: [],
        levels: [],
    });

	return (
		<PageLayout showbreadcrumbs={false}>
				<div className="container-large">
					<div className="row pb-4 mb-4 full-width lan-rtl">
						<div className="col-md-3 mx-md-auto">
							<SearchAside filterOptions={filterOptions} setFilterOptions={setFilterOptions} />
						</div>
						<div className="col-md-9 mx-md-auto">
							<SearchList filterOptions={filterOptions} />
						</div>

					</div>
				</div>
		</PageLayout>
	)
}

export default SearchPage