import React, { useState } from 'react'
import CMSLayout from 'view/cms/components/shared/CMSLayout'
import PostsList from '../components/PostsList'

function PostsPage() {

  

  return (
    <CMSLayout> 
        <PostsList />
    </CMSLayout>
  )
}

export default PostsPage