import React, { useState } from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappShareButton,
  TelegramShareButton,
  WhatsappIcon,
  TelegramIcon
} from 'react-share';

const SocialShareButtons = ({ url, title,showCopy=false }) => {

  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  const iconSize = window.innerWidth <= 480 ? 40 : 60;


  return (
    <div className="social-share-buttons mb-3">
      <FacebookShareButton className='mx-1' url={url} quote={title}>
        <FacebookIcon  size={iconSize} round />
      </FacebookShareButton>
      <TwitterShareButton className='mx-1' url={url} title={title}>
        <TwitterIcon size={iconSize} round />
      </TwitterShareButton>
      <LinkedinShareButton className='mx-1' url={url} title={title}>
        <LinkedinIcon size={iconSize} round />
      </LinkedinShareButton>
      <WhatsappShareButton className='mx-1' url={url} title={title}>
        <WhatsappIcon size={iconSize} round />
      </WhatsappShareButton>
      <TelegramShareButton className='mx-1' url={url} title={title}>
        <TelegramIcon size={iconSize} round />
      </TelegramShareButton>
      { showCopy && (
        <>
          <button className='btn btn-primary btn-copy' title='Copy url' onClick={copyToClipboard} style={{ cursor: 'pointer', width:iconSize, height:iconSize }}>
            <i className={`fa fa-copy ${copied ? 'color-success' : ''}`}></i>
          </button>
          
        </>
      )}
    </div>
  );
};

export default SocialShareButtons;
