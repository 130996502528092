import TranslateController from 'controller/shared/TranslateController';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { LinkedinIcon } from 'react-share';
import { CourseHttpService } from 'services/course';
import { HttpService } from 'services/http';
import LoadingInside from 'view/cms/components/shared/LoadingInside';
import PageLayout from 'view/components/shared/PageLayout';
import SocialShareButtons from 'view/features/feature_blog/components/SocialShareButtons';

function CertificatePage() {
  const { uid } = useParams();
  const [certificate, setData] = useState(null);

  const { _t } = TranslateController();

  const { isLoading, error, data } = useQuery(['user_certificate', uid], () => { return CourseHttpService.loadCertificate(uid) }, {
    onSuccess: (data) => {
      console.log(data);
      setData(data);
    }
  });

  return (
    <PageLayout
      showbreadcrumbs={false}
      HeaderChild={<></>}
    >
      <div className="">
        {isLoading && (<LoadingInside isLoading={isLoading} />)}
        {certificate && (
          <div className='certificate-container'>
            <img src={HttpService.ApiBase + "/" + certificate.file_url} alt="certificate" />

            <div className="mt-5 mb-5">
              <SocialShareButtons url={`${HttpService.ApiBase + "/" + certificate.file_url}`} />
            </div>
          </div>
        )}
      </div>

    </PageLayout>
  );
}

export default CertificatePage;
