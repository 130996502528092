import React from 'react'

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function LoadingInside({loading}) {
  return (
    <div className="inner-loading ">
        <CircularProgress color="inherit" />
    </div>   
  )
}

export default LoadingInside