import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, TextField, TextareaAutosize } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { Edit } from "@mui/icons-material";
import Done from "view/cms/components/shared/Done";
import Error from "view/cms/components/shared/Error";
import LoadingInside from "view/cms/components/shared/LoadingInside";
import { SettingsHttpService } from "../services";
import NamesWidgets from "view/cms/components/forms/shared/NamesWidgets";

function AboutItemForm({ id, lesson, ...props }) {
  const { register, handleSubmit, setValue, watch, formState: { errors }, } = useForm();
  const [editingItem, setEditingItem] = useState(null);

  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [names, setNames] = useState([]);
  const [namesRefresh, setNamesRefresh] = useState(0);
  const [data, setData] = useState(null);


  const fileRef = useRef()

  useEffect(() => {
    if (id) {
      SettingsHttpService.loadAboutItem(id).then(item => {
        setData(item)
        onEditing(item)
      })
    }
  }, [])

  const onEditing = (item) => {
    setEditingItem(item)


    for (const key in item) {
      setValue(key, item[key])
    }

    setNames(item.names)
    setNamesRefresh(namesRefresh+1)
    setRefresh(refresh + 1)
  }

  const saveChanges = (data) => {

    setError(false)
    if (editingItem) data.id = editingItem.id;

    var body = {
      "id": id ? id : data.id,
      "names": names,
    }


    setDone(false)
    setLoading(true)
    SettingsHttpService.saveAboutItem(body).then(response => {
      setLoading(false)
      setDone(true)
      setError(false)
      props.onEditDone(response);
    }).catch(err => {
      setLoading(false)
      // setError("Something went wrong")
    });
  };




  return (
    <div>


      <form className="mt-4" onSubmit={handleSubmit(saveChanges)}>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <NamesWidgets show_short_description={0} key={namesRefresh + "nms"} names={names} setNames={(names) => { setNames(names) }} />

            </div>
            <hr className="solid" />

          </div>
        </div>
        <div className="form-group mt-3 text-right mb-1">
          <Button
            onClick={handleSubmit}
            type="submit"
            variant="contained"
            startIcon={editingItem ? <Edit /> : <AddIcon />}
          >
            {editingItem ? "Save Changes" : "Add About Item"}
          </Button>


        </div>
        <div className="form-group">
          {done && <Done />}
          {error && <Error message={error} />}
          {loading && <LoadingInside />}
        </div>
      </form>
    </div>
  );
}

export default AboutItemForm;
