import { Button, Checkbox, FormControl, FormControlLabel, InputLabel, Input, Radio } from '@mui/material';
import React, { useContext, useState } from 'react';
import { Utils } from 'utils';
import QuizQuestionChoices from './QuizQuestionChoices';
import Error from 'view/cms/components/shared/Error';
import Loading from 'view/cms/components/shared/Loading';
import QuizViewQuestionsController from 'controller/course/quiz/QuizViewQuestionsController';
import QuizDone from 'view/components/course/quiz/QuizDone';

function QuizViewQuestions({ quiz, ...props }) {

    const { questions, loading, error, done, selectedChoices, termsChecked, name, handleChoiceChange,
        handleTermsChecked, handleNameChange, saveChanges, _t,setDone ,grade} = QuizViewQuestionsController({ quiz: quiz, props })

    const choices = Object.entries(selectedChoices).length;

    const isSaveButtonDisabled = choices !== quiz?.questions?.length; // || !termsChecked || name.trim() === ''


    return (
        <div className='mt-3 mb-5'>

            {done && <QuizDone quiz={quiz} grade={grade} onRetake={()=>{ setDone(false); }} onBack={()=>{ props?.onBack(); }} />}
            {!done && (
                <>
                    {questions?.map(item => (
                        <div className={`card card-default mb-2 ${(selectedChoices[item.id]) ? '' : error && 'box-error'}`} key={`cquestion${item.id}`}>
                            <div className="card-header">
                                <h4 className="card-title m-0">
                                    {item?.names ? Utils.getDefaultName(item) : `question ${item.id}`}
                                </h4>
                            </div>
                            <div className="card-body">
                                <QuizQuestionChoices question={item} onChange={choiceId => handleChoiceChange(item.id, choiceId)} />
                            </div>
                        </div>
                    ))}

                    {/* <div className={`lan-kfont agree ${(error && !termsChecked) ? 'box-error' : ''}`}>
                        <FormControlLabel
                            control={<Checkbox checked={termsChecked} onChange={handleTermsChecked} />}
                            label={_t('I understand that submitting work that isn’t my own may result in permanent failure of this course or deactivation of my account.')}
                        />
                    </div> */}
                    {/* <div className='lan-kfont'>
                        <FormControl>
                            <InputLabel htmlFor="name-input">{_t('Legal Name')}</InputLabel>
                            <Input id="name-input" value={name} onChange={handleNameChange} />
                            <div className="additional-text">{_t('Use the name on your government-issued ID')}</div>
                        </FormControl>
                    </div> */}
                    <Button className='kfont btn bg-primary text-light mt-4' onClick={saveChanges.mutate} disabled={isSaveButtonDisabled}>
                        {_t('Submit answer')}
                    </Button>
                    {loading && <Loading />}
                    {error && <Error message={error} />}

                </>
            )}
            {/* {done && <Done message={_t("Answers submitted")} />} */}


        </div>
    );

}


export default QuizViewQuestions;
