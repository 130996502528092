import React, { useState } from 'react'
import { HttpService } from '../../../../../services/http';
import { UserHttpService } from '../../../../../services/user';
import { useForm } from "react-hook-form";

function CmsLoginForm() {


    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const OnSubmit = (data) => {
        if (loading) return;

        setLoading(true);
        var body = {
            "email": data.email,
            "username": data.email,
            "password": data.password
        }
        UserHttpService.login(body).then(response => {
            setLoading(false);
            if (response.role) {
                HttpService.setToken(response.tokens.access)
                HttpService.setUser(response)

                if (response.role == 'admin' || response.role == 'editor') {
                    window.location.href = "/cms/dashboard"
                }
                else {
                    window.location.href = "/"
                }
            }
            else {
                console.log(response)
                if(response.detail) {
                    alert(response.detail)
                }
                else if(response.email){
                    alert(response.email)
                }
                else{
                    alert(response[0])
                }

            }
        });
    };


    return (
        <form className="needs-validation" onSubmit={handleSubmit(OnSubmit)} >
            <div className="form-row">
                <div className="form-group col">
                    <label className="text-color-dark text-3">Email address <span className="text-color-danger">*</span></label>
                    <input type="text" error={errors.email} {...register("email", { required: true })} className={`form-control form-control-lg text-4 ${errors.email ? "has_error" : ""} `} required />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col">
                    <label className="text-color-dark text-3">Password <span className="text-color-danger">*</span></label>
                    <input type="password" error={errors.password} {...register("password", { required: true })} required className={`form-control  form-control-lg text-4 ${errors.password ? "has_error" : ""} `} placeholder="Password" />

                </div>
            </div>
            <div className="form-row justify-content-between">
                <div className="form-group col-md-auto">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="rememberme" />
                        <label className="custom-control-label cur-pointer text-2" htmlFor="rememberme">Remember Me</label>
                    </div>
                </div>
                <div className="form-group col-md-auto">
                    <a className="text-decoration-none text-color-dark text-color-hover-primary font-weight-semibold text-2" href="#forgot">Forgot Password?</a>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col">
                    <button type="submit" className="btn btn-dark btn-modern btn-block text-uppercase rounded-0 font-weight-bold text-3 py-3" data-loading-text="Loading...">Login</button>
                </div>
            </div>
        </form>
    )
}

export default CmsLoginForm