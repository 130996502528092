import React, { useState } from 'react'
import { useQuery } from 'react-query';
import { Utils } from 'utils';
import { SettingsHttpService } from 'view/cms/features/feature_settings/services';
import CustomAccordion from 'view/components/shared/CustomAccordion';

function FaqsList({className}) {


    const [faqs, setFaqs] = useState(null);

    const { isLoading, error, data, refetch } = useQuery('faqs', () => { return SettingsHttpService.loadFaqsByCurrentLanguage() }, {
        onSuccess: (data) => {
            setFaqs(data.results)
        },
        // cacheTime: 60*60*24*30*1000// Cache results for 30 days
        refetchInterval: 60 * 100000,
    });


    return (
        <div className="d-block " data-appear-animation="fadeInUpShorter" data-appear-animation-delay="100">

            {faqs && faqs.map(faq => (
                <CustomAccordion className={className} key={"faqs_"+faq.id}  open={false} title={faq.question}>
                    <div className="text-justify l lan-rtl my-3">
                        {Utils.parse(faq.answer+"")}
                    </div>
                </CustomAccordion>
            ))}
        </div>
    )
}

export default FaqsList