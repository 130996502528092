import { useInfiniteQuery, useQuery } from 'react-query';
import { SearchHttpService } from '../core/service';
import { CourseHttpService } from 'services/course';

/**
 * A custom React hook for fetching and paginating explore posts.
 * @returns {{
 *   data: any,
 *   isLoading: boolean,
 *   isError: boolean,
 *   hasNextPage: boolean,
 *   refetch : () => void,
 *   fetchNextPage: () => void
 * }}
 */
function UserSearchList(query = "" , filterOptions = { 
    categories: [],
    levels: [],}) {
    
    const {
        data,
        isLoading,
        isError,
        refetch
    } = useQuery(
        ["search_results",query,filterOptions],
        ()=>{ return CourseHttpService.loadCourses(query,filterOptions.categories,filterOptions.levels) },
        {
            onSuccess: (data) => {
                // console.log(data);
            }
        }
    );

    return { data, isLoading, isError,refetch};
}

export default UserSearchList;