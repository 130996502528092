import { QuestionMarkOutlined } from '@mui/icons-material';
import React, { useContext } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link, useParams } from "react-router-dom";
import { LanguageContext } from '../../../../../../contexts/LanguageContext';
import { CourseHttpService } from '../../../../../../services/course';
import DeleteDialog from '../../../shared/DeleteDialog';
import { useQuery } from 'react-query';
import { translate } from 'utils';
import CustomModal from 'view/components/modals/CustomModal';
import TrackForm from './TrackForm';

function Tracks({ contentId }) {

    const { isLoading, error, tracks } = useQuery(['tracks', contentId], () => CourseHttpService.loadTracks({ content: contentId }), {
        onSuccess: (tracks) => {
            setData(tracks.results)
        }
    });

    const [data, setData] = useState(null);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = React.useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = React.useState(false);
    const [questionModalIsOpen, setQuestionModalIsOpen] = React.useState(false);

    const [selectedId, setSelectedId] = React.useState(0);
    const { id } = useParams();
    const [refresh, setRefresh] = React.useState(0);

    const { setLanguage, translations } = useContext(LanguageContext);
    const _t = (key) => {
        return translate(key, translations)
    }


    const _handleDelete = (e, id) => {
        setSelectedId(id);
        setRefresh(refresh + 1)
        setDeleteDialogIsOpen(true)
    }

    const _onDeleteDone = () => {
        var temp = data.filter(item => item.id !== selectedId)
        setData(temp)
        setDeleteDialogIsOpen(false);
        setRefresh(refresh + 1)
    }

    const _edit = (e, item) => {
        e.preventDefault();
        setSelectedId(item.id);
        setEditModalIsOpen(true)
        setRefresh(refresh + 1)
    }

    const _showQuestions = (e, item) => {
        e.preventDefault();
        setSelectedId(item.id);
        setQuestionModalIsOpen(true)
        setRefresh(refresh + 1)
    }



    return (
        <div className='box mt-5'>
            <div className="box-header mb-5">
                <h5>{_t('Tracks')}</h5>
                <div className="btns">
                    <button className="btn btn-primary" onClick={() => { setSelectedId(null); setEditModalIsOpen(true) }}><i className="fa fa-plus "></i></button>
                </div>
            </div>
            <div className="box-body">
                {isLoading && <div className="">Loading...</div>}
                <div >
                    <div className="accordion accordion-modern" id="quizsaccordion">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th className="hide-on-small">Language</th>
                                    <th className="hide-on-small">Is Default</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.length > 0 ? (
                                    data.map((track, index) => (
                                        <tr key={track.id}>
                                            <td>{index + 1}</td>
                                            <td>{track.label}</td>
                                            <td className="hide-on-small">{track.src_lang}</td>
                                            <td className="hide-on-small">{track.is_default ? "Yes" : "No"}</td>
                                            <td>
                                                <a href={track.src} className="btn btn-accordion" download target="_blank" rel="noopener noreferrer">
                                                    <i className="fa fa-download"></i>
                                                </a>
                                                <button className="btn btn-accordion" onClick={(e) => { e.preventDefault(); _edit(e, track) }}>
                                                    <i className="fas fa-edit" ></i>
                                                </button>
                                                <button className="btn btn-accordion" onClick={(e) => { e.preventDefault(); _handleDelete(e, track.id) }}>
                                                    <i className="fas fa-trash" ></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="6" style={{ textAlign: "center" }}>
                                            No tracks available
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>

            {editModalIsOpen && (
                <CustomModal className="" key={editModalIsOpen} setOpen={(value) => setEditModalIsOpen(value)}
                    open={editModalIsOpen} >

                    <TrackForm key={`trf${selectedId}`} contentId={contentId} id={selectedId}
                        onEditDone={(track) => {
                            let index = data.findIndex(item => item.id === track.id);

                            if (index > -1) {
                                data[index].label = track.label;
                                data[index].src_lang = track.src_lang;
                                data[index].is_default = track.is_default;
                                data[index].src = track.src;
                            }
                            else {
                                data.push(track);
                            }
                            setData(data)
                        }}
                    />
                </CustomModal>
            )}




            {deleteDialogIsOpen && (
                <DeleteDialog
                    url={`/academy/course/track/${selectedId}`}
                    isOpen={deleteDialogIsOpen}
                    key={"mdl" + deleteDialogIsOpen + selectedId}
                    setOpen={setDeleteDialogIsOpen}
                    notifyDone={_onDeleteDone}
                />
            )}

        </div>
    )
}

export default Tracks