import React, { useEffect } from 'react'
import Select from 'react-select';

export default function SelectStatus({ callback, value, placeholder="Status", width ,name="status" }) {

    
    const customStyles = {
        control: (base, state) => ({
            ...base,

            background: "white",
            // match with the menu
            width: width ? width : "200px",
            // Overwrittes the different states of border
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                // Overwrittes the different states of border
            }
        }),
        menu: base => ({
            ...base,
            width:"200px",
            '*::-webkit-scrollbar': {
                width: '0.4em'
            },
            '*::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
            },
            '*::-webkit-scrollbar-thumb': {
                backgroundColor: '#c7c7c7',
            },
            // override border radius to match the box
            borderRadius: 0,
            
            borderBottomRightRadius: 5,
            borderBottomLeftRadius: 5,
            // kill the gap
            marginTop: 0,
            backgroundColor: "#fff",
            opacity: "0.97"

        }),
        menuList: base => ({
            ...base,
            // kill the white space on first and last option
            padding: 10,
            "&:hover": {
                borderBottomRightRadius: 5,
                borderBottomLeftRadius: 5,
                transition: "all 0.5s ease-out"
            }
        }),
        option: (base, state) => {


            let backgroundColor = "transparent";

            if (state.isSelected) {
                backgroundColor = "#ccc";
            }

            if (state.isFocused) {
                backgroundColor = "#ccc";
            }
            if (state.isActive) {
                backgroundColor = "#ccc";
            }

            return {
                ...base,
                color: "#000",
                backgroundColor,
                transition: "all 0.5s ease-out"
            };
        }

    };

    var options = [
        {
            label: "Active",
            value: 1
        },
        {
            label : "Deactive",
            value : 0
        },
    ]

    const handleSelect = (e) => {
        var value = e.value;
        callback(value, name)

    }

    return (
        <>
            <div className="filter-select">
                <Select options={options}
                    styles={customStyles}
                    onChange={handleSelect}
                    placeholder={placeholder}
                    value={options && options.filter(obj => obj.value === value)}


                />
            </div>
            
        </>
    )
}
