import { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { CourseHttpService } from '../../../services/course'
import { Utils, translate } from 'utils'
import { LanguageContext } from 'contexts/LanguageContext'

function ContentController(lesson) {
  const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    const { setLanguage, translations } = useContext(LanguageContext);
    const _t = (key) => {
        return translate(key, translations)
    }
    useEffect(() => {

        _getData()

        return () => {
        }
    }, [])

    const _getData = () => {
        setLoading(true);
        CourseHttpService.loadContents(lesson).then((items) => {
            if (items.results) {
                setData(items.results)
            }
        });
    }



  return { data,  }
}

export default ContentController