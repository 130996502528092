import TranslateController from 'controller/shared/TranslateController';
import React from 'react'
import { Utils } from 'utils';

function LectureBreadCrumb({lecture,courseData}) {
    const { _t } = TranslateController()

    return (
        <nav aria-label="breadcrumb ">
            <ul className="breadcrumb text-5 negative-ls-1">
                <li><a href={`/course/${courseData && courseData.id}`}  onClick={(e)=>{ e.preventDefault(); }}>{courseData && Utils.getDefaultName(courseData)}</a></li>
                {/* <li className="text-color-primary"><a href={`/course/${courseData && courseData.id}/lectures/`} onClick={(e)=>{ e.preventDefault(); }}>{_t("Weeks")}</a></li> */}
                <li className="active text-color-primary">{lecture && Utils.getDefaultName(lecture)}</li>
            </ul>
        </nav>
    )
}

export default LectureBreadCrumb