import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Link, useParams } from 'react-router-dom'
import PageHeader from 'view/components/shared/PageHeader'
import { PostHttpService } from 'view/cms/features/feature_blog/services'
import AuthorSummary from '../components/AuthorSummary'
import Footer from 'view/components/shared/Footer'
import Header from 'view/components/shared/Header'
import { Utils } from 'utils'

function AuthorDetails() {
	const { id } = useParams();

	const [posts, setPosts] = useState(null);

	const { isLoading, error, data } = useQuery(['author', id], () => { return PostHttpService.loadAuthor(id) }, {
		onSuccess: (data) => {
			console.log(data)
			if (data.posts) {
				setPosts(data.posts)
			}
		},
		refetchInterval: 600 * 10000,

	});


	return (
		<div className="body">
			<Header />

			<div role="main" className="main">
				<PageHeader child={<AuthorSummary author={data} />} showbreadcrumbs={false} />

				<div id='main'>
					<div className='custom-page-content p-relative z-index-2'>
						<div className="container custom-padding-top-1 custom-padding-bottom-1 px-lg-5 bg-light">
							{posts && posts.map((post, index) => {
								return (
									<article key={`bs1_post_${post.id}`} className="thumb-info thumb-info-no-zoom bg-transparent border-radius-0 pb-1 mb-0">
										<div className="row align-items-center pb-1">
											<div className="col-md-5">
												<Link to={`/post/${post.id}`}>
													<img src={post.image} className="img-fluid border-radius-0" alt={post.title} />
												</Link>
											</div>
											<div className="col-md-7 ps-sm-1 mb-sm-2 ">
												<div className="thumb-info-caption-text  hide-on-small">
													<div className="thumb-info-type text-light text-uppercase d-inline-block bg-color-dark px-2 m-0 mb-1 float-none">
														<Link to={`/articles/${post.category.id}`} className="text-decoration-none text-color-light kfont">{Utils.getDefaultName(post.category)}</Link>
													</div>
													<h2 className="d-block line-height-2 text-4 text-dark font-weight-bold mt-1 mb-0">
														<Link to={`/post/${post.id}`} className="text-decoration-none text-color-dark text-color-hover-primary">{post.title}</Link>
													</h2>
													<p className="line-height-2 text-2 mb-0">{Utils.parse(post.short_description)}</p>

												</div>
												<div className="thumb-info-title bg-title-fade full-width p-4 show-on-small">
													<div className="thumb-info-type bg-color-dark px-2 mb-1 kfont float-none">{Utils.getDefaultName(post.category)}</div>
													<div className="thumb-info-inner mt-1">
														<h2 className="font-weight-bold text-color-light line-height-2 text-5 mb-0">{post.title}</h2>
													</div>
												</div>
											</div>

										</div>
										<hr className={`my-2 ${index == 3 && "pb-2"}`} />
									</article>
								)
							}
							)}

						</div>
					</div>
				</div>

			</div>

			<Footer />
		</div>


	)
}

export default AuthorDetails