import React, { useEffect, useState } from 'react'
import { UserHttpService } from 'services/user';
import CustomModal from '../modals/CustomModal';
import TranslateController from 'controller/shared/TranslateController';


export default function CheckUserProfileCompleted() {

    const [modalIsOpen, setModalIsOpen] = useState(false)

    const { _t } = TranslateController();

    useEffect(() => {
        UserHttpService.loadProfile().then(profile => {
            if(!profile?.livingcity_id){
                setModalIsOpen(true)
            }
        });
    }, []);


    return (
        <CustomModal big key={modalIsOpen} setOpen={(value) => setModalIsOpen(value)}
            open={modalIsOpen}  >
            {/* <div className="btn btn-back text-light" onClick={closeModal}><i className="fa fa-arrow-left"></i></div> */}
            <div className="text-center">
                <h2 className='text-3-5'>{_t("Please complete your profile")}</h2>
                <a href="/profile" className="btn btn-modern btn-primary btn-arrow-effect-1 py-2 px-3  kfont">{_t("Go to profile")} <i className="fas fa-arrow-right ms-2"></i></a>
            </div>
        </CustomModal >


    )
}
