import React, { useState, useRef } from 'react';
import { HttpService } from 'services/http';
import { UserHttpService } from 'services/user';
import Loading from '../../shared/Loading';
import LoadingInside from '../../shared/LoadingInside';

function ProfileImageWidget({ profile }) {
    const [selectedImage, setSelectedImage] = useState(null);
    const [loading, setLoading] = useState(false);

  const fileInputRef = useRef(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(URL.createObjectURL(file));
    // console.log(URL.createObjectURL(file))
    // You can perform additional operations like uploading the image to a server here
    _saveProfileImage(file)
  };

  const _saveProfileImage = (file) => {
    let body={
        first_name: profile.first_name,
        last_name: profile.last_name,
        file : file
    }
    setLoading(true)

    UserHttpService.updateProfileImage(body).then((response)=>{
        setLoading(false)
        console.log(response);
    })
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="profile-image-container">
      <div className="img-container">
      {loading && <LoadingInside />}

        <img
          src={selectedImage ? selectedImage : (profile && profile.image) ? `${HttpService.SiteBase}${profile.image}` : 'assets/images/default.png'}
          alt=""
        />
        <div className="change-image-button">
          <label htmlFor="image-upload" className="upload-label">
            <i className="fa fa-camera"></i>
          </label>
        </div>
        <input
            id="image-upload"
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            style={{ display: 'none' }}
            ref={fileInputRef}
          />
      </div>
    </div>
  );
}

export default ProfileImageWidget;
