import React, { useContext, useState } from 'react'
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Utils } from 'utils';
import CustomAccordion from 'view/components/shared/CustomAccordion';
import TranslateController from 'controller/shared/TranslateController';
import ContentsLightList from 'view/components/course/content/ContentsLightList';
import { CourseHttpService } from 'services/course';
import AdminUserContents from './AdminUserContents';

function AdminUserLessonsSummary({ lecture,userId }) {


    return (
            <div className=" without-bg border-bottom" >
                {lecture?.lessons.map((item, index) => (
                    <LessonAccordion userId={userId} lesson={item} key={"lessac" + item.id} />
                ))}
            </div>
    )
}

function LessonAccordion({ lesson ,userId}) {


    const {_t} = TranslateController();


    return (
        <CustomAccordion open={1} key={`clesson${lesson.id}`} title={Utils.getDefaultName(lesson)}>
                <AdminUserContents userId={userId} lesson={lesson}   />
                {/* <LessonQuizes lesson={lesson.id} isLight={1}   /> */}
        </CustomAccordion>

    )
}

export default AdminUserLessonsSummary