import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { CourseHttpService } from "services/course";
import { Utils } from "utils";

function ContentVideoController({ content, currentTime, onProgress }) {

    const videoRef = useRef(null);
    const videoDuration = useRef(0);
    const [videoLoaded, setVideoLoaded] = useState(false);
    const [question, setQuestion] = useState(null);
    const [shownQuestions, setShownQuestions] = useState([]);
    const [videoCurrentTime, setVideoCurrentTime] = useState([]);
    const [currentSubtitle, setCurrentSubtitle] = useState(null);
    const [showNextDialog, setShowNextDialog] = useState(false);

    const [lyrics, setLyrics] = useState(null);
    const [refresh, setRefresh] = useState(0);

    const _loadLyrics = (contentId) => {
        CourseHttpService.loadLrcs(contentId).then((data) => {
            if (data.results && data.results.length > 0) {
                let lrcs = parseLrcText(data.results[0].lrc_text)
                setLyrics(lrcs)
                setRefresh(refresh+1)
            }
        })
    }

    useEffect(() => {
        _loadLyrics(content?.id)
    }, [content?.id]);

    let norifyEndTriggered = false;

    useEffect(() => {
        let intervalId;

        const handleTimeUpdate = () => {
            const video = videoRef.current;


            if (video) {


                const currentTime = video.currentTime;

                if (!norifyEndTriggered && currentTime >= (0.75 * videoDuration.current)) {
                    notifyVideoEnd("end");
                    norifyEndTriggered = true;  // Ensure it's triggered only once
                }

                if (onProgress) {
                    onProgress(currentTime)
                }

                _handleQuestionShow(video)
                _handleTranscriptShow(video.currentTime)
                setVideoCurrentTime(currentTime)
            }
        };


        const handleLoadedMetadata = () => {
            videoDuration.current = videoRef.current.duration;
            setVideoLoaded(true)
        };

        if (videoRef.current) {
            videoRef.current.addEventListener('timeupdate', handleTimeUpdate);
            videoRef.current.addEventListener('loadedmetadata', handleLoadedMetadata);


            // const videoElement = videoRef.current;

            // content?.questions.map(quesion=>{
            //   const progressBarWidth = videoElement.offsetWidth - 10; // Adjust this value to your preference
            //       const percentage = (quesion.show_at_time / videoElement.duration) * 100;
            //       const divPosition = 50; //(percentage * progressBarWidth) / 100;
            // })
        }

        return () => {
            if (videoRef.current) {
                videoRef.current.removeEventListener('timeupdate', handleTimeUpdate);
                videoRef.current.removeEventListener('loadedmetadata', handleLoadedMetadata);
            }
            clearInterval(intervalId);
        };
    }, [content?.id,lyrics]);


    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.currentTime = Utils.timeInSeconds(currentTime + "");
        }
    }, [currentTime]);

    function parseLrcText(lrcText) {
        const lines = lrcText.split('\n');
        const lrcArray = lines.map(line => {
            // Match the time format [mm:ss] and capture text after the time
            const match = line.match(/\[(\d{2}):(\d{2})\](.+)/);
            if (match) {
                const minutes = match[1];
                const seconds = match[2];
                const text = match[3].trim();
                const time = `${minutes}:${seconds}`; // Keep time as mm:ss
                return { time, text };
            }
            return null;
        }).filter(item => item !== null); // Filter out any lines that did not match the pattern
    
        return lrcArray;
    }

    function _handleTranscriptShow(progress) {


        const transcriptToShow = lyrics?.find(
            (lyric) => Utils.isLrcVisible(lyric, progress) ?? lyric
        );

        if (transcriptToShow) {
            // Check if the question is already shown
            // console.log(transcriptToShow)
            // if (currentSubtitle?.id != transcriptToShow.id) {
                setCurrentSubtitle(transcriptToShow)
            // }
        }
        else {
            setCurrentSubtitle(null)
        }

    }

    function _handleQuestionShow(video) {
        const currentTime = video.currentTime;

        // Convert currentTime to the format "mm:ss"
        const currentFormattedTime = formatTime(currentTime);

        const questionToShow = content?.questions?.find(
            (question) => question.show_at_time === currentFormattedTime
        );

        if (questionToShow) {
            // Check if the question is already shown
            if (!shownQuestions.includes(questionToShow.id)) {
                let temp = shownQuestions;
                temp.push(questionToShow.id);
                setShownQuestions(temp);
                setQuestion(questionToShow);
                video.pause();
            }
        }

    }



    // Helper function to format time in "mm:ss" format

    function formatTime(time) {
        const minutes = Math.floor(time / 60)
            .toString()
            .padStart(2, "0");
        const seconds = Math.floor(time % 60)
            .toString()
            .padStart(2, "0");
        return `${minutes}:${seconds}`;
    }



    const _logContent = (action = "start") => {
        var body = {
            "content": content?.id,
            "action": action
        }

        CourseHttpService.logContent(body).then(response => {
        }).catch(err => {
            // setError("Something went wrong")
        });
    }

    const notifyVideoStart = async (contentId) => {
        // API call to notify server about video start
        _logContent("start")
    };

    const notifyVideoEnd = async (contentId) => {
        // API call to notify server about video end
        _logContent("end")

    };

    const notifyVideoProgress = async (contentId, currentTime) => {
        // You can include the `currentTime` in the request payload
        console.log("notifyVideoProgress");

        // _logContent("progress")
    };

    const handleVideoStart = () => {
        notifyVideoStart(content?.id);
    };

    const handleVideoEnd = () => {
        notifyVideoEnd(content?.id);
        _handleNextContentOrQuiz()
    };

    const _handleNextContentOrQuiz = () => {
        if (content?.next_content || content?.next_quiz) {
            setShowNextDialog(true)
        }
    }

    const _handleShowCertificate = () => {
        if (content?.next_content || content?.next_quiz) {
            setShowNextDialog(true)
        }
    }

    return { videoRef, handleVideoStart, showNextDialog, setShowNextDialog, handleVideoEnd, videoLoaded, question, setQuestion, currentSubtitle }
}

export default ContentVideoController