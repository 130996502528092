import React, { useState } from 'react'
import { useQuery } from 'react-query';
import { CourseHttpService } from '../../../../services/course';
import CourseReviewList from './CourseReviewList'
import { Utils } from "../../../../utils";
import ReviewStars from './ReviewStars';
import ReviewPercentage from './ReviewPercentage';
import TranslateController from 'controller/shared/TranslateController';

function CourseReviews({ course }) {
    const { isLoading, error, reviews } = useQuery(['course_reviews', course?.id], () => { return CourseHttpService.loadReviews(course?.id, 5) }, {
        onSuccess: (data) => {
            if (data.results) {
                setData(data.results)
                setCount(data.count)

            }
        }
    });

    const [data, setData] = useState(null);
    const [count, setCount] = useState(0);

    const {_t} = TranslateController()

    return (
        <section id="reviews" className="section section-no-border pt-3 pb-3 m-0 lan-rtl" >
            <div className="container ">
                <h2 className='mb-2 font-weight-bold'>{_t("Reviews")}</h2>
                <div className="row">
                    <div className="col-md-3">
                        <div className="review-details">
                            <h2>{course?.review_counts.total_average.toFixed(1)}</h2>
                            <div>
                                <ReviewStars rating={course?.review_counts.total_average} />
                                <p>{count} reviews</p>
                            </div>
                        </div>
                        <div className="review-summary">
                            <ReviewPercentage course={course} />
                        </div>
                        <div className="mt-3">
                            <button type="button" className="btn btn-outline btn-primary text-dark text-color-hover-light rounded-0 mb-2 ">{_t("View All Reviews")}</button>
                        </div>
                    </div>
                    <div className="col-md-9">
                        {/* <h3>TOP REVIEWS FROM {course && Utils.getDefaultName(course)}</h3> */}
                        <CourseReviewList reviews={data} />

                    </div>
                </div>
            </div>
        </section>
    )
}

export default CourseReviews