import { CancelOutlined, CheckCircleOutline } from '@mui/icons-material'
import TranslateController from 'controller/shared/TranslateController'
import React from 'react'

function QuizDone({ message = "Changes are saved successfully",minimumNeededToPass=75, grade = 81 , ...props }) {

  const { _t } = TranslateController();
  let pass = grade > minimumNeededToPass

  return (
    <div className={`quiz-done ${pass ? "quiz-success" : ""} mb-5`}>
      {pass ? <CheckCircleOutline className='mb-3' /> : <CancelOutlined className='mb-3' />}
      <h3>{_t('Your score : ') + grade}</h3>
      <div className='mb-5'>{pass ? _t("Congratulations! You successfully passed the test") :
        _t("Unfortunately you didn't pass this time. try again later")}</div>

      <div className="flex-center">
        <button className="btn btn-primary btn-icon" onClick={() => { props?.onRetake() }}> {_t("Retake Test")}</button>
        <button className="btn btn-primary btn-icon" onClick={() => { props?.onBack() }}> {_t("Return")}</button>
      </div>

    </div>
  )
}

export default QuizDone