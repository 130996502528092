import React, { useContext } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link, useParams } from "react-router-dom";
import { LanguageContext } from '../../../../../contexts/LanguageContext';
import { CourseHttpService } from '../../../../../services/course';
import { translate, Utils } from '../../../../../utils';
import CustomModal from '../../../../components/modals/CustomModal';
import WeekForm from '../../forms/course/WeekForm';
import SelectInstructorList from '../../lists/SelectInstructorList';
import DeleteDialog from '../../shared/DeleteDialog';
import Lectures from './Lectures';

function CourseInstructors({ course }) {

    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = React.useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = React.useState(false);

    const [selectedId, setSelectedId] = React.useState(0);
    const { id } = useParams();
    const [refresh, setRefresh] = React.useState(0);

    const { translations } = useContext(LanguageContext);
    const _t = (key) => {
        return translate(key, translations)
    }


    const _handleDelete = (e, id) => {
        setSelectedId(id);
        setRefresh(refresh + 1)
        CourseHttpService.SetInstructorToCourse(course.id,id,"remove")
        _onDeleteDone();
    }

    const _onDeleteDone = () => {
        course.instructors = course.instructors.filter(item => item.id !== selectedId)
        setRefresh(refresh + 1)
    }

    const _addInstructorToCourse = (item) => {
        if(course?.instructors){
           course.instructors.push(item)
           CourseHttpService.SetInstructorToCourse(course.id,item.id)
        }
        else{
            course.instructors = [item]
        }
        setEditModalIsOpen(false)
        setRefresh(refresh + 1)

    }



    return (
        <div className='box mt-5 mb-5'>
            <div className="box-header mb-5">
                <h5>Instructors</h5>
                <div className="btns">
                    <button className="btn btn-icon btn-primary" onClick={() => { setSelectedId(null); setEditModalIsOpen(true) }}><i className="fa fa-plus "></i></button>
                </div>
            </div>
            <div className="box-body">
                <div className='general-list' >
                    {course?.instructors?.map(item => (
                        <div key={item.id + "cmp"} className='general-list-item'>
                            <img className="img-fluid mb-4 max-width-100" src={Utils.getImage(item)} alt=""></img>
                            <div className="caption">
                                <h3> {Utils.getDefaultName(item)}</h3>
                            </div>
                            <div className="btns">
                                    <button className="btn" onClick={(e) => { e.preventDefault(); e.stopPropagation(); _handleDelete(e, item.id) }}>
                                        <a href="#delete" >
                                            <i className="fa fa-trash"></i>
                                        </a>
                                    </button>
                                </div>
                        </div>
                    )
                    )}
                </div>
            </div>

            {editModalIsOpen && (
                <CustomModal big className="" key={editModalIsOpen} setOpen={(value) => setEditModalIsOpen(value)}
                    open={editModalIsOpen} >
                        <SelectInstructorList type="instructor" courseId={course?.id} OnSelect={_addInstructorToCourse} />
                </CustomModal>
            )}

            {deleteDialogIsOpen && (
                <DeleteDialog
                    url={`/academy/course/week/${selectedId}`}
                    isOpen={deleteDialogIsOpen}
                    key={"mdl" + deleteDialogIsOpen + selectedId}
                    setOpen={setDeleteDialogIsOpen}
                    notifyDone={_onDeleteDone}
                />
            )}
        </div>
    )
}

export default CourseInstructors