import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useQuery } from 'react-query';
import { useParams } from "react-router-dom";
import { CourseHttpService } from '../../../../../services/course';
import { Utils } from '../../../../../utils';
import CustomModal from '../../../../components/modals/CustomModal';
import DeleteDialog from '../../../components/shared/DeleteDialog';
import TimeSignatureForm from './TimeSignatureForm';

function TimeSignaturesList({ }) {

  
  const [items, setData] = useState(null);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = React.useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = React.useState(false);

  const [selectedId, setSelectedId] = React.useState(0);
  const { id } = useParams();
  const [refresh, setRefresh] = React.useState(0);


  const { isLoading, error, data, refetch } = useQuery('time_signatures', CourseHttpService.loadTimeSignatures, {
    onSuccess: (data) => {
      setData(data.results)
    }
});


  // const _getData = () => {
  //   setLoading(true);
  //   CourseHttpService.loadInstructors().then((items) => {
  //     if (items.results) {
  //       setData(items.results)
  //     }
  //   });
  // }


  const _handleDelete = (e, id) => {
    setSelectedId(id);
    setRefresh(refresh + 1)
    setDeleteDialogIsOpen(true)
  }

  const _onDeleteDone = () => {
    var temp = items.filter(item => item.id !== selectedId)
    setData(temp)
    setDeleteDialogIsOpen(false);
    setRefresh(refresh + 1)
  }

  const _edit = (e, item) => {
    e.preventDefault();
    setSelectedId(item.id);
    setEditModalIsOpen(true)
    setRefresh(refresh + 1)
  }



  return (
    <div className='box'>
      <div className="box-header mb-5">
        <h5>Time Signatures</h5>
        <div className="btns">
          <button className="btn btn-primary" onClick={() => { setSelectedId(null); setEditModalIsOpen(true) }}><i className="fa fa-plus "></i></button>
        </div>
      </div>
      <div className="box-body general-list">
        {items?.map(item => (
          <div key={item.id + "cmp"} className='general-list-item' onClick={(e) => { _edit(e, item) }}>
            {/* <img className="img-fluid mb-4" src={Utils.getImage(item)} alt=""></img> */}
            <div className="caption">
              <h3> {Utils.getDefaultName(item)}</h3>
              <div className="btns">
                <button className="btn" onClick={(e) => { _edit(e, item) }}>
                  <a href="#edit"  >
                    <i className="fa fa-edit"></i>
                  </a>
                </button>
                <button className="btn" onClick={(e) => {e.stopPropagation(); _handleDelete(e, item.id) }}>
                  <a href="#delete" >
                    <i className="fa fa-trash"></i>
                  </a>
                </button>
              </div>
            </div>
          </div>
        )
        )}
      </div>

      <CustomModal className="" big key={editModalIsOpen} setOpen={(value) => setEditModalIsOpen(value)}
        open={editModalIsOpen} >

        <TimeSignatureForm id={selectedId} 
        onEditDone={(author) => { 
          let index = items.findIndex(item => item.id === author.id);
          if (index > -1) {
            items[index].names = author.names;
            items[index].image = author.image;
          }
          else {
            items.push(author);
          }
          setData(items)
      }} />


      </CustomModal>

      <DeleteDialog
        url={`/academy/course/notation/time-signature/${selectedId}`}
        isOpen={deleteDialogIsOpen}
        key={"mdl" + deleteDialogIsOpen + selectedId}
        setOpen={setDeleteDialogIsOpen}
        notifyDone={_onDeleteDone}
      />
    </div>
  )
}

export default TimeSignaturesList