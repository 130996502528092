import { StarOutline } from '@mui/icons-material';
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useQuery } from 'react-query';
import { useParams } from "react-router-dom";
import { CourseHttpService } from '../../../../services/course';
import { Utils } from '../../../../utils';
import CustomModal from '../../../components/modals/CustomModal';
import InstructorForm from '../forms/course/InstructorForm';
import DeleteDialog from '../shared/DeleteDialog';

function ReviewList({ }) {

  const { isLoading, error, reviews } = useQuery(['reviews'], () => { return CourseHttpService.loadReviewsWithCourse(); }, {
    onSuccess: (data) => {
      setData(data.results)
    }
  });

  const [data, setData] = useState(null);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = React.useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = React.useState(false);

  const [selectedId, setSelectedId] = React.useState(0);
  const { id } = useParams();
  const [refresh, setRefresh] = React.useState(0);


  // const _getData = () => {
  //   setLoading(true);
  //   CourseHttpService.loadInstructors().then((items) => {
  //     if (items.results) {
  //       setData(items.results)
  //     }
  //   });
  // }


  const _handleDelete = (e, id) => {
    e.preventDefault();
    setSelectedId(id);
    setRefresh(refresh + 1)
    setDeleteDialogIsOpen(true)
  }

  const _onDeleteDone = () => {
    var temp = data.filter(item => item.id !== selectedId)
    setData(temp)
    setDeleteDialogIsOpen(false);
    setRefresh(refresh + 1)
  }

  const _edit = (e, item) => {
    e.preventDefault();
    setSelectedId(item.id);
    setEditModalIsOpen(true)
    setRefresh(refresh + 1)
  }

  const _changeStatus = (e, review) => {
    e.preventDefault();
    CourseHttpService.changeReviewStatus(review);

    const itemIndex = data.findIndex(item => item.id === review.id);
    if (itemIndex !== -1) {
      const newData = [...data];
      newData[itemIndex].status = !data[itemIndex].status;
      setData(newData);
    }


    setRefresh(refresh + 1)
  }





  return (
    <div className='box'>
      <div className="box-header mb-5">
        <h5>Reviews</h5>
        <div className="btns">
          {/* <button className="btn btn-icon" onClick={() => { setSelectedId(null); setEditModalIsOpen(true) }}><i className="fa fa-plus "></i></button> */}
        </div>
      </div>
      <div className="box-body row" key={refresh+"rroww"}>
        {data?.map(item => (
          <div key={item.id + "cmp"} className='col-md-12 ' onClick={(e) => { }}>
            <div className=" cms-review-item">
              <div className='kfont'> {item.description}</div>
              <div className="review-item-aside">
                <div className='mr-2'>{item.course && Utils.getDefaultName(item.course)}</div>
                <div className='star-count'> {item.star} <StarOutline className='gold-star' /></div>
                <button className="btn" onClick={(e) => { _edit(e, item) }}>
                  <a href="#edit"  >
                    <i className="fa fa-edit"></i>
                  </a>
                </button>
                <button className="btn" onClick={(e) => { _changeStatus(e, item) }}>
                  <a href="#change-status"  >
                    
                    <i className={item.status ? `fa fa-check color-success` : `fa fa-times color-danger`}></i>
                  </a>
                </button>
                <button className="btn" onClick={(e) => { e.stopPropagation(); _handleDelete(e, item.id) }}>
                  <a href="#delete" >
                    <i className="fa fa-trash"></i>
                  </a>
                </button>
              </div>
            </div>
          </div>
        )
        )}
        {/* <div className="col-md-12">
          <ul className="pagination center mt-3 mb-5 ">
            <li className="page-item"><a className="page-link" href="#"><i className="fas fa-angle-left"></i></a></li>
            <li className="page-item active"><a className="page-link" href="#">1</a></li>
            <li className="page-item"><a className="page-link" href="#"><i className="fas fa-angle-right"></i></a></li>
          </ul>
        </div> */}
      </div>

      <CustomModal className="" key={editModalIsOpen} setOpen={(value) => setEditModalIsOpen(value)}
        open={editModalIsOpen} >

        {/* <InstructorForm id={selectedId} /> */}


      </CustomModal>

      <DeleteDialog
        url={`/academy/course/review/${selectedId}`}
        isOpen={deleteDialogIsOpen}
        key={"mdl" + deleteDialogIsOpen + selectedId}
        setOpen={setDeleteDialogIsOpen}
        notifyDone={_onDeleteDone}
      />
    </div>
  )
}

export default ReviewList