import React, { useState } from 'react'
import { CourseHttpService } from 'services/course';
import { HttpService } from 'services/http';
import ReviewStars from '../review/ReviewStars';
import { Utils } from 'utils';
import { useQuery } from 'react-query';
import ReviewForm from './ReviewForm';
import { useParams } from 'react-router-dom';

function ContentReviews({ content}) {

    const { course } = useParams();

    const [reviews, setData] = useState(null)

    const { isLoading, error, data, refetch } = useQuery(['reviews', content?.id], () => { return CourseHttpService.loadReviews(content.course, 100, content?.id); }, {
        onSuccess: (data) => {
            setData(data.results)
        },
        refetchInterval: HttpService.DefaultRefetchInterval,
    });
    return (
        <div>
            <ReviewForm contentId={content.id} courseId={course} onEditDone={() => { refetch(); }} />
           
            <hr className="solid" />
            <div className="mt-3">
                {reviews?.map(review => (
                    <div className="review-item" key={review.id + "rvw"}>
                        <ReviewStars rating={review.star} />
                        <p className="date">{Utils.formatDate(review.created_at)}</p>
                        <p className="description kfont">{review.description}</p>
                    </div>
                )
                )}
            </div>
        </div>
    )
}

export default ContentReviews