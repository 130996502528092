import TranslateController from 'controller/shared/TranslateController'
import React, { useEffect } from 'react'
import RecentlyViewedCourses from 'view/components/course/user/RecentlyViewedCourses'
import UserCourseSummary from 'view/components/course/user/UserCourseSummary'
import Footer from 'view/components/shared/Footer'
import Header from 'view/components/shared/Header'
import PageHeader from 'view/components/shared/PageHeader'
import PageLayout from 'view/components/shared/PageLayout'
import CheckUserProfileCompleted from 'view/components/user/CheckUserProfileCompleted'

function UserDashboard() {
	const { _t } = TranslateController()

	return (
		<PageLayout showbreadcrumbs={false} HeaderChild={
			<h1 className="text-color-light my-3 font-weight-bold text-10 text-sm-8 lan-kfont">{_t("My Courses")}</h1>
		} >

			<div className="row ">
				<div className="col-md-12 mb-4 lan-text-right">
					<UserCourseSummary />
				</div>
			</div>
			<CheckUserProfileCompleted />
			<RecentlyViewedCourses />
		</PageLayout>


	)
}

export default UserDashboard